export const hasFormError = (formErrors: Record<string, string>): boolean => {
  return !!Object.keys(formErrors).find((errorkey) => formErrors[errorkey])
}

export const validateRequired = (value: string): string => {
  if (!value) {
    return 'Field is required'
  } else {
    return ''
  }
}

// export const validateEmail = (email: string): string => {
//   const { required, invalid } = validationMessages.email
//   if (!email) {
//     return required
//   } else {
//     let lastAtPos = email.lastIndexOf('@')
//     let lastDotPos = email.lastIndexOf('.')
//     if (!(
//       lastAtPos < lastDotPos &&
//       lastAtPos > 0 &&
//       email.indexOf('@@') === -1 &&
//       lastDotPos > 2 &&
//       email.length - lastDotPos > 2
//     )) {
//       return invalid
//     } else {
//       return ''
//     }
//   }
// }

// export const validatePhone = (phone: string): string => {
//   const phoneRegExp =
//     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//   const { required, numberOnly, invalid } = validationMessages.phone
//   if (!phone) {
//     return required
//   } else if (!phone.match(/^[0-9\b]+$/)) {
//     return numberOnly
//   } else if (!phone.match(phoneRegExp) || phone.length !== 10) {
//     return invalid
//   } else {
//     return ''
//   }
// }
