import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddInstitutionProps,
  completedVacancyprops,
  GetAddressREQ,
  GetAddressRES,
  GetInstituteProps,
  GetOrganizationProps,
} from "./types";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndPoints";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";

export const AddInstitutes = createAsyncThunk(
  "institute/Add_Institute",
  async (requestPayload: AddInstitutionProps): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.add_institute}`,
      requestPayload
    );
    if (response.data) {
      history.push(ROUTES.VIEW_INSTITUTE);
    }
    return response.data;
  }
);

export const GetInstitute = createAsyncThunk(
  "institute/Get_Institution",
  async (): Promise<Array<GetInstituteProps>> => {
    const response = await api.get(apiEndpoints.get_institute);
    return response.data.message;
  }
);

export const GetOrganization = createAsyncThunk(
  "institute/Get_Organization",
  async (): Promise<Array<GetOrganizationProps>> => {
    const response = await api.get(apiEndpoints.get_organization);
    return response.data.message;
  }
);

export const GetAddress = createAsyncThunk(
  "institute/getAddress",
  async (requestPayload: GetAddressREQ): Promise<Array<GetAddressRES>> => {
    const response = await api.post(
      `${apiEndpoints.getAddress}`,
      requestPayload
    );
    return response.data.message;
  }
);

export const GetMyDraft = createAsyncThunk(
  "vacancy/getMyDraft",
  async (requestPayload: { ins_id?: string; is_draft?: any }): Promise<any> => {
    const response = await api.post(apiEndpoints.getDraft, requestPayload);
    return response.data.message;
  }
);

export const GetCompletedVacancyApi = createAsyncThunk(
  "vacancy/getCompletedVacancy",
  async (requestPayload: {
    id?: string;
  }): Promise<completedVacancyprops> => {
    const response = await api.post(
      apiEndpoints.getCompletedVacancy,
      requestPayload
    );
    return response.data.message;
  }
);

export const deleteDomainStatus = createAsyncThunk(
  'institute/Get_Institution',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.deleteDomain,
      requestPayload
    )
    if (response.data) {
      dispatch(GetInstitute())
    }
    return response.data.message
  }
)