import React from 'react'
import SideNavigation from '../component/SideNavigation'
import { Routes } from '../routes'
import { BrowserRouter } from 'react-router-dom'

const Dohrwebsite = () => {
  return (
    <BrowserRouter>
      <SideNavigation isDucare={false}/>
      <Routes/>
    </BrowserRouter>
  )
}

export default Dohrwebsite