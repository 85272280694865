import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndPoints";
import { AllVacancyProsp, ConsAllVacancyProp } from "./types";

export const getAllPublishVacancy = createAsyncThunk(
    'vacancy/allPublishVacancy',
    async (requestPayload: AllVacancyProsp): Promise<any> => {
        const response = await api.post(apiEndpoints.getAllVacancy, requestPayload)
        return response?.data?.message
    }
)
export const getAllConsVacancy= createAsyncThunk(
    'vacancy/allConsVacancy',
    async (requestPayload: ConsAllVacancyProp): Promise<any> => {
        const response = await api.post(apiEndpoints.getAllConsVacancy, requestPayload)
        return response?.data?.message
    }
)

export const VacancyStatus= createAsyncThunk(
    'vacancy/VacancyStatus',
    async (requestPayload: ConsAllVacancyProp): Promise<any> => {
        const response = await api.post(apiEndpoints.VacancyStatus, requestPayload)
        return response?.data?.message
    }
)