import React from 'react'
import { PrimaryButton } from '../../../../../component'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { AllVacancyResposne } from '../../../../../redux/admin/vacancy/types'
import moment from 'moment'

// eslint-disable-next-line

interface DataExportButtonProps {
  exportData: AllVacancyResposne[]
}

const DataExportButton: React.FC<DataExportButtonProps> = ({ exportData }) => {
  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')

  const dateformatTime = (time: Date) => {
    return moment(time).format('YYYY-MM-DD- HH:mm')
  }

  const handleExport = async () => {
    const formattedData = exportData.map((item) => {
      return {
        Id: item.id,
        'Customer name': `${item.customer_firstname} ${item.customer_lastname}`,
        'Customer Email': item.customer_emailID,
        'Company Name': item.customer_company_name,
        Position: item.position,
        'PublishedDate and Time': dateformatTime(item.created_at),
        Consultant: `${item.consultant_first_name} ${item.consultant_last_name}`,
        'Organisation Type': item.customer_organization_type,
        'Job description': item.discription,
        'Vacancy Date': item.v_date,
        'Vacancy Time': `${formatTime(item.from_time)}-${formatTime(
          item.to_time
        )}`,
        'Break Time': item.break_time,
        'Customer Contact': item.customer_telephone_number,
        Location: `${item.customer_address},${item.customer_area_name},${item.customer_postal_code}`,
        'Original time': `${formatTime(item.from_time)} - ${formatTime(
          item.to_time
        )}`,
        'Reported Time': `${item.report_start_time} - ${item.report_end_time}`,
        'Reported Hours': item.report_total_whours,
        'Reason From Consultant':
          item.report_reason === null ? 'NA' : item.report_reason,
        'Approved time':
          item.vacancy_status === 2
            ? item.approved_stime === null ||
              item.approved_etime === null ||
              item.approved_stime === '' ||
              item.approved_etime === ''
              ? `${item.report_start_time} - ${item.report_end_time}`
              : `${item.approved_stime} - ${item.approved_etime}`
            : 'NA',
        'Total Approved Hours':
          item.vacancy_status === 2
            ? item.total_approved_hrs === null
              ? item.report_total_whours
              : item.total_approved_hrs
            : 'NA',
        'Reason by customer': item.feedback === null ? 'NA' : item.feedback,
        RecipentType: item.publish_to,

        'Vacancy Status':
          item.vacancy_is_approved === 0
            ? 'Cuspending'
            : item.is_paid === 0
            ? 'Pending'
            : 'Paid',

        'Customer Cancellation Info':
          item.is_canceled === 0 ? 'NA' : 'Cancelled',
        'I study minimum 50 %':
          item.I_am_a_full_time_student === 1 ? 'Yes' : 'No',
        'I have another primary occupation minimum 50%':
          item.self_employed_F_Skatt_or_freelancer_third_party === 1
            ? 'Yes'
            : 'No',
        'Initial accepted time':
          item?.vacancy_Accepted_Primary_time === null
            ? 'NA'
            : dateformatTime(item?.vacancy_Accepted_Primary_time)
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'VacancyData')
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    saveAs(blob, 'Paid vacancy_data.xlsx')
  }

  return (
    <PrimaryButton
      variant="success"
      onClick={handleExport}
      disabled={exportData.length === 0}
    >
      Export Vacancy Data
    </PrimaryButton>
  )
}

export default DataExportButton
