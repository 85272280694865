import { ReactElement, useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  FlexWrapper,
  Icon,
  Loader,
  PageWrapper,
  PrimaryButton,
  SectionTitle,
  Input
} from '../../../../component'
import ROUTES from '../../../../const/routes'
import { updateSelectedConsultant } from '../../../../redux/admin/consultant/action'
import {
  deleteConsutantStatus,
  getAllConsultantList,
  updataConsutantStatus
} from '../../../../redux/admin/consultant/api'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import { getConsultantResponse } from '../../../../redux/admin/consultant/types'

const ViewInActiveConsultants = (): ReactElement => {
  const {
    consultant: { getApprovedlist, isLoading }
  } = useSelector((state: RootState) => ({
    consultant: state.consultant
  }))
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(
      getAllConsultantList({
        type: 3,
        is_verfied: true
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(5)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  let total: number
  const [showModal, setshowModal] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [selectedConsultant, setSelectedConsultant] =
    useState<getConsultantResponse | null>(null)
  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(getApprovedlist?.length / dataPerPage)
  const pageButtonsToShow = 4
  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }
  const filteredDatas = getApprovedlist?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else {
      // Convert both the search query and the name to lowercase for case-insensitive matching
      const searchQueryLower = searchQuery.toLowerCase()
      const name = `${items.first_name} ${items.last_name}`.toLowerCase()
      // Check if the name starts with the searchQuery
      return name.startsWith(searchQueryLower)
    }
  })

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin backgroundColor=" #f2fff2">
          <SectionTitle title="Inactive Consultants" />
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                value={searchQuery}
                placeholder="Search by consultant"
                onChange={handleSearch}
              />
            </FlexWrapper>
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredDatas
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  ?.map((data, index) => {
                    const {
                      first_name,
                      last_name,
                      email_id,
                      phone_number,

                      swedish_personal_number
                    } = data
                    return (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{1 + total++}</td>
                        <td>{`${first_name}${last_name}`}</td>
                        <td>{email_id}</td>
                        <td>{phone_number}</td>

                        <td>{swedish_personal_number}</td>
                        <td>
                          <PrimaryButton
                            variant="success"
                            title="Click to activate the consultant"
                            onClick={() => {
                              setSelectedConsultant(data)
                              setShowConfirmationModal(true)
                            }}
                          >
                            Activate
                          </PrimaryButton>
                        </td>
                        <td>
                          <Icon
                            title="View Consultant"
                            onClick={() => {
                              history.push(ROUTES.CONSULTANTS_DETAILS)
                              dispatch(updateSelectedConsultant(data))
                            }}
                            icon={['fas', 'eye']}
                          />{' '}
                          <Icon
                            title="Delete Consultant"
                            onClick={() => {
                              setshowModal(data?.id)
                            }}
                            icon={['far', 'trash-alt']}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={getApprovedlist?.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setshowModal('')
        }}
        backdrop="static"
      >
        <Modal.Header>
          Are you sure you want to delete this consultant?
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper noMargin justifyContent="end">
            <PrimaryButton
              left
              onClick={async () => {
                await dispatch(
                  deleteConsutantStatus({
                    id: showModal
                  })
                )
                setshowModal('')
              }}
            >
              Yes
            </PrimaryButton>{' '}
            <PrimaryButton
              left
              onClick={() => {
                setshowModal('')
              }}
            >
              No
            </PrimaryButton>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
      >
        <Modal.Header>
          Are you sure you want to reactivate this consultant?
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper noMargin justifyContent="end">
            <PrimaryButton
              left
              onClick={() => {
                dispatch(
                  updataConsutantStatus({
                    data: false,
                    id: selectedConsultant?.id,
                    type: 3,
                    email_id: selectedConsultant?.email_id
                  })
                )
                setShowConfirmationModal(false)
              }}
            >
              Yes
            </PrimaryButton>{' '}
            <PrimaryButton
              left
              onClick={() => {
                setShowConfirmationModal(false)
              }}
            >
              No
            </PrimaryButton>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  )
}

export default ViewInActiveConsultants
