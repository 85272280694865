import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import fonts from '../../const/fonts'

export const HeaderWrapper = styled.div`
  height: 50px;
  width: 100%;
  box-shadow: 0 20px 20px rgb(126 142 177 / 12%);
  background: #003824;
  border-bottom: 1px solid #ebedf2;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  box-sizing: border-box;
`
export const SideContainer = styled.div`
  /* background: #f1f2f3 !important; */
  width: 500px;
  align-items: center;
  display: flex;
`

export const LogoWrapper = styled.div`
  width: 120px;
  height: 35%;
  display: flex;
  margin: auto 12px;
`

export const Logo = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 800;
`
export const HamburgeButton = styled(Button)`
  background: none;
  border: none;
  text-align: center;
  font-size: ${fonts.medium}px;
  color: ${({ theme }) => theme?.hamburgerMenu};
  &:focus,
  &:active {
    background: none;
    box-shadow: none;
    outline: none;
  }
  &:hover {
    background: none;
    outline: none;
    box-shadow: none;
  }
`
export const Hamburg = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 8.5%;
  margin-left: 10px;
  .line {
    margin-bottom: 4px;
    display: block;
    height: 3px;
    width: 20px;
    border-radius: 10px;
    background: white;
    transition: 0.4s ease-in-out;
  }
  .line1 {
    height: 3px;
    width: 22px;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(45deg);
    transition: transform 0.4s ease-in-out;
  }

  .line2 {
    transition: transform 0.2s ease-in-out;
    transform: scaleY(0);
  }

  .line3 {
    height: 3px;
    width: 22px;
    border-radius: 10px;
    display: flex;
    margin-top: -3.2px;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(-45deg);
    transition: transform 0.4s ease-in-out;
    background: #0e2431;
  }
`
export const Select = styled.select`
  width: 100px;
  padding: 4%;
  outline: none;
  border-radius: 10px;
  box-shadow: 0px 1px 15px;
`

export const IconWrapper = styled.span`
  padding-left: 2%;
  padding-top: 4px;
  cursor: pointer;
`

export const ProfileImage = styled.img`
  height: 35px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 4%;
`

export const ProfileContainer = styled.div`
  position: relative;
  left: -4%;
  top: 7px;
  border-radius: 20px;
`

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  margin-top: -4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  ${ProfileContainer}:hover & {
    display: block;
  }
`

export const DropdownContentLink = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
`

export const UserName = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12%;
`

export const ProfileFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Logout = styled.span`
  position: relative;
  left: -80px;
  color: white;
  font-size: 17px;
  cursor: pointer;
  .ico {
    position: relative;
    left: 5px;
    font-size: 11px;
  }
`
