import { Card, FormControl } from 'react-bootstrap'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import styled from 'styled-components'

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 1rem auto;
`

export const CountInput = styled(FormControl)`
  height: 40px;
  margin-top: 2.5%;
  box-shadow: 0 1px 5px 0 grey;
  border: none;
  outline: 1px #003824;
  @media (max-width: 1000px) {
    margin-top: 4%;
  }
`

export const SelectedTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
`
export const In = styled(ReactGoogleAutocomplete)`
  border: 1px solid #ced4da;
  border-radius: 3px;
  width: 100%;
`
