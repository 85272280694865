import styled from 'styled-components'

interface DropdownWrapperProps {
  width?: string
}

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  margin: 1%;
  width: ${({ width }) => width || '22%'};
  #editable-dropdown {
    height: 100%;
  }
`

export default DropdownWrapper
