import { configureStore } from '@reduxjs/toolkit'
import InstituteReducer from './admin/institution/reducer'
import SettingsReducer from './admin/settings/reducer'
import consultantReducer from './admin/consultant/reducer'
import menuReducer from './Menu/reducer'
import vacancyReducer from './admin/vacancy/reducer'
import contactusReducer from './admin/contactus/reducer'
import manualpushallReducer from './admin/ManualPushAll/reducer'
import customerReducer from './admin/customer/reducer'
import WebsiteadReducer from './admin/WebsiteAd/reducer'

const reducer = {
  institute: InstituteReducer,
  settings: SettingsReducer,
  consultant: consultantReducer,
  menu: menuReducer,
  vacancy: vacancyReducer,
  contactus: contactusReducer,
  manualpushall: manualpushallReducer,
  customer: customerReducer,
  Websitead: WebsiteadReducer
}

const initialState = {}

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
