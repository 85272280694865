/* eslint-disable no-unused-vars */
import React, { ReactElement, useState, useEffect } from 'react'
import { Modal, Table, Button } from 'react-bootstrap'
import moment from 'moment'
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  Icon,
  Input,
  Loader
} from '../../../../component'
import { CardWraper } from '../../Consultants/subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import 'react-datepicker/dist/react-datepicker.css'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../const/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Getwebsitead, deletead } from '../../../../redux/admin/WebsiteAd/api'
import { Editwebsitead } from '../../../../redux/admin/WebsiteAd/api'
import { updateViewAd } from '../../../../redux/admin/WebsiteAd/action'

const PublishedAd = (): ReactElement => {
  const { websitegetadlist, isLoading } = useSelector((state: RootState) => ({
    websitegetadlist: state.Websitead.Websitecreateadapplist,
    isLoading: state.Websitead.isLoading
  }))

  const dispatch = useDispatch()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  let sorteddata = [...websitegetadlist].reverse()

  const [filterType, setFilterType] = useState<any>(2) // Default: All (type 1)

  const dateformatTime = (time: Date | null | string) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  useEffect(() => {
    dispatch(
      Getwebsitead({
        type: filterType
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])
  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4

  const [showModal, setShowModal] = useState<any>('')
  const [editingIndex, setEditingIndex] = useState(-1) // Track the index being edited
  const [editedTitle, setEditedTitle] = useState<string>('')
  const [editedEndDate, setEditedEndDate] = useState<any>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const history = useHistory()

  const filteredSearchData = sorteddata?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      items.ad_title?.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })
  let total: number

  const calculateAdStatus = (
    publishedDate: Date | null,
    endDate: Date | null
  ): string => {
    const currentDate = new Date()
    if (publishedDate && endDate) {
      if (currentDate >= publishedDate && currentDate <= endDate) {
        return 'Active'
      } else if (currentDate > endDate) {
        return 'Ended'
      }
    }
    return 'N/A' // Handle cases where either date is not available
  }

  const handleEditClick = (index: number) => {
    setEditingIndex(index)
    setEditedTitle(sorteddata[index].ad_title)
    setEditedEndDate(sorteddata[index].end_date)
  }

  const handlesave = async (
    index: number,
    value: string,
    value2: Date | null
  ) => {
    const updatedData = [...sorteddata]
    const passIdToUpdate = updatedData[index].id

    try {
      await dispatch(
        Editwebsitead({
          ad_title: value,
          end_date: value2,
          id: passIdToUpdate,
          published_date: new Date()
        })
      )

      setEditingIndex(-1)
      window.location.reload()
    } catch (error) {
      // Handle error if needed
    }
  }

  const [selectedAdvertisementId, setSelectedAdvertisementId] = useState<
    string | null
  >(null)
  const handleApplyClick = (advertisementId: string) => {
    setSelectedAdvertisementId(advertisementId)
    history.push({
      pathname: ROUTES.PublishedAdUser,
      search: `?id=${advertisementId}`
    })
  }
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Published Ad list" hasBorder />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                height="60%"
                value={searchQuery}
                placeholder="Search by Ad title"
                onChange={handleSearch}
              />
              <select
                value={filterType}
                onChange={(e) => setFilterType(Number(e.target.value))}
                style={{ width: '20%', height: '70%' }}
              >
                <option value={2}>Active</option>
                <option value={3}>Ended</option>
                <option value={1}>All</option>
              </select>
            </FlexWrapper>
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {filteredSearchData
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{item.id}</td>
                      <td>
                        {editingIndex === index ? (
                          <div>
                            <input
                              type="text"
                              value={editedTitle}
                              onChange={(e) => setEditedTitle(e.target.value)}
                            />
                          </div>
                        ) : (
                          item.ad_title
                        )}
                      </td>

                      <td>{dateformatTime(item.published_date)}</td>
                      <td>
                        {editingIndex === index ? (
                          <div>
                            <input
                              type="date"
                              value={editedEndDate}
                              onChange={(e) => setEditedEndDate(e.target.value)}
                            />
                          </div>
                        ) : (
                          dateformatTime(item.end_date)
                        )}
                      </td>
                      <td>
                        <Icon
                          icon={['fas', 'eye']}
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.PUBLISHEDADVIEW
                            })
                            dispatch(updateViewAd(item))
                          }}
                        />
                      </td>
                      <td>
                        {calculateAdStatus(
                          new Date(dateformatTime(item.published_date)),
                          new Date(dateformatTime(item.end_date))
                        )}
                      </td>
                      <td>
                        <Icon
                          title="Delete Ad"
                          onClick={() => {
                            setShowModal(item?.id)
                          }}
                          icon={['far', 'trash-alt']}
                        />
                        {calculateAdStatus(
                          new Date(dateformatTime(item.published_date)),
                          new Date(dateformatTime(item.end_date))
                        ) === 'Active' ? (
                          <Icon
                            title="Edit"
                            onClick={() => handleEditClick(index)}
                            icon={['far', 'edit']}
                          />
                        ) : null}
                        {editingIndex === index ? (
                          <button
                            onClick={() =>
                              handlesave(index, editedTitle, editedEndDate)
                            }
                          >
                            Submit
                          </button>
                        ) : null}
                        {editingIndex === index ? (
                          <button onClick={() => setEditingIndex(-1)}>
                            Close
                          </button>
                        ) : null}
                      </td>
                      <td>
                        <Icon
                          title="View User"
                          onClick={() => {
                            handleApplyClick(item.id)
                          }}
                          icon={faUser}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setShowModal('')
        }}
      >
        <Modal.Header>Are you sure you want to delete this Ad?</Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(
                deletead({
                  id: showModal,
                  filtertype: filterType
                })
              )
              setShowModal('')
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setShowModal('')
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  )
}

export default PublishedAd
