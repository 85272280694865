/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import strings from '../../locale/en'
import {
  TableFooterWrapper,
  NavigationButton,
  PageDisplay,
  PaginationWrapper,
  EntriesWrapper,
  NumberButton
} from './subcomponents'
import { TableFooterProps } from './typings'

const TableFooter = ({
  currentPage,
  totalPages,
  showEntries,
  startValue,
  pageValue,
  showPageDisplay = true,
  TotalIems,
  handlePrevious,
  handleNext,
  handlePageClick,
  pageButtonsToShow,
  showButtonDisplay
}: TableFooterProps): ReactElement => {
  const {
    button: { previous, next }
  } = strings

  const [pageRange, setPageRange] = useState<any>([])

  useEffect(() => {
    const start = Math.max(1, currentPage - Math.floor(pageButtonsToShow / 2))
    const end = Math.min(start + pageButtonsToShow - 1, totalPages)
    setPageRange(Array.from({ length: end - start + 1 }, (_, i) => start + i))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageButtonsToShow, totalPages])

  return (
    <TableFooterWrapper>
      <EntriesWrapper>
        {showEntries && (
          <>
            Showing {startValue} to {pageValue} of {TotalIems} {''}
            entries
          </>
        )}
      </EntriesWrapper>

      <PaginationWrapper>
        <NavigationButton
          $isLeft
          variant="outline-primary"
          onClick={() => handlePrevious(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {previous}
        </NavigationButton>
        {showPageDisplay && (
          <PageDisplay>{`Page ${currentPage} of ${totalPages}`}</PageDisplay>
        )}
        {pageRange && showButtonDisplay && (
          <ul>
            {pageRange?.map((pageNumber: any) => (
              <NumberButton
                className="page-item"
                key={pageNumber}
                aria-hidden="true"
                isActive={pageNumber === currentPage}
                onClick={() => handlePageClick && handlePageClick(pageNumber)}
              >
                <button
                  onClick={() => handlePageClick && handlePageClick(pageNumber)}
                  className="page-link"
                >
                  {pageNumber}
                </button>
              </NumberButton>
            ))}
          </ul>
        )}
        <NavigationButton
          onClick={() => handleNext(currentPage + 1)}
          disabled={currentPage >= totalPages}
        >
          {next}
        </NavigationButton>
      </PaginationWrapper>
    </TableFooterWrapper>
  )
}

export default TableFooter