import { ReactElement } from 'react'
import moment from 'moment'
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { useLocation } from 'react-router-dom'

const ViewFeedbackConsultant = (): ReactElement => {
  const location = useLocation()
  const feedbackData = location.state?.feedbackData
  // const dateformatTime = (time: Date) => {
  //   return moment(time).format('YYYY-MM-DD-HH:mm')
  // }

  return (
    <PageWrapper>
      <FlexWrapper>
        <SectionTitle title="Consultant Feedback" hasBorder hasBackButton />
      </FlexWrapper>
      <div>
        {feedbackData && (
          <div>
            <p>First Name: {feedbackData.first_name}</p>
            <p>Last Name: {feedbackData.last_name}</p>
            <p>Email: {feedbackData.email}</p>
            <p>
              Updated At:{' '}
              {moment(feedbackData.updated_at.slice(0, 19)).format(
                'YYYY-MM-DD- HH:mm'
              )}
            </p>
            <p>Message: {feedbackData.message}</p>
            {/* Add more details as needed */}
          </div>
        )}
      </div>
    </PageWrapper>
  )
}

export default ViewFeedbackConsultant
