import { DropdownListProps } from "../component/EditableDropdown/typings";
import { GetInstituteProps, GetOrganizationProps } from "../redux/admin/institution/types";
import { Role } from "../redux/admin/settings/types";

export const OrganizationDropdown = (
  Orglists: Array<GetOrganizationProps>
): Array<DropdownListProps> => {
  const OrganizationType = Orglists.map((item: GetOrganizationProps) => ({
    id: item?.id,
    name: item?.organization_type,
  }));
  return OrganizationType;
};

export const TitleDropdown = (
  Titlelists: Array<Role>
): Array<DropdownListProps> => {
  const RoleTitle = Titlelists.map((item: Role) => ({
    id: item?.id,
    name: item?.title,
  }));
  return RoleTitle;
};

export const InstituteDropdown = (
  Titlelists: Array<GetInstituteProps>
): Array<DropdownListProps> => {
  const Institute = Titlelists.map((item: GetInstituteProps) => ({
    id: item?.id,
    name: item?.institute_name,
  }));
  return Institute;
};