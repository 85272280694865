import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetvacanciesApplicationresponse,
  initialState,
  websitecreatead
} from './types'
import {
  websitead,
  Getwebsitead,
  Editwebsitead,
  Publishedadapplication
} from './api'

export const WebsiteadSlice = createSlice({
  name: 'Websitead',
  initialState,
  reducers: {
    updateViewAd: (state, action: PayloadAction<websitecreatead | null>) => {
      state.updatecreatedapplist = action.payload
    },
    updateViewApplication: (
      state,
      action: PayloadAction<GetvacanciesApplicationresponse | null>
    ) => {
      state.setApplicationData = action.payload
    },
    updatehasAdCreated: (state, action: PayloadAction<boolean>) => {
      state.handleupdatead = action.payload
    }
  },
  extraReducers: {
    [websitead.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [websitead.fulfilled.toString()]: (
      state,
      action: PayloadAction<websitecreatead>
    ) => {
      state.isLoading = false,
      state.handleupdatead = true,
      window.scrollTo({ top: 0 }),
      state.Websitecreateadapp = action.payload
    },
    [websitead.rejected.toString()]: (state) => {
      state.isLoading = false
    },

    [Getwebsitead.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [Getwebsitead.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<websitecreatead>>
    ) => {
      state.isLoading = false
      state.Websitecreateadapplist = action.payload
    },
    [Getwebsitead.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [Editwebsitead.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<websitecreatead>>
    ) => {
      state.isLoading = false
      state.Websitecreateadapplist = action.payload
    },

    [Publishedadapplication.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [Publishedadapplication.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetvacanciesApplicationresponse>>
    ) => {
      state.isLoading = false
      state.getapplicationlist = action.payload
    },
    [Publishedadapplication.rejected.toString()]: (state) => {
      state.isLoading = false
    }
  }
})

export default WebsiteadSlice.reducer
