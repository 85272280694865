export const TableHeaderData = [
  'S.No',
  ' Name',
  'E-mail',
  'Received on',
  'Right to work',
  'Phone Number',
  'DOB',
  'Action',
  'Detail view'
]
