import { FormControl } from 'react-bootstrap'
import styled from 'styled-components'

export const LoginWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4f4f4;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const LoginContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 0 37px #0815420d;
  margin: auto;
  padding: 40px;
  width: 450px;
  .second {
    margin-left: 20px;
    padding: 10px;
    font-weight: 600;
    color: #ff6100;
    border-radius: 50%;
  }
  span {
    color: blue;
    cursor: pointer;
  }
  .error {
    position: relative;
    top: -19px;
    left: -70px;
    font-size: 13px;
    color: red;
  }

  .resend {
    margin-left: 10px;
    background: transparent;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    width: 25%;
    color: #ff6100;
  }
`

export const Title = styled.h4`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  p {
    color: #898989;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin-bottom: 25px;
    letter-spacing: 0.7px;
    line-height: 1.7;
  }
`

export const Label = styled.h4`
  font-size: 13px;
  font-weight: 600;
`

export const InputValues = styled(FormControl)`
  background: #f5f5f5;
  margin: 15px 0;
  border-radius: 6px;
  height: 45px;
  position: relative;
  &::placeholder {
    color: #1a1668;
    font-size: 15px;
    font-weight: 400;
  }
  .form-control:focus {
    box-shadow: none;
  }
`

export const LoginButton = styled.div<{ disabled?: boolean }>`
  background: #242864;
  color: #fff;
  border: none;
  height: 36px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
  opacity: ${({ disabled }) => (disabled ? '0.5' : null)};
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.02857em;
  border-radius: 0.5rem;
  text-align: center;
  align-content: center;
  padding: 0.525rem 1.5rem;
  margin: auto 4px;
  cursor: pointer;
  &:focus,
  &:hover,
  &:active {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  &:disabled {
    pointer-events: auto;
  }
`

export const HelperText = styled.div`
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  p {
    font-weight: 700;
    cursor: pointer;
    color: blue;
    position: relative;
    left: 6px;
    &:hover {
      text-decoration: underline;
    }
  }
`
