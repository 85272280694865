import { BrowserRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import './App.css'
import Header from './component/Header'
import theme from './const/theme'
import { weight } from './const/fonts'
import Dohrwebsite from './Dohr'
import Login from './Login'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from './redux/store'

const Body = styled.div`
  display: flex;
  min-height: calc(100vh - 40px);
  body {
    overflow: auto;
    scroll-behavior: smooth;
  }
  #container {
    z-index: 0;
  }
  .react-datepicker-popper {
    z-index: 999;
    padding: 0 !important;
  }
  th {
    font-weight: ${weight.bold};
  }
  td {
    font-weight: ${weight.normal};
  }
`

const App = () => {
  const { isLoggedIn } = useSelector(
    (state: RootState) => ({
      isLoggedIn: state.settings.isLoggedIn
    }),
    shallowEqual
  )
  return (
    <ThemeProvider theme={theme}>
      {isLoggedIn ? (
        <BrowserRouter>
          <Header />
          <Body>
            <Dohrwebsite />
          </Body>
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </ThemeProvider>
  )
}

export default App
