/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from 'react'
import moment from 'moment'
import { Column, PageWrapper, SectionTitle } from '../../../../component'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { RootState } from '../../../../redux/store'
import { websitecreatead } from '../../../../redux/admin/WebsiteAd/types'
const PublishedAdView = (): ReactElement => {
  const { updateViewad } = useSelector((state: RootState) => ({
    updateViewad: state.Websitead.updatecreatedapplist as websitecreatead
  }))

  const dateformatTime = (time: Date | null | string) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  const calculateAdStatus = (
    publishedDate: Date | null,
    endDate: Date | null
  ): string => {
    const currentDate = new Date()
    if (publishedDate && endDate) {
      if (currentDate >= publishedDate && currentDate <= endDate) {
        return 'Active'
      } else if (currentDate > endDate) {
        return 'Ended'
      }
    }
    return 'N/A' // Handle cases where either date is not available
  }

  return (
    <PageWrapper>
      <SectionTitle title="Published Ad detail view" hasBorder hasBackButton />
      <>
        <Row>
          <Col xs="3">
            <Column keyName="Ad ID" value={updateViewad.id} />
            <Column keyName="Ad title" value={updateViewad.ad_title} />
            <Column
              keyName="Published date"
              value={dateformatTime(updateViewad.published_date)}
            />
            <Column
              keyName="End date"
              value={dateformatTime(updateViewad.end_date)}
            />
          </Col>

          <Col xs="3">
            <Column keyName="Status" value={ calculateAdStatus(
                          new Date(dateformatTime(updateViewad.published_date)),
                          new Date(dateformatTime(updateViewad.end_date))
                        )}
                         
                        />
            <Column keyName="Location" value={updateViewad.location} />
            <Column keyName="Sector" value={updateViewad.sector} />
            <Column keyName="Ad description" value={updateViewad.descrption} />
          </Col>
        </Row>
      </>
    </PageWrapper>
  )
}

export default PublishedAdView
