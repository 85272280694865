import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialState,
  ContactUsResposne,
  DOHRFeedBack,
  newsemailresponse
} from './types'
import { getContactUsFeed, getNewsEmail } from './api'
import { getDOHRFeedBAck } from './api'

export const initialState: InitialState = {
  isLoading: false,
  ContactusFeedback: [],
  DOHRFeedBack: [],
  updateViewDOHRFeedBack: null,
  newsemail: []
}

export const ContactUsSlice = createSlice({
  name: 'ContactUs',
  initialState,
  reducers: {
    updateDOHRfeedback: (state, action: PayloadAction<DOHRFeedBack | null>) => {
      state.updateViewDOHRFeedBack = action.payload
    }
  },

  extraReducers: {
    [getContactUsFeed.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getContactUsFeed.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<ContactUsResposne>>
    ) => {
      state.isLoading = false
      state.ContactusFeedback = action?.payload
    },
    [getContactUsFeed.rejected.toString()]: (state) => {
      state.isLoading = false
    },

    [getDOHRFeedBAck.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getDOHRFeedBAck.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<DOHRFeedBack>>
    ) => {
      state.isLoading = false
      state.DOHRFeedBack = action?.payload
    },
    [getDOHRFeedBAck.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [getNewsEmail.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<newsemailresponse>>
    ) => {
      state.isLoading = false
      state.newsemail = action?.payload
    }
  }
})

export default ContactUsSlice.reducer
