import { ReactElement, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  PrimaryButton,
  SectionTitle
} from '../../../../component'
import { addRole, getRole } from '../../../../redux/admin/settings/api'
import { RootState } from '../../../../redux/store'
import { PostRole } from '../../../../redux/admin/settings/types'
import { RoleFilter } from './subcomponents'
import { CardWraper } from '../../Institute/AddInstitute/subcomponent'

const AddRole = (): ReactElement => {
  const dispatch = useDispatch()
  const { RoleData } = useSelector(
    (state: RootState) => ({
      RoleData: state.settings.role
    }),
    shallowEqual
  )

  const [AddRole, setAddRole] = useState<PostRole>({ title: '' })

  useEffect(() => {
    dispatch(getRole())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Add Role" hasBorder />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <Input
              value={AddRole.title}
              placeholder="Add Role"
              onChange={(value: string) => {
                setAddRole({ title: value })
              }}
            />
          </DropdownWrapper>
          <PrimaryButton
            height="36px"
            onClick={() => {
              dispatch(addRole(AddRole))
            }}
          >
            Submit
          </PrimaryButton>
        </FlexWrapper>

        <RoleFilter Tableitems={RoleData} />
      </CardWraper>
    </PageWrapper>
  )
}

export default AddRole
