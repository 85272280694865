import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services'
import apiEndpoints from '../../../const/apiEndPoints'
import { ContactUsProp } from './types'

// Define a function to show an alert for network errors
const showAlertForNetworkError = (error: any) => {
  alert(`Network Error:  ${error.message}`) // You can customize the alert message
}

export const getContactUsFeed = createAsyncThunk(
  '/Get_contact_us_data',
  async (requestPayload: ContactUsProp, { rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.getContactUsData,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      return response?.data?.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const getDOHRFeedBAck = createAsyncThunk(
  '/get_feedback_dohr',
  async (requestPayload: ContactUsProp, { rejectWithValue }) => {
    try {
      const response = await api.get(apiEndpoints.getDOHRFeedBAck, {
        params: requestPayload // Pass the payload as the params property
      })
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      return response?.data?.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const deleteConsultantmessage = createAsyncThunk(
  'contactusConsultant/delete',
  async (requestPayload: { id: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.deletecontactus,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      if (response.data) {
        dispatch(
          getContactUsFeed({
            type: 2
          })
        )
      }
      return response.data.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const deleteCustomermessage = createAsyncThunk(
  'contactusCustomer/delete',
  async (requestPayload: { id: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.deletecontactus,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      if (response.data) {
        dispatch(
          getContactUsFeed({
            type: 1
          })
        )
      }
      return response.data.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const deleteWebmessage = createAsyncThunk(
  'contactusConsultant/delete',
  async (requestPayload: { id: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.deletecontactus,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      if (response.data) {
        dispatch(
          getContactUsFeed({
            type: 3
          })
        )
      }
      return response.data.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const deleteDOHRmessage = createAsyncThunk(
  'contactusdohr/delete',
  async (requestPayload: { id: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.deletedohrfeedback,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      if (response.data) {
        dispatch(
          getDOHRFeedBAck({
            type: 0
          })
        )
      }
      return response.data.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)

export const getNewsEmail = createAsyncThunk(
  'inbox/getnewsemail',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getnewsemail)
    return response.data.message
  }
)

export const deletenewsemail = createAsyncThunk(
  'inbox/deletegetnewsemail',
  async (requestPayload: { id: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.deltenewsemail,
        requestPayload
      )
      if (response.status !== 200) {
        showAlertForNetworkError(response.statusText)
        return rejectWithValue('Network Error')
      }
      if (response.data) {
        dispatch(getNewsEmail())
      }
      return response.data.message
    } catch (error) {
      showAlertForNetworkError(error)
      return rejectWithValue('Network Error')
    }
  }
)
