import Card from "react-bootstrap/Card";
import styled from "styled-components";

const CardWrapper = styled(Card)`
  box-shadow: 0 1px 5px 0 grey;
  width: ${({ width }) => (width ? `${width}%` : "45%")};
  height: ${({ height }) => (height ? height : "auto")};
  background-color: ${({ color }) => (color ? color : "")};
  margin: 12px;
  min-width: fit-content !important;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : "none"};
`;

export default CardWrapper;
