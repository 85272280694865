import { ReactElement } from 'react'
import { FlexWrapper, PageWrapper, SectionTitle } from '../../../../component'


const ManualPushConsultant = (): ReactElement => {
    
    return (
      <PageWrapper>
        <FlexWrapper>
        <SectionTitle title="Manual Push Notification" hasBorder />
         
        </FlexWrapper>
      </PageWrapper>
     
    )
}

export default ManualPushConsultant