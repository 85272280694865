import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import fonts, { weight } from '../../const/fonts'
import { ListProps } from './typings'

interface MenuContainerProps {
  isChild?: boolean
  background?: string
}

export const MenuContainer = styled.ul<MenuContainerProps>`
  margin: 0;
  width: 16rem;
  position: relative;
  z-index: 1;
  left: 0;
  background: #ffffff;
  overflow-x: hidden;
  padding-left: ${({ isChild }) => (isChild ? '24px' : '0')};
  list-style: none;
  border-right: 1px solid #e0e6ed;
  @media (max-width: 415px) {
    width: ${({ isChild }) => (isChild ? 'auto' : '')};
  }
`

export const ListWrapper = styled.div`
  margin: 5px;
`

export const ListItems = styled.li<ListProps>`
  padding: 1px 0;
  display: flex;
  color: ${({ $isSelected }) => ($isSelected ? '#6c5ffc' : '#282f53')};
`
export const Anchor = styled(Link)<ListProps>`
  width: 100%;
  padding: 16px 8px;
  text-decoration: none;
  font-size: ${({ font }) => (font ? `13px` : `${fonts.small}px`)};
  color: ${({ $isSelected }) => ($isSelected ? '#0E23DF' : '#282f53')};
  display: block;
  transition: 0.3s;
  margin-top: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: ${weight.bold};
  letter-spacing: 1px;
  
  &:hover {
    color: white;
    background:#003824;
    border-radius:6px;
    
    };
  
`

export const ChevronIcon = styled(FontAwesomeIcon)<ListProps>`
position:absolute;
left:220px;
  padding: 0;
  font-size: 9px;
  color:green;
  &:hover {
    animation: rotation 2s infinite linear;
    
  }
`
export const MenuListWrapper = styled.div<ListProps>`
  padding: 11px 10px;
  height: 60px;
  display: flex;
  cursor: pointer;
  width: 100%;
  background: ${({ $isSelected }) => ($isSelected ? '#fff' : '#fff')};
  /* border-radius: 6px; */
  align-items: center;
  transition: 0.4s;

`

export const FontIcon = styled(FontAwesomeIcon)<ListProps>`
  margin: auto 12px;
  text-align: center;
  font-size: ${({ isMenuOpen }) => (isMenuOpen ? '13px' : '15px')};
  color: ${({ $isSelected }) => ($isSelected ? '#351038' : 'gray')};
  &:hover {
    color: ${({ $isSelected }) => ($isSelected ? '#6c5ffc' : '#282f53')};
  }
`
export const MenuLogo = styled.img<ListProps>`
  margin: auto 12px;
  width: 15px;
  object-fit: contain;
  text-align: center;
  font-size: ${({ isMenuOpen }) => (isMenuOpen ? '13px' : '16px')};
  color: ${({ $isSelected }) => ($isSelected ? '#351038' : 'gray')};
`
