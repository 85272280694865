import React, { ReactElement, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon
} from '../../../../component'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Consultants/subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router'
import ROUTES from '../../../../const/routes'
import { getAllcustomerdata } from '../../../../redux/admin/customer/api'
import { updateViewCustomer } from '../../../../redux/admin/customer/action'

const ViewTotalCustomer = (): ReactElement => {
  const { customerlist, isLoading } = useSelector(
    (state: RootState) => ({
      customerlist: state.customer.listofcustomer,
      isLoading: state.customer.isLoading
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const history = useHistory()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const sorteddata = [...customerlist]
  const [filterType] = useState(1)

  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number

  useEffect(() => {
    dispatch(
      getAllcustomerdata({
        type: filterType,
        is_verfied: true
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  // const handleMakeActive = async (customerId: number) => {
  //   const payload: customerai = {
  //     data: false,
  //     id: customerId,
  //   };
  //   await dispatch(customerstatus(payload)); // Dispatch the action to make the customer active

  //   // Fetch customer data again after making changes
  //   await dispatch(
  //     getAllcustomer({
  //       type: filterType,
  //       is_verfied: true,
  //     })
  //   );
  // };

  // const handleMakeInactive = async (customerId: number) => {
  //   const payload: customerai = {
  //     data: true, // Toggle the value
  //     id: customerId,
  //   };
  //   await dispatch(customerstatus(payload)); // Dispatch the action to make the customer inactive

  //   // Fetch customer data again after making changes
  //   await dispatch(
  //     getAllcustomer({
  //       type: 1,
  //       is_verfied: false,
  //     })
  //   );
  // };

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="View Total customer" hasBorder />
        </FlexWrapper>
        <FlexWrapper></FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper>
            <TablePaginationEntries
              showEntries
              handleSelectedDataPerPage={(value) => {
                setdataPerPage(value)
              }}
            />
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {sorteddata
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{`${item.first_name} ${item.last_name} `}</td>
                      <td>{item.company_name}</td>
                      <td>{item.email_id}</td>
                      <td>{item.telephone_number}</td>
                      <td>
                        <Icon
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.VIEWCUSTOMERDETAIL
                            })

                            dispatch(updateViewCustomer(item))
                          }}
                          icon={['fas', 'eye']}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewTotalCustomer
