import styled from 'styled-components'
import { colors } from '../../const/theme'
import { Button } from 'react-bootstrap'
import { NavigationButtonProps } from './typings'
import { Body } from '../../typography'

interface ButtonProps {
  isActive: boolean
}

export const TableFooterWrapper = styled.div`
  width: 98%;
  height: 45px;
  align-items: center;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  ul {
    padding: 0 10px;
  }
`

export const NavigationButton = styled(Button)<NavigationButtonProps>`
  color: ${colors.gray};
  border: 1px solid gray;
  text-transform: capitalize;
  background: ${colors.white};
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  &:hover,
  &:focus {
    border-color: ${colors.white};
    background: #c7c7c7;
    color: ${colors.black};
  }
  &:disabled {
    background: lightgray;
    color: ${colors.black};
    border: 1px solid gray;
  }
  min-width: 80px;
  max-width: 150px;
  div {
    display: flex;
    justify-content: ${({ $isLeft }) => ($isLeft ? 'end' : 'start')};
  }
  svg {
    margin: auto 10px;
  }

  @media (max-width: 767px) {
    margin: 5px;
    text-align: center;
    &:hover,
    &:focus {
      border-color: ${colors.white};
      background: #c7c7c7;
      color: ${colors.black};
    }
  }
`

export const PageDisplay = styled(Body)`
  width: 25%;
  margin-top: 7px;
  font-weight: 450;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 10px;
    text-align: center;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 50%;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const EntriesWrapper = styled.div`
  font-size: 14px;
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
  }
  @media only screen and (max-width: 767px) {
    font-size: 15px;
    align-items: center;
  }
`

export const NumberButton = styled.li<ButtonProps>`
  display: inline-block;
  text-align: center;
  height: 35px;
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
  border: none;
  button {
    font-size: 14px;
    color: ${colors.black};
    text-align: center;
    font-weight: 600;
    background-color: ${({ isActive }) => (isActive ? 'lightblue' : 'white')};
    color: ${colors.gray};
    &:active,
    &:focus {
      background-color: ${({ isActive }) => (isActive ? 'lightblue' : 'white')};
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-right: 8px;
      margin-top: 8px;
    }
  }
`