export const dropList = [
  {
    id: "01",
    name: "Paid",
  },
  {
    id: "02",
    name: "Pending",
  },
];

export const tableHeader = [
  "S.No",
  "Customer",
  "Position",
  "Published Date-Time",
  "Company name",
  "Consultant",
  "Original ",
  "Reported ",
"Approved",
  " Approved hours",
  "Vacancy Status",
  "Detail view",
];
