import { ReactElement,useState } from "react";
import { RoleFilterProps } from "./typings";
import { TableHeaderData } from "./const";
import { FlexWrapper, PageWrapper } from "../../../../component";
import { Table } from "react-bootstrap";
import TablePaginationEntries from "../../../../component/TablePaginationEntires";
import TableFooter from "../../../../component/TableFooter";

export const RoleFilter = ({ Tableitems }: RoleFilterProps): ReactElement => {
  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(Tableitems?.length / dataPerPage)
  const pageButtonsToShow = 4
  let total:number;
  return (
    <PageWrapper>
      <FlexWrapper>
        <TablePaginationEntries  
        showEntries
                    handleSelectedDataPerPage={(value) => {
                      setdataPerPage(value)
                    }}/>
        <Table striped borderless hover>
          <thead style={{ backgroundColor: "#003824", color: "white" }}>
            <tr style={{ textAlign: "center" }}>
              {TableHeaderData  .map((data, index) => (
                <th key={`Header Unique id - ${index}`}>{data}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Tableitems?.slice(
                          (total = currentPage * dataPerPage - dataPerPage),
                          currentPage * dataPerPage
                        ).map((item, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                <td>{1+total++}</td>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{item.is_active == "1" ? "True" : "False"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TableFooter
                    currentPage={currentPage}
                    showEntries
                    startValue={startindex}
                    showPageDisplay={false}
                    pageValue={endindex}
                    TotalIems={Tableitems?.length}
                    totalPages={totalPages}
                    showButtonDisplay
                    pageButtonsToShow={pageButtonsToShow}
                    handlePrevious={() =>
                      setCurrentPages(Math.max(currentPage - 1, 1))
                    }
                    handleNext={() =>
                      setCurrentPages(Math.min(currentPage + 1, totalPages))
                    }
                    handlePageClick={handlePageClick}
                  />
      </FlexWrapper>
    </PageWrapper>
  );
};
