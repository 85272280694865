const strings = {
  admin: {

  },
  button: {
    back: 'Back',
    save: 'Save',
    apply: 'Apply',
    approve: 'Approve',
    reject: 'Reject',
    cancel: 'Cancel',
    evaluate: 'Evaluate',
    review: 'Review',
    done: 'Done',
    selectAll: 'Select All',
    dSelectAll: 'Deselect All',
    proceed: 'Proceed',
    createSectionText: 'Create Section',
    skipSectionText: 'Skip this step',
    download: 'Download',
    assign: 'Assign',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
    add: 'Add'
  },

}

export default strings
