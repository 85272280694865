import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIsMenuOpen } from '../../redux/Menu/action'
import { RootState } from '../../redux/store'
import {
  Hamburg,
  HamburgeButton,
  HeaderWrapper,
  Logo,
  LogoWrapper,
  SideContainer,
  Logout
} from './subcomponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateIsloggedIn } from '../../redux/admin/settings/action'

const Header = (): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => ({
    isMenuOpen: state.menu.isMenuOpen
  }))
  const dispatch = useDispatch()

  const handleToggleMenu = () => {
    dispatch(updateIsMenuOpen(!isMenuOpen))
  }

  return (
    <>
      <HeaderWrapper>
        <SideContainer>
          <LogoWrapper>
            <Logo>{'DoHR'}</Logo>
          </LogoWrapper>
          <HamburgeButton variant="transparent" onClick={handleToggleMenu}>
            <Hamburg>
              <span className={'line'} />
              <span className={'line'} />
              <span className={'line'} />
            </Hamburg>
          </HamburgeButton>
        </SideContainer>

        <Logout
          className="logout"
          onClick={() => {
            dispatch(updateIsloggedIn(false))
          }}
        >
          Logout{' '}
          <FontAwesomeIcon
            className="ico"
            icon={['fas', 'right-from-bracket']}
            style={{ color: 'white', fontSize: '20px' }}
          />
        </Logout>
      </HeaderWrapper>
    </>
  )
}

export default Header
