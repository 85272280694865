import React, { useEffect, useState } from 'react'
import {
  FlexWrapper,
  Icon,
  Loader,
  PageWrapper,
  SectionTitle,
  Input,
  PrimaryButton
} from '../../../component'
import { CardWraper, IsApprovedAction } from '../Consultants/subcomponents'
import { Table, Modal, Button } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  handleDenyApplication,
  handleApproveApplication,
  deleteopenapplication
} from '../../../redux/admin/customer/api'

import { RootState } from '../../../redux/store'
import TablePaginationEntries from '../../../component/TablePaginationEntires'
import TableFooter from '../../../component/TableFooter'
import { TableHeaderData } from './const'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../const/routes'
import moment from 'moment'
import {
  updateApplicationData,
  getAllApplication
} from '../../../redux/admin/customer/action'
const Openapplication = () => {
  const { getApprovedlist, isLoading } = useSelector(
    (state: RootState) => ({
      getApprovedlist: state.customer.getApprovedlist,
      isLoading: state.customer.isLoading
    }),
    shallowEqual
  )

  // useEffect(() => {
  //   dispatch(Getopenapplications())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [selectedApplicationId, setSelectedApplicationId] = useState(null)
  const [filterType, setFilterType] = useState<any>(1) // Default: All (type 1)
  const [selectedEmailid, setselectedEmailid] = useState('')
  const [showModal, setShowModal] = useState<any>('')
  useEffect(() => {
    if (filterType === 1) {
      dispatch(
        getAllApplication({
          type: 1
        })
      )
    } else if (filterType === 2) {
      dispatch(
        getAllApplication({
          type: 2
        })
      )
    } else if (filterType === 3) {
      dispatch(
        getAllApplication({
          type: 3
        })
      )
    }
    // Add more conditions for other filter types if needed

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(getApprovedlist?.length / dataPerPage)
  const pageButtonsToShow = 4

  let total: number

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const filteredSearchData = getApprovedlist?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      `${items.first_name} ${items.last_name}`
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })

  const dateformatTime = (time: Date) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const savedFilterType = params.get('filterType')

    if (savedFilterType) {
      setFilterType(Number(savedFilterType))
    }

    // ... your existing code
  }, [history.location.search])

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper>
          <SectionTitle title="Incoming open applications" />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper noMargin justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                value={searchQuery}
                placeholder="Search by name"
                onChange={handleSearch}
              />
              <select
                value={filterType}
                onChange={(e) => {
                  setFilterType(Number(e.target.value))
                  history.push(`?filterType=${e.target.value}`)
                }}
                style={{ width: '20%', height: '70%' }}
              >
                <option value={1}>Applied</option>
                <option value={2}>Approved </option>
                <option value={3}>Denied</option>
              </select>
            </FlexWrapper>
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredSearchData
                  .reverse()
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{`${item.first_name} ${item.last_name}`}</td>
                      <td>{dateformatTime(item.created_at)}</td>
                      <td>{item.occup_sector}</td>

                      <td style={{ width: '150px' }}>
                        {filterType === 2 || filterType === 3 ? (
                          <Icon
                            title="Delete"
                            onClick={() => {
                              setShowModal(item?.id)
                            }}
                            icon={['far', 'trash-alt']}
                          />
                        ) : (
                          <IsApprovedAction
                            handleApprove={() => {
                              // Show the approval confirmation modal
                              setShowApproveModal(true)
                              // Set the selected consultant ID for later use
                              setSelectedApplicationId(item.id)
                              setselectedEmailid(item?.email_id)
                            }}
                            handleDeny={() => {
                              // Show the deny confirmation modal
                              setShowDenyModal(true)
                              // Set the selected consultant ID for later use
                              setSelectedApplicationId(item.id)
                              setselectedEmailid(item?.email_id)
                            }}
                          />
                        )}
                      </td>

                      <td>
                        <Icon
                          title="View application"
                          onClick={() => {
                            dispatch(updateApplicationData(item))
                            history.push(ROUTES.OPENAPPLICATIONVIEW)
                            history.push(`?filterType=${filterType}`)
                          }}
                          icon={['fas', 'eye']}
                        />{' '}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={getApprovedlist?.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
      <Modal show={showApproveModal} onHide={() => setShowApproveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve this application?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              // Handle the approval logic here using the selectedConsultantId
              dispatch(
                handleApproveApplication({
                  id: selectedApplicationId,
                  is_denied: false,
                  is_approved: true,
                  email_id: selectedEmailid
                })
              )
              setShowApproveModal(false)
            }}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Deny Confirmation Modal */}
      <Modal show={showDenyModal} onHide={() => setShowDenyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to deny this application?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              // Handle the deny logic here using the selectedConsultantId
              dispatch(
                handleDenyApplication({
                  id: selectedApplicationId,
                  is_denied: true,
                  is_approved: false,
                  email_id: selectedEmailid
                })
              )
              setShowDenyModal(false)
            }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowDenyModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={!!showModal}
        onHide={() => {
          setShowModal('')
        }}
      >
        <Modal.Header>
          Are you sure you want to delete this applicant?
        </Modal.Header>
        <Modal.Footer>
          <PrimaryButton
            left
            onClick={() => {
              dispatch(
                deleteopenapplication({
                  id: showModal,
                  filtertype: filterType
                })
              )
              setShowModal('')
            }}
          >
            Yes
          </PrimaryButton>

          <PrimaryButton
            left
            onClick={() => {
              setShowModal('')
            }}
          >
            No
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  )
}

export default Openapplication
