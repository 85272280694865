import React, { ReactElement } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { getConsultantResponse } from '../../../../../redux/admin/consultant/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { Row, Col, Accordion } from 'react-bootstrap'
import styled from 'styled-components'
import { H3 } from '../../../../../typography'
import fonts, { weight } from '../../../../../const/fonts'

const Title = styled(H3)`
  margin-bottom: 5px;
  font-size: ${fonts.small}px;
  font-weight: ${weight.normal};
  font-family: 'Montserrat', sans-serif;
  color: darkblue;
`

const ViewConsultantdata = (): ReactElement => {
  const { setSelectedConsutant } = useSelector((state: RootState) => ({
    setSelectedConsutant: state.consultant
      .setSelectedConsutant as getConsultantResponse
  }))
  const info_work_exp_in_school = JSON.parse(
    setSelectedConsutant?.info_work_exp_in_school || '[]'
  )
  const info_work_exp_out_school = JSON.parse(
    setSelectedConsutant?.info_work_exp_out_school || '[]'
  )
  const info_other_work_experience = JSON.parse(
    setSelectedConsutant?.info_other_work_experience || '[]'
  )
  const languageSkills = JSON.parse(
    setSelectedConsutant.language_skills || '[]'
  )
  const dateformatTime = (time: Date) => {
    return moment(time).format('YYYY-MM-DD ')
  }
  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle title="Detail view" hasBorder hasBackButton />
        <FlexWrapper justifyContent="center">
          <Accordion defaultActiveKey="" style={{ width: '100%' }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Title>Personal information</Title>
              </Accordion.Header>
              <Accordion.Body>
                <FlexWrapper>
                  <Row>
                    <Col xs={12} md={6}>
                      <Column
                        keyName="First name"
                        value={`${setSelectedConsutant.first_name} `}
                      />
                      <Column
                        keyName="last name"
                        value={`${setSelectedConsutant.last_name} `}
                      />
                      <Column
                        keyName="Address"
                        value={setSelectedConsutant?.address}
                      />
                      <Column
                        keyName="Postalcode"
                        value={setSelectedConsutant?.postalcode}
                      />
                      <Column
                        keyName="Area name"
                        value={setSelectedConsutant?.area_name}
                      />
                      <Column
                        keyName="Contact number"
                        value={setSelectedConsutant?.phone_number}
                      />
                      <Column
                        keyName="Email"
                        value={setSelectedConsutant?.email_id}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <Column
                        keyName="Date of Birth"
                        value={dateformatTime(
                          setSelectedConsutant.date_of_brith
                        )}
                      />
                      <Column
                        keyName="I have a swedish bank account
  "
                        value={
                          setSelectedConsutant?.reg_i_have_swedish === 1
                            ? 'Yes'
                            : 'No'
                        }
                      />
                      <Column
                        keyName="My preferred type of workplace"
                        value={setSelectedConsutant?.preferred_work_institution}
                      />
                      <Column
                        keyName="Briefly describe why you would like to work as a substitute"
                        value={setSelectedConsutant.reg_short_description}
                      />
                      <Column
                        keyName="I am a student"
                        value={
                          setSelectedConsutant?.reg_iam_teacher_student === 1
                            ? 'Yes'
                            : 'No'
                        }
                      />
                      {setSelectedConsutant?.reg_iam_teacher_student === 1 && (
                        <>
                          <Column
                            keyName="Type of study"
                            value={setSelectedConsutant.type_of_study}
                          />
                          <Column
                            keyName="Institution name"
                            value={setSelectedConsutant.name_of_institutition}
                          />
                          {setSelectedConsutant.proof_of_studies && (
                            <Column
                              keyName="Proof of studies"
                              value={
                                <a
                                  href={`https://api.dohr.io/${setSelectedConsutant.proof_of_studies}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Proof of Studies.pdf
                                </a>
                              }
                            />
                          )}
                        </>
                      )}
                      {setSelectedConsutant?.reg_iam_teacher_student !== 1 && (
                        <>
                          <Column
                            keyName="I am a self employed/freelancer"
                            value={
                              setSelectedConsutant.self_employed === 1
                                ? 'Yes'
                                : 'No'
                            }
                          />
                          <Column
                            keyName="I am retired/Pensioner"
                            value={
                              setSelectedConsutant.iam_retired_pensioner === 1
                                ? 'Yes'
                                : 'No'
                            }
                          />
                          <Column
                            keyName="I have primary occupation(minimum 50%)"
                            value={
                              setSelectedConsutant.other_occupations === 1
                                ? 'Yes'
                                : 'No'
                            }
                          />
                          {setSelectedConsutant.other_occupations === 1 && (
                            <Column
                              keyName="Specify"
                              value={
                                setSelectedConsutant.info_other_occupations
                              }
                            />
                          )}
                          {setSelectedConsutant.other_occupations === 0 && (
                            <Column
                              keyName="If others,Specify"
                              value={
                                setSelectedConsutant.info_other_occupations
                              }
                            />
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </FlexWrapper>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <Title>My work experience</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <Column
                      keyName="I have experience working with children/young adults in a school setting"
                      value={
                        setSelectedConsutant?.work_exp_in_school === 1
                          ? 'Yes'
                          : 'No'
                      }
                    />
                    {setSelectedConsutant?.work_exp_in_school === 1 &&
                      info_work_exp_in_school.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <Column
                              keyName={`Employer ${index + 1}`}
                              value={item.name_of_emp}
                            />
                            <Column
                              keyName={`Duration ${index + 1}`}
                              value={item.duration}
                            />
                            <Column
                              keyName={`Role ${index + 1}`}
                              value={item.title_of_role}
                            />
                            <Column
                              keyName={`Responsibilities ${index + 1}`}
                              value={item.key_res}
                            />
                          </div>
                        )
                      )}

                    <Column
                      keyName="I have experience working with children/young adults outside of school"
                      value={
                        setSelectedConsutant?.work_exp_out_school === 1
                          ? 'Yes'
                          : 'No'
                      }
                    />
                    {setSelectedConsutant.work_exp_out_school === 1 &&
                      info_work_exp_out_school.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <Column
                              keyName={`Employer ${index + 1}`}
                              value={item.name_of_emp}
                            />
                            <Column
                              keyName={`Duration ${index + 1}`}
                              value={item.duration}
                            />
                            <Column
                              keyName={`Role ${index + 1}`}
                              value={item.title_of_role}
                            />
                            <Column
                              keyName={`Responsibilities ${index + 1}`}
                              value={item.key_res}
                            />
                          </div>
                        )
                      )}

                    <Column
                      keyName="Other work experience"
                      value={
                        setSelectedConsutant?.other_work_experience === 1
                          ? 'Yes'
                          : 'No'
                      }
                    />
                    {setSelectedConsutant.other_work_experience === 1 &&
                      info_other_work_experience.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <Column
                              keyName={`Employer ${index + 1}`}
                              value={item.name_of_emp}
                            />
                            <Column
                              keyName={`Duration ${index + 1}`}
                              value={item.duration}
                            />
                            <Column
                              keyName={`Role ${index + 1}`}
                              value={item.title_of_role}
                            />
                            <Column
                              keyName={`Responsibilities ${index + 1}`}
                              value={item.key_res}
                            />
                          </div>
                        )
                      )}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <Title>My availablity</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column
                  keyName="Where can you start"
                  value={
                    setSelectedConsutant?.as_soon_as_possible === 1
                      ? 'As soon as possible'
                      : setSelectedConsutant?.as_soon_as_possible === 0
                      ? 'Specify date'
                      : 'NA'
                  }
                />
                {setSelectedConsutant?.as_soon_as_possible === 0 && (
                  <Column
                    keyName="Specify Date"
                    value={dateformatTime(setSelectedConsutant?.specify_date)}
                  />
                )}
                <Column
                  keyName="Select hours/Week
                                        "
                  value={setSelectedConsutant?.working_hours_or_weeks}
                />
                <Column
                  keyName="I can work evening or weekends
                                        "
                  value={
                    setSelectedConsutant?.working_evening_or_weekends === 1
                      ? 'Yes'
                      : setSelectedConsutant?.working_evening_or_weekends === 0
                      ? 'No'
                      : 'NA'
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <Title>My language skills</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column keyName="Swedish" value={languageSkills.swedish} />
                <Column keyName="English" value={languageSkills.english} />
                <Column
                  keyName="Other"
                  value={languageSkills.other.map(
                    (entry: any, index: number) => (
                      <div key={index}>
                        <p> {entry.lang}</p>
                        <p>Proficiency: {entry.proficiency}</p>
                      </div>
                    )
                  )}
                />
              </Accordion.Body>
            </Accordion.Item>{' '}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <Title>Other information</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column
                  keyName="I have a valid police clearance certificate
                                        "
                  value={
                    setSelectedConsutant?.police_clearance_report === 1
                      ? 'Yes'
                      : setSelectedConsutant?.police_clearance_report === 0
                      ? 'No'
                      : 'NA'
                  }
                />
                {/* 
                {setSelectedConsutant?.police_clearance_report === 1 && (
                  <>
                    <Column
                      keyName="Police report"
                      value={
                        <a
                          href={`https://api.dohr.io/${setSelectedConsutant.police_report}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Police report.pdf
                        </a>
                      }
                    />
                  </>
                )} */}

                <Column
                  keyName="I have a right to work in Sweden?"
                  value={
                    setSelectedConsutant?.right_to_work_in_Sweden === 1
                      ? 'Yes'
                      : setSelectedConsutant?.right_to_work_in_Sweden === 0
                      ? 'No'
                      : 'NA'
                  }
                />
                {/* 
                {setSelectedConsutant?.right_to_work_in_Sweden === 1 && (
                  <>
                    {setSelectedConsutant?.Iam_EU_citizen === 1 && (
                      <>
                        <Column keyName="I am an EU citizen" value="Yes" />
                        <Column
                          keyName="main page of your passport"
                          value={
                            <a
                              href={`https://api.dohr.io/${setSelectedConsutant.right_to_work_in_Sweden_attachment}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Passport.pdf
                            </a>
                          }
                        />
                      </>
                    )}

                    {setSelectedConsutant?.Iam_non_EU_citizen === 1 && (
                      <>
                        <Column keyName="I am a non-EU citizen" value="Yes" />
                        <Column
                          keyName="work permit"
                          value={
                            <a
                              href={`https://api.dohr.io/${setSelectedConsutant.right_to_work_in_Sweden_attachment}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Work Permit.pdf
                            </a>
                          }
                        />
                      </>
                    )}
                  </>
                )} */}

                <Column
                  keyName="I have a valid driver's licence
                                        "
                  value={
                    setSelectedConsutant?.Driving_license === 1
                      ? 'Yes'
                      : setSelectedConsutant?.Driving_license === 0
                      ? 'No'
                      : 'NA'
                  }
                />
                <Column
                  keyName="How did you hear about us?
                                        "
                  value={
                    setSelectedConsutant?.How_did_you_hear_about_us
                      ? setSelectedConsutant?.How_did_you_hear_about_us
                      : 'NA'
                  }
                />
                <Column
                  keyName="Consent for GDPR was given on
                                        "
                  value={dateformatTime(setSelectedConsutant?.created_at)}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewConsultantdata
