export const TableHeaderData = [
  'S.No',
  'Customer',
  'Company name',
  'Email',
  'Created on',
  'Contact number',
  'Action',
  'Detail view'
]
