import React from 'react'
import { PrimaryButton } from '../../../../../component'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { GetAllCustomerresponse } from '../../../../../redux/admin/customer/types'

interface DataExportButtonProps {
  exportData: GetAllCustomerresponse[]
  filterType: number
}

const getFileNamePrefix = (filterType: number): string => {
  switch (filterType) {
    case 1:
      return 'All'
    case 2:
      return 'Active'
    case 3:
      return 'Inactive'
    case 4:
      return 'Awaiting'
    default:
      return 'Customer'
  }
}

const DataExportButton: React.FC<DataExportButtonProps> = ({
  exportData,
  filterType
}) => {
  const handleExport = async () => {
    const fileNamePrefix = getFileNamePrefix(filterType) // Define a function to get the prefix based on filterType
    const formattedData = exportData.map((item) => {
      return {
        Id: item.id,
        Customer: `${item.first_name} ${item.last_name}`,
        'Work email': item.email_id,
        'Work contact number': item.telephone_number,
        'Work address': item.address,
        'Postal code': item.postal_code,
        'Area name': item.area_name,
        'Company name': item.company_name,
        'Org number': item.organization_no,
        'Organisation Type': item.organization_type,
        'Invoice Address': item.invoice_address,
        'Invoice postal code': item.invoice_postal_code,
        'Invoice area name': item.invoice_area_name,
        'Invoice reference': item.invoice_reference,
        'Invoice email': item.invoice_email_id,
        'Routine instructions for the substitutes':
          item.Routine_instructions_for_the_substitutedh_customer
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'VacancyData')
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    saveAs(blob, `${fileNamePrefix}_Customer_data.xlsx`)
  }

  return (
    <PrimaryButton
      variant="success"
      onClick={handleExport}
      disabled={exportData.length === 0}
    >
      Export Customer Data
    </PrimaryButton>
  )
}

export default DataExportButton
