/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment' // Import Moment type
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { AllVacancyResposne } from '../../../../../redux/admin/vacancy/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'
import { faInfoCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useHistory } from 'react-router-dom'
import ROUTES from '../../../../../const/routes'
import { getAllPublishVacancy } from '../../../../../redux/admin/vacancy/api'

const ViewPublishedVacancy = (): ReactElement => {
  const { updateViewpublishvacancy, vacancyUserDetails } = useSelector(
    (state: RootState) => ({
      updateViewpublishvacancy: state.vacancy
        .updateViewpublishvacancy as AllVacancyResposne,

      vacancyUserDetails: state.consultant.vacancyUserDetails
    })
  )

  const mapVacancyStatusToStringAndColor = (status: string | number) => {
    switch (status) {
      case 0:
        return { label: 'Unfilled', color: 'red', icon: faCircleXmark }
      case 1:
        return { label: 'Filled', color: 'green', icon: faCircle }
      case 2:
        return { label: 'Completed', color: 'green', icon: faCheckCircle }
      default:
        return { label: 'Unknown', color: 'gray', icon: faCircleXmark }
    }
  }
  const renderApprovedTime = () => {
    // Check if both approved_etime and approved_stime are null
    if (
      updateViewpublishvacancy.approved_etime === null &&
      updateViewpublishvacancy.approved_stime === null
    ) {
      // Display report time range when both approved times are null
      return `${formatTime(
        updateViewpublishvacancy.report_start_time
      )} - ${formatTime(updateViewpublishvacancy.report_end_time)}`
    } else {
      // Otherwise, display the approved time range
      return `${updateViewpublishvacancy.approved_stime} - ${updateViewpublishvacancy.approved_etime}`
    }
  }
  const renderApprovedHrs = () => {
    // Check if both approved_etime and approved_stime are null
    if (updateViewpublishvacancy.total_approved_hrs === null) {
      // Display report time range when both approved times are null
      return `${updateViewpublishvacancy.report_total_whours}`
    } else {
      // Otherwise, display the approved time range
      return `${updateViewpublishvacancy.total_approved_hrs} `
    }
  }

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')

  // const dateformatTime = (time: Date) => {
  //   return moment(time).format('YYYY-MM-DD-HH:mm')
  // }

  const [showCancelInfo, setShowCancelInfo] = useState(false)
  const location = useLocation()
  const history = useHistory()
  // Parse the selected date from the URL parameters
  const searchParams = new URLSearchParams(location.search)
  const selectedDateFromURL = searchParams.get('date')
  const showAllToggleFromURL = searchParams.get('showAll')

  const dispatch = useDispatch()

  const handleBackClick = () => {
    if (selectedDateFromURL) {
      // Add a flag to control the API call

      dispatch(
        getAllPublishVacancy({
          type: 3,
          date: moment(selectedDateFromURL).format('YYYY-MM-DD'),
          get_all: false
        })
      )
      history.push({
        pathname: ROUTES.PUVLISHED_VACC,
        search: `?date=${selectedDateFromURL}&showAll=${showAllToggleFromURL}`
      })
    }
  }

  // // Set the initial selectedDate state using the parsed date
  // const [selectedDate, setSelectedDate] = useState<any>(
  //   selectedDateFromURL ? moment(selectedDateFromURL) : moment()
  // );

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle
          title="Detail view"
          hasBorder
          hasBackButton
          onBackClick={handleBackClick}
        />
        <FlexWrapper justifyContent="center">
          <Row>
            <Col xs={12} md={6}>
              <Column
                keyName="Customer name"
                value={`${updateViewpublishvacancy.customer_firstname} ${updateViewpublishvacancy.customer_lastname}`}
              />

              <Column
                keyName="Customer email"
                value={updateViewpublishvacancy?.customer_emailID}
              />
              <Column
                keyName="Position"
                value={updateViewpublishvacancy?.position}
              />
              <Column
                keyName="Published Date-Time"
                value={moment(
                  updateViewpublishvacancy?.created_at.slice(0, 19)
                ).format('YYYY-MM-DD- HH:mm')}
              />
              <Column
                keyName="Company name"
                value={updateViewpublishvacancy?.customer_company_name}
              />
              <Column
                keyName="Organisation type"
                value={updateViewpublishvacancy?.customer_organization_type}
              />
              <Column
                keyName="Job Description"
                value={updateViewpublishvacancy.discription}
              />
              <Column
                keyName="Vacancy date"
                value={updateViewpublishvacancy.v_date}
              />
              <Column
                keyName="Vacancy Time"
                value={`${formatTime(
                  updateViewpublishvacancy.from_time
                )} - ${formatTime(updateViewpublishvacancy.to_time)}`}
              />
              <Column
                keyName="Break time"
                value={updateViewpublishvacancy.break_time}
              />
              {updateViewpublishvacancy.vacancy_status === 0 ? (
                <Column keyName="Consultant" value="NA" />
              ) : (
                <Column
                  keyName="Consultant"
                  value={`${updateViewpublishvacancy.consultant_first_name} ${updateViewpublishvacancy.consultant_last_name}`}
                />
              )}
              <Column
                keyName=" Customer contact"
                value={updateViewpublishvacancy.customer_telephone_number}
              />
            </Col>

            <Col xs={12} md={6}>
              {updateViewpublishvacancy.vacancy_status !== 0 && (
                <>
                  <Column
                    keyName="Reported time"
                    value={`${formatTime(
                      updateViewpublishvacancy.report_start_time
                    )} - ${formatTime(
                      updateViewpublishvacancy.report_end_time
                    )}`}
                  />
                  <Column
                    keyName="Reported Hours"
                    value={updateViewpublishvacancy.report_total_whours}
                  />
                  <Column
                    keyName="Reason from Consultant  "
                    value={updateViewpublishvacancy.report_reason}
                  />
                </>
              )}
              {updateViewpublishvacancy.vacancy_status === 2 &&
                updateViewpublishvacancy.is_paid === 1 && (
                  <>
                    <Column
                      keyName="Approved time"
                      value={renderApprovedTime()}
                    />
                    <Column
                      keyName="Total Approved Hours"
                      value={renderApprovedHrs()}
                    />
                    <Column
                      keyName="Reason by customer"
                      value={updateViewpublishvacancy.feedback}
                    />
                  </>
                )}
              <Column
                keyName="Location"
                value={`${updateViewpublishvacancy.customer_address},${updateViewpublishvacancy.customer_area_name},${updateViewpublishvacancy.customer_postal_code}`}
              />
              <Column
                keyName="Recipient type"
                value={updateViewpublishvacancy.publish_to}
              />
              <Column
                keyName="Vacancy Status"
                value={
                  <>
                    {' '}
                    <FontAwesomeIcon
                      icon={
                        mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).icon
                      }
                      style={{
                        color: mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).color
                      }}
                    />
                    <span style={{ marginLeft: '5px' }}>
                      {
                        mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).label
                      }
                    </span>
                    {updateViewpublishvacancy?.cancelled_by_consultant ===
                      1 && (
                      <FontAwesomeIcon
                        title="This indicates that the previous consultant cancelled this vacancy 90 minutes or less prior to the start time. "
                        icon={faInfoCircle}
                        style={{
                          marginLeft: '5px',
                          color: 'blue',
                          cursor: 'pointer'
                        }}
                      />
                    )}
                  </>
                }
              />

              <Column keyName="By accepting this job, I confirm:" value={''} />
              <Column
                keyName="I study minimum 50%"
                isCheckbox
                input={
                  updateViewpublishvacancy?.I_am_a_full_time_student === 1
                    ? true
                    : false
                }
              />
              <Column
                keyName="I have another primary occupation - minimum 50%"
                isCheckbox
                input={
                  updateViewpublishvacancy?.self_employed_F_Skatt_or_freelancer_third_party ===
                  1
                    ? true
                    : false
                }
              />
              <Column
                keyName="Customer cancellation info"
                value={
                  <>
                    {updateViewpublishvacancy.is_canceled === 0 ? (
                      'NA'
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                          onClick={() => setShowCancelInfo(!showCancelInfo)}
                          style={{
                            cursor: 'pointer',
                            color:
                              updateViewpublishvacancy.cancellation_charges ===
                              '0'
                                ? 'blue'
                                : 'red'
                          }}
                        >
                          Cancelled
                        </button>
                        <button
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                            color:
                              updateViewpublishvacancy.cancellation_charges ===
                              '0'
                                ? 'blue'
                                : 'red'
                          }}
                          onClick={() => setShowCancelInfo(!showCancelInfo)}
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{ marginLeft: '5px' }}
                          />
                          {showCancelInfo && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '50%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                background: '#f1f1f1',
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                fontSize: '12px',
                                maxWidth: '100px'
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: '5px',
                                  fontWeight: 'bold'
                                }}
                              >
                                Cancel Information:
                              </div>
                              <div>
                                {updateViewpublishvacancy.cancellation_charges ===
                                '0'
                                  ? 'A customer cancelled the vacancy without charges.'
                                  : 'The cancellation was made after the cancellation deadline. An invoice to be issued to the customer!'}
                              </div>
                            </div>
                          )}
                        </button>
                      </div>
                    )}
                  </>
                }
              />
              {updateViewpublishvacancy?.vacancy_status === 0 &&
                updateViewpublishvacancy?.cancelled_by_consultant === 1 && (
                  <>
                    <Column
                      keyName="Previously cancelled by (Consultant)"
                      value={`${vacancyUserDetails[0]?.first_name} ${vacancyUserDetails[0]?.last_name}`}
                    />
                    <Column
                      keyName="Previously cancelled on (Consultant)"
                      value={moment(
                        vacancyUserDetails[0]?.vacancy_created_at?.slice(0, 19)
                      ).format('YYYY-MM-DD- HH:mm')}
                    />
                  </>
                )}
              {updateViewpublishvacancy.vacancy_status !== 0 && (
                <Column
                  keyName="Initial accepted time"
                  value={
                    updateViewpublishvacancy?.vacancy_Accepted_Primary_time ===
                    null
                      ? 'NA'
                      : moment(
                          String(
                            updateViewpublishvacancy?.vacancy_Accepted_Primary_time
                          )?.slice(0, 19)
                        ).format('YYYY-MM-DD- HH:mm')
                  }
                />
              )}
            </Col>
          </Row>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewPublishedVacancy
