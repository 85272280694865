import { ReactElement, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  PrimaryButton,
  SectionTitle
} from '../../../component'
import { RootState } from '../../../redux/store'
import { ExpressPassTable } from './subcomponent'
import { CardWraper } from '../Institute/AddInstitute/subcomponent'
import { PostexpressPass } from '../../../redux/admin/consultant/types'
import {
  addExpressPass,
  getExpressPass
} from '../../../redux/admin/consultant/api'
import ToastMessage from '../../../component/ToastMessge'

const AddExpressPass = (): ReactElement => {
  const dispatch = useDispatch()
  const { ExpressPassList, message } = useSelector(
    (state: RootState) => ({
      ExpressPassList: state.consultant.GetExpressPass,
      message: state.consultant.addExpressResponse
    }),
    shallowEqual
  )

  const [AddExpress, setAddExpress] = useState<PostexpressPass>({
    date: '',
    count: ''
  })

  const [show, setShow] = useState(false)
  const [passExists, setPassExists] = useState(false) // Track if a pass for the same date exists
  useEffect(() => {
    dispatch(getExpressPass())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    try {
      await dispatch(addExpressPass(AddExpress))
      // Check if the response message indicates that a pass for the same date exists
      if (message === 'already pass available at this date') {
        setPassExists(true)
      } else {
        setPassExists(false)
      }
      setShow(true)
    } catch (error) {
      console.error('Error adding express pass:', error)
      setPassExists(false)
      setShow(true)
    }
  }

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Reserve pool quota" hasBorder />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <Input
              value={AddExpress.date}
              inputType="date"
              onChange={(value: string) => {
                setAddExpress({ ...AddExpress, date: value })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddExpress.count}
              placeholder="Set quota"
              inputType="number"
              onChange={(value: string) => {
                const newValue = Number(value)
                if (!isNaN(newValue) && newValue >= 0) {
                  setAddExpress({ ...AddExpress, count: value })
                }
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
          </DropdownWrapper>
        </FlexWrapper>

        <ExpressPassTable Tableitems={ExpressPassList} />
      </CardWraper>
      <ToastMessage
        show={show}
        message={
          passExists
            ? 'Pass already exists for this date'
            : 'The reserve pool quota has been set!'
        }
        onCloseHandler={() => {
          setShow(false)
        }}
        bg={passExists ? 'danger' : 'success'}
      />
    </PageWrapper>
  )
}

export default AddExpressPass
