/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../redux/store'

import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  PrimaryButton,
  SectionTitle,
  EditableDropdown
} from '../../../../component'

import { OccupitationalList, placeDropdown } from './const'
import { CardWraper } from './subcomponents'
import { Modal, Button } from 'react-bootstrap'
import { websitead } from '../../../../redux/admin/WebsiteAd/api'
import { DropdownListProps } from '../../../../component/EditableDropdown/typings'
import { updatehasAdCreated } from '../../../../redux/admin/WebsiteAd/action'
import ToastMessage from '../../../../component/ToastMessge'
const CreateAd = (): ReactElement => {
  const { websitecreateadlist, isLoading, handleupdated } = useSelector(
    (state: RootState) => ({
      websitecreateadlist: state.Websitead.Websitecreateadapp,
      isLoading: state.Websitead.isLoading,
      handleupdated: state.Websitead.handleupdatead
    })
  )

  const dispatch = useDispatch()

  const [values, setvalues] = useState(websitecreateadlist)
  const [errors, setErrors] = useState({
    ad_title: '',
    location: '',
    sector: '',
    published_date: '',
    end_date: '',
    description: ''
  })
  const [showModal, setShowModal] = useState(false)

  // Function to handle publishing
  const handlePublish = () => {
    const newErrors = {
      ad_title: values.ad_title ? '' : 'Field is Required',
      location: values.location ? '' : 'Field is Required',
      sector: values.sector ? '' : 'Field is Required',
      published_date: values.published_date ? '' : 'Field is Required',
      end_date: values.end_date ? '' : 'Field is Required',
      description: values.descrption ? '' : 'Field is Required'
    }
    setErrors(newErrors)
    if (Object.values(newErrors).every((error) => !error)) {
      setShowModal(true)
    }
  }

  // Function to confirm publishing and submit
  const confirmPublish = () => {
    setShowModal(false)
    dispatch(websitead(values))
    setvalues({
      ad_title: '',
      location: '',
      sector: '',
      published_date: new Date(),
      end_date: '',
      descrption: ''
    })
  }

  // Function to cancel publishing
  const cancelPublish = () => {
    setShowModal(false)
    // Add code here to handle cancellation, if needed
  }

  const EndDate = values.end_date
    ? typeof values.end_date === 'string'
      ? new Date(values.end_date)
      : values.end_date
    : null

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper>
          <SectionTitle title="Create Ad" />
        </FlexWrapper>
        <FlexWrapper noPadding>
          <DropdownWrapper>
            <Input
              value={values.ad_title}
              placeholder="Ad Title "
              error={errors.ad_title}
              onChange={(value: string) => {
                setvalues({
                  ...values,
                  ad_title: value
                })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <EditableDropdown
              error={errors.location}
              dropdownList={placeDropdown}
              placeholder={'Location'}
              handleSelect={(value: DropdownListProps) => {
                setvalues({
                  ...values,
                  location: value.name
                })
              }}
            />
          </DropdownWrapper>

          <DropdownWrapper>
            <EditableDropdown
              error={errors.sector}
              dropdownList={OccupitationalList}
              placeholder={'Sector'}
              handleSelect={(value: DropdownListProps) => {
                setvalues({
                  ...values,
                  sector: value.name,
                  sector_id: value.id
                })
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noPadding>
          <DropdownWrapper>
            <DatePicker
              selected={values.published_date}
              onChange={() => {}}
              placeholderText="Published date"
              dateFormat="dd/MM/yyyy"
              disabled
              customInput={
                <Input
                  value={values.published_date}
                  error={errors.published_date}
                  placeholder="Select date"
                />
              }
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <DatePicker
              selected={EndDate}
              placeholderText="End Date (YYYY/MM/DD)"
              dateFormat="yyyy/MM/dd"
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              onChange={(value) => {
                setvalues({
                  ...values,
                  end_date: value
                })
              }}
              customInput={
                <Input
                  inputType="text"
                  error={errors.end_date}
                  value={EndDate}
                  placeholder="End Date (YYYY/MM/DD)"
                />
              }
            />
          </DropdownWrapper>
        </FlexWrapper>
        <DropdownWrapper width="90%">
          <Input
            height="auto"
            value={values?.descrption}
            error={errors.description}
            placeholder="Ad description "
            inputType="textarea"
            onChange={(value: string) => {
              setvalues({
                ...values,
                descrption: value
              })
            }}
          />
        </DropdownWrapper>

        <FlexWrapper justifyContent="center" noPadding>
          <PrimaryButton onClick={handlePublish}>Publish</PrimaryButton>
        </FlexWrapper>
      </CardWraper>

      {/* React Bootstrap Modal */}
      <Modal show={showModal} onHide={cancelPublish}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to publish this Ad?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelPublish}>
            No
          </Button>
          <Button variant="primary" onClick={confirmPublish}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {handleupdated && (
        <ToastMessage
          show={!!handleupdated}
          bg="success"
          onCloseHandler={() => {
            dispatch(updatehasAdCreated(false))
          }}
          message="Ad succesfully published"
        ></ToastMessage>
      )}
    </PageWrapper>
  )
}

export default CreateAd
