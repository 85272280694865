import { ReactElement, useState, useEffect } from 'react'
import { ExpressPassProps } from './typings'
import { TableHeaderData } from './const'
import {
  FlexWrapper,
  Icon,
  Input,
  PageWrapper,
  PrimaryButton
} from '../../../component'
import { Table, Button, Modal, ModalFooter } from 'react-bootstrap'
import TablePaginationEntries from '../../../component/TablePaginationEntires'
import TableFooter from '../../../component/TableFooter'
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'
import ROUTES from '../../../const/routes'
import {
  ExpressUpdateCount,
  deleteExpresspass
} from '../../../redux/admin/consultant/api'
import { useDispatch } from 'react-redux'

import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

export const ExpressPassTable = ({
  Tableitems
}: ExpressPassProps): ReactElement => {
  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  const [editingIndex, setEditingIndex] = useState(-1) // Track the index being edited
  const [editedCount, setEditedCount] = useState<any>(0) // Store the edited count value
  const [showModal, setShowModal] = useState<any>('')

  useEffect(() => {
    // Whenever Tableitems change, reset the editing state
    setEditingIndex(-1)
    setEditedCount(0)
  }, [Tableitems])

  const history = useHistory()
  const dispatch = useDispatch()

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const handleEditClick = (index: number) => {
    setEditingIndex(index)
    setEditedCount(sortedData[index].count)
  }

  const handleCountChange = async (index: number, value: number) => {
    if (value <= 0) {
      value = 0 // Prevent negative values
    }
    const updatedData = [...sortedData]
    const passIdToUpdate = updatedData[index].pass_id

    try {
      await dispatch(
        ExpressUpdateCount({
          count: value,
          open: true,
          id: passIdToUpdate
        })
      )

      setEditingIndex(-1)
      window.location.reload()
    } catch (error) {
      // Handle error if needed
    }
  }

  const handlePersonIconClick = (passId: any) => {
    history.push(`${ROUTES.GETEXPRESSPASSUSERLIST}?passId=${passId}`)
  }

  const totalPages = Math.ceil(Tableitems?.length / dataPerPage)
  const pageButtonsToShow = 3

  let total: number

  const sortedData = [...Tableitems]
  let sortedOrdered = sortedData?.sort(function (a, b) {
    let dateA: any = new Date(a?.date)
    let dateB: any = new Date(b?.date)

    return dateA - dateB
  })
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }
  const filteredData =
    sortOrder === 'asc' ? sortedOrdered : [...sortedOrdered].reverse()

  return (
    <PageWrapper>
      <FlexWrapper>
        <TablePaginationEntries
          showEntries
          handleSelectedDataPerPage={(value) => {
            setdataPerPage(value)
          }}
        />
        <Table striped borderless hover>
          <thead style={{ backgroundColor: '#003824', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th
                onClick={() => {
                  toggleSortOrder()
                  // You can perform sorting based on your data here.
                }}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={sortOrder === 'asc' ? faArrowUp : faArrowDown}
                  color="white"
                />
              </th>
              {TableHeaderData.map((data, index) => (
                <th key={`Header Unique id - ${index}`}>{data}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData
              ?.slice(
                (total = currentPage * dataPerPage - dataPerPage),
                currentPage * dataPerPage
              )
              .map((item, index) => (
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td>{}</td>
                  <td>{1 + total++}</td>
                  <td>{item.date}</td>
                  {editingIndex === index ? (
                    <td>
                      <div>
                        <Input
                          inputType="number"
                          value={editedCount}
                          onChange={(e: any) => {
                            const newValue = Number(e)
                            if (newValue >= 0) {
                              setEditedCount(newValue)
                            }
                          }}
                        />
                        {/* <button onClick={() => setEditedCount(editedCount + 1)}>
                          <FontAwesomeIcon
                            icon="caret-up"
                            style={{ cursor: 'pointer' }}
                          />
                        </button>
                        <button
                          onClick={() => {
                            if (editedCount > 0) {
                              setEditedCount(editedCount - 1)
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon="caret-down"
                            style={{ cursor: 'pointer' }}
                          />
                        </button> */}
                      </div>
                      <Button
                        onClick={() => handleCountChange(index, editedCount)}
                        style={{ marginTop: '5px' }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => setEditingIndex(-1)}
                        variant="secondary"
                        style={{ marginTop: '5px', marginLeft: '5px' }}
                      >
                        Close
                      </Button>
                    </td>
                  ) : (
                    <td>{item.count}</td>
                  )}
                  <td>{item.filled_count}</td>
                  <td>{item.available_count}</td>

                  <td>
                    <Icon
                      onClick={() => handleEditClick(index)}
                      icon={['far', 'edit']}
                      style={{ cursor: 'pointer' }}
                    />
                    <Icon
                      onClick={() => handlePersonIconClick(item.pass_id)}
                      icon={faUser}
                      style={{ cursor: 'pointer' }}
                    />
                    <Icon
                      icon={['far', 'trash-alt']}
                      onClick={() => {
                        setShowModal(item?.pass_id)
                      }}
                    />
                    {/* <FontAwesomeIcon
                      icon={faUser}
                      onClick={() => handlePersonIconClick(item.pass_id)}
                      style={{ cursor: "pointer" }} // Add a pointer cursor to indicate clickability */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <TableFooter
          currentPage={currentPage}
          showEntries
          startValue={startindex}
          showPageDisplay={false}
          pageValue={endindex}
          TotalIems={Tableitems.length}
          totalPages={totalPages}
          showButtonDisplay
          pageButtonsToShow={pageButtonsToShow}
          handlePrevious={() => setCurrentPages(Math.max(currentPage - 1, 1))}
          handleNext={() =>
            setCurrentPages(Math.min(currentPage + 1, totalPages))
          }
          handlePageClick={handlePageClick}
        />
      </FlexWrapper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setShowModal('')
        }}
      >
        <Modal.Header>Are you sure you want to delete?</Modal.Header>
        <ModalFooter>
          <FlexWrapper noMargin justifyContent="end">
            <PrimaryButton
              left
              onClick={() => {
                dispatch(
                  deleteExpresspass({
                    id: showModal
                  })
                )
                setShowModal('')
              }}
            >
              Yes
            </PrimaryButton>{' '}
            <PrimaryButton
              left
              onClick={() => {
                setShowModal('')
              }}
            >
              No
            </PrimaryButton>
          </FlexWrapper>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  )
}
