import React, { ReactElement, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle
} from '../../../component'
import { AppDispatch, RootState } from '../../../redux/store'
import { tableHeader } from './const'
import 'react-datepicker/dist/react-datepicker.css'
import { approveHours, getHoursList } from '../../../redux/admin/consultant/api'
import { CardWraper } from '../Consultants/subcomponents'
import Buttons from '../../../component/Modal/subcomponent'

const HoursList = (): ReactElement => {
  const {
    consultant: { getHoursDetials, isLoading }
  } = useSelector(
    (state: RootState) => ({
      consultant: state.consultant
    }),
    shallowEqual
  )

  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    dispatch(getHoursList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deterMineStatus = (status: any) => {
    let sta
    if (status === 0) {
      sta = 'Pending'
    } else if (status === 1) {
      sta = 'Approved'
    } else if (status === 2) {
      sta = 'Rejected'
    }
    return sta
  }
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Unpaid vacancies" hasBorder />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table striped borderless hover responsive>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getHoursDetials.map((item, index) => (
                  <tr key={index} style={{ textAlign: 'center' }}>
                    <td>{index + 1}</td>
                    <td>{item.total_hours}</td>
                    <td>{item.remaining_hours}</td>
                    <td>{item.used_hours}</td>
                    <td>{item.requesting_hours}</td>
                    <td>
                      <span style={{ fontWeight: 600 }}>
                        {deterMineStatus(item.is_processed)}
                      </span>
                    </td>
                    <td>
                      <div>
                        <Buttons
                          style={{ background: 'green' }}
                          disabled={item.is_processed !== 0}
                          onClick={() => {
                            dispatch(
                              approveHours({
                                ...item,
                                total_hours: item?.total_hours,
                                used_hours: item?.used_hours,
                                remaining_hours: item?.remaining_hours,
                                requesting_hours: item?.requesting_hours,
                                ins_id: item?.ins_id,
                                is_processed: 1,
                                id: item?.id
                              })
                            ).then((res) => {
                              if (res?.meta?.requestStatus === 'fulfilled') {
                                alert('Approved')
                              }
                            })
                          }}
                        >
                          Approve
                        </Buttons>
                        <Buttons
                          style={{ background: 'red' }}
                          disabled={item.is_processed !== 0}
                          onClick={() => {
                            dispatch(
                              approveHours({
                                ...item,
                                total_hours: item?.total_hours,
                                used_hours: item?.used_hours,
                                remaining_hours: item?.remaining_hours,
                                requesting_hours: item?.requesting_hours,
                                ins_id: item?.ins_id,
                                is_processed: 2,
                                id: item?.id
                              })
                            ).then((res) => {
                              if (res?.meta?.requestStatus === 'fulfilled') {
                                alert('Rejected')
                              }
                            })
                          }}
                        >
                          Deny
                        </Buttons>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </CardWraper>
    </PageWrapper>
  )
}

export default HoursList
