import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../../const/theme";
import { H2, Small } from "../../typography";
import BackButton from "../BackButton";
import Title from "./subcomponents";

interface SectionTitleProps {
  title: string;
  helpText?: string;
  hasBorder?: boolean;
  hasBackButton?: boolean;
  smallFont?: boolean;
  onBackClick?: () => void; // Add the onBackClick prop to handle the back button click
}

const SectionTitle = ({
  helpText,
  title,
  hasBorder,
  hasBackButton,
  smallFont,
  onBackClick, // Add onBackClick prop to the destructured props
}: SectionTitleProps): ReactElement => {
  const history = useHistory();

  return (
    <Title hasBorder={hasBorder}>
      {hasBackButton && (
        <BackButton
          handleBack={() => {
            if (onBackClick) {
              onBackClick(); // Call the onBackClick prop if it's provided
            } else {
              history.goBack();
            }
          }}
        />
      )}
      {smallFont && <h5>{title}</h5>}
      {!smallFont && <H2 color={colors.black}>{title}</H2>}
      {helpText && (
        <div>
          <Small isHelpText={true}>{helpText}</Small>
        </div>
      )}
    </Title>
  );
};

export default SectionTitle;
