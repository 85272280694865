import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddInstitutes,
  GetAddress,
  GetCompletedVacancyApi,
  GetInstitute,
  GetMyDraft,
  GetOrganization,
} from "./api";
import {
  InitialState,
  GetOrganizationProps,
  GetAddressRES,
  GetInstituteProps,
  getDraftandPublishVacancy,
  completedVacancyprops,
} from "./types";

const initialState: InitialState = {
  isLoading: false,
  handleInstituteCreated: false,
  AddInstitute: {
    institute_name: "",
    institute_id: "",
    address: "",
    locality: "",
    postcode: "",
    institute_type: {
      id: "",
      name: "",
    },
    requirement_info: [],
    institute_domin: "",
    email_id: "",
    password: "",
    contact_number: "",
  },
  GetOrganization: [],
  getAddress: [],
  getInstitute: [],
  updateViewInstitute: null,
  getMyDraft: [],
  getCompletedVacancy: {
    Customer: [],
    Consultant: [],
  },
};

export const InstituteSlice = createSlice({
  name: "Institute",
  initialState,
  reducers: {
    updatehasInstituteCreated: (state, action: PayloadAction<boolean>) => {
      state.handleInstituteCreated = action.payload;
    },
    updateSelectedInstitute: (
      state,
      action: PayloadAction<GetInstituteProps | null>
    ) => {
      state.updateViewInstitute = action.payload;
    },
  },
  extraReducers: {
    [AddInstitutes.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [AddInstitutes.fulfilled.toString()]: (state) => {
      state.isLoading = false;

      state.handleInstituteCreated = true;
      window.scrollTo({ top: 0 });
    },
    [AddInstitutes.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetOrganization.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetOrganization.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetOrganizationProps>>
    ) => {
      state.isLoading = false;
      state.GetOrganization = action.payload;
    },
    [GetOrganization.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetAddress.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetAddress.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAddressRES>>
    ) => {
      state.isLoading = false;
      state.getAddress = action.payload;
    },
    [GetAddress.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetInstitute.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetInstitute.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetInstituteProps>>
    ) => {
      state.isLoading = false;
      state.getInstitute = action.payload;
    },
    [GetInstitute.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetMyDraft.pending.toString()]: (
      state,
    ) => {
      state.isLoading = true;
    },
    [GetMyDraft.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getDraftandPublishVacancy>>
    ) => {
      state.isLoading = false;
      state.getMyDraft = action.payload;
    },
    [GetMyDraft.rejected.toString()]: (
      state,
    ) => {
      state.isLoading = false;
    },
    [GetCompletedVacancyApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetCompletedVacancyApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<completedVacancyprops>
    ) => {
      state.isLoading = false;
      state.getCompletedVacancy = action.payload;
    },
    [GetCompletedVacancyApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default InstituteSlice.reducer;
