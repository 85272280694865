import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { GetAllCustomerresponse } from '../../../../../redux/admin/customer/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { Row, Col } from 'react-bootstrap'
import ROUTES from '../../../../../const/routes'

const ViewCustomerDetail = (): ReactElement => {
  const { updateViewCustomer } = useSelector((state: RootState) => ({
    updateViewCustomer: state.customer
      .updateViewCustomer as GetAllCustomerresponse
  }))

  const history = useHistory()

  const params = new URLSearchParams(history.location.search)
  const savedFilterType = params.get('filterType')

  const onbackclick = () => {
    history.push(`${ROUTES.VIEWCUSTOMER}?filterType=${savedFilterType}`)
  }

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle
          title="Detail view"
          hasBorder
          hasBackButton
          onBackClick={onbackclick}
        />
        <FlexWrapper>
          <Row>
            <Col xs={12} md={6}>
              <Column
                keyName="Customer"
                value={`${updateViewCustomer.first_name} ${updateViewCustomer.last_name}`}
              />
              <Column
                keyName="Work email"
                value={updateViewCustomer?.email_id}
              />
              <Column
                keyName="Work contact number"
                value={updateViewCustomer?.telephone_number}
              />
              <Column
                keyName="Work address"
                value={updateViewCustomer?.address}
              />
              <Column
                keyName="Postal code"
                value={updateViewCustomer?.postal_code}
              />
              <Column
                keyName="Area name"
                value={updateViewCustomer?.area_name}
              />
              <Column
                keyName="Company name "
                value={updateViewCustomer.company_name}
              />
              <Column
                keyName="Org number"
                value={updateViewCustomer.organization_no}
              />
            </Col>
            <Col xs={12} md={6}>
              <Column
                keyName="Organisation type"
                value={updateViewCustomer.organization_type}
              />
              <Column
                keyName="Invoice address"
                value={updateViewCustomer.invoice_address}
              />
              <Column
                keyName="Invoice postal code"
                value={updateViewCustomer.invoice_postal_code}
              />
              <Column
                keyName="Invoice area name "
                value={updateViewCustomer.invoice_area_name}
              />

              <Column
                keyName="Invoice reference "
                value={updateViewCustomer.invoice_reference}
              />

              <Column
                keyName="Invoice email"
                value={updateViewCustomer.invoice_email_id}
              />

              <Column
                keyName="Routine instructions for the substitutes"
                value={
                  updateViewCustomer.Routine_instructions_for_the_substitutedh_customer
                }
              />
            </Col>
          </Row>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewCustomerDetail
