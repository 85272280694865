import moment from 'moment' // Import Moment type
import React, { ReactElement, useEffect, useState, useMemo } from 'react'
import { Table, Modal } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  Input,
  PrimaryButton
} from '../../../../component'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Consultants/subcomponents'
import { tableHeader } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory, useLocation } from 'react-router'
import ROUTES from '../../../../const/routes'
import { getAllPublishVacancy } from '../../../../redux/admin/vacancy/api'
import { updateViewVacancy } from '../../../../redux/admin/vacancy/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
  faCheckCircle,
  faCircle,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons'
import {
  deleteVacancyStatus,
  getVacancyDataList
} from '../../../../redux/admin/consultant/api'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const UnFilled = (): ReactElement => {
  const { vacancyLists, isLoading } = useSelector(
    (state: RootState) => ({
      vacancyLists: state.vacancy.vacancyLists,
      isLoading: state.vacancy.isLoading
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sorteddata = [...vacancyLists]
  const [selectedDate, setSelectedDate] = useState<moment.Moment | any>(null)

  useEffect(() => {
    // Parse the selected date from the URL parameters
    const searchParams = new URLSearchParams(location.search)
    const selectedDateFromURL = searchParams.get('date')

    // Set the selected date state
    setSelectedDate(
      selectedDateFromURL ? moment(selectedDateFromURL) : moment()
    )
  }, [location.search])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [showModal, setshowModal] = useState('')

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  const sortedFilteredData = useMemo(() => {
    let filteredList = sorteddata
    if (selectedDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.v_date).isSame(selectedDate, 'day')
      )
    }

    if (searchQuery.trim() !== '') {
      filteredList = filteredList.filter((item) =>
        item.customer_company_name
          .toLowerCase()
          .startsWith(searchQuery.toLowerCase().trim())
      )
    }

    // No sorting logic needed here

    return filteredList
  }, [selectedDate, sorteddata, searchQuery])

  const filteredData =
    sortOrder === 'asc' ? sortedFilteredData : [...sortedFilteredData].reverse()

  const totalPages = Math.ceil(filteredData.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date ? moment(date) : null)
    dispatch(
      getAllPublishVacancy({
        type: 0,
        date: date ? moment(date).format('YYYY-MM-DD') : null,
        get_all: false
      })
    )
  }
  const mapVacancyStatusToStringAndColor = (status: string | number) => {
    switch (status) {
      case 0:
        return { label: 'Unfilled', color: 'red', icon: faCircleXmark }
      case 1:
        return { label: 'Filled', color: 'green', icon: faCircle }
      case 2:
        return { label: 'Completed', color: 'green', icon: faCheckCircle }
      default:
        return { label: 'Unknown', color: 'gray', icon: faCircleXmark }
    }
  }

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')

  useEffect(() => {
    // Check if the selectedDate is not set (meaning it's the initial visit)
    if (!selectedDate) {
      dispatch(
        getAllPublishVacancy({
          type: 0,
          date: moment(new Date()).format('YYYY-MM-DD'),
          get_all: false
        })
      )
    } else {
      dispatch(
        getAllPublishVacancy({
          type: 0,
          date: selectedDate.format('YYYY-MM-DD'), // Use the selected date here
          get_all: false
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredSearchData = filteredData?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      items.customer_company_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Unfilled Vacancies" hasBorder />
        </FlexWrapper>
        <FlexWrapper>
          <DatePicker
            selected={selectedDate ? selectedDate.toDate() : null}
            onChange={handleDateChange}
            placeholderText="Select a date"
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            yearDropdownItemNumber={50}
            scrollableYearDropdown
            showYearDropdown
            customInput={
              <Input
                value={selectedDate}
                placeholder="Select date"
                onClick={(value: string) =>
                  dispatch(
                    getAllPublishVacancy({
                      type: 0,
                      date: moment(value).format('YYYY-MM-DD'),
                      get_all: false
                    })
                  )
                }
              />
            }
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                value={searchQuery}
                placeholder="Search by company name"
                onChange={handleSearch}
              />
            </FlexWrapper>
            <Table striped borderless hover responsive>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th
                    onClick={() => {
                      toggleSortOrder()
                      // You can perform sorting based on your data here.
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon
                      icon={sortOrder === 'asc' ? faArrowUp : faArrowDown}
                      color="white"
                    />
                  </th>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredSearchData
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{}</td>
                      <td>{1 + total++}</td>
                      <td>{`${item.customer_company_name} `}</td>
                      <td>{item.position}</td>
                      <td>
                        {moment(item?.created_at.slice(0, 19)).format(
                          'YYYY-MM-DD- HH:mm'
                        )}
                      </td>{' '}
                      <td>{`${formatTime(item.from_time)}-${formatTime(
                        item.to_time
                      )}`}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={
                            mapVacancyStatusToStringAndColor(
                              item.vacancy_status
                            ).icon
                          }
                          style={{
                            color: mapVacancyStatusToStringAndColor(
                              item.vacancy_status
                            ).color
                          }}
                        />
                        <span
                          style={{
                            marginLeft: '5px',
                            color: mapVacancyStatusToStringAndColor(
                              item.vacancy_status
                            ).color
                          }}
                        >
                          {
                            mapVacancyStatusToStringAndColor(
                              item.vacancy_status
                            ).label
                          }
                        </span>
                        {item?.cancelled_by_consultant === 1 && (
                          <FontAwesomeIcon
                            title="This indicates that the previous consultant cancelled this vacancy 90 minutes or less prior to the start time. "
                            icon={faInfoCircle}
                            style={{ marginLeft: '5px', color: 'blue' }}
                          />
                        )}
                      </td>
                      <td>
                        <Icon
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.VIEWUNFILLEDVACANCY,
                              search: `?date=${selectedDate.format(
                                'YYYY-MM-DD'
                              )}`
                            })
                            dispatch(
                              getVacancyDataList({
                                vacancy_id: item?.id
                              })
                            )
                            dispatch(updateViewVacancy(item))
                          }}
                          icon={['fas', 'eye']}
                        />
                        <Icon
                          title="Delete vacancy"
                          onClick={() => {
                            setshowModal(item?.id)
                          }}
                          icon={['far', 'trash-alt']}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={filteredData.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
        <Modal
          show={!!showModal}
          onHide={() => {
            setshowModal('')
          }}
          backdrop="static"
        >
          <Modal.Header>
            Are you sure you want to delete this vacancy?
          </Modal.Header>
          <Modal.Footer>
            <FlexWrapper noMargin justifyContent="end">
              <PrimaryButton
                left
                onClick={async () => {
                  await dispatch(
                    deleteVacancyStatus({
                      id: showModal,
                      date: selectedDate
                    })
                  )
                  setshowModal('')
                }}
              >
                Yes
              </PrimaryButton>{' '}
              <PrimaryButton
                left
                onClick={() => {
                  setshowModal('')
                }}
              >
                No
              </PrimaryButton>
            </FlexWrapper>
          </Modal.Footer>
        </Modal>
      </CardWraper>
    </PageWrapper>
  )
}

export default UnFilled
