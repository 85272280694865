import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { AllVacancyResposne } from '../../../../../redux/admin/vacancy/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import ROUTES from '../../../../../const/routes'
import { getAllPublishVacancy } from '../../../../../redux/admin/vacancy/api'
const ViewCompletedVacancy = (): ReactElement => {
  const { updateViewpublishvacancy } = useSelector((state: RootState) => ({
    updateViewpublishvacancy: state.vacancy
      .updateViewpublishvacancy as AllVacancyResposne
  }))

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  // Parse the selected date from the URL parameters
  const searchParams = new URLSearchParams(location.search)
  const selectedDateFromURL = searchParams.get('date')

  const handleBackClick = () => {
    if (selectedDateFromURL) {
      dispatch(
        getAllPublishVacancy({
          type: 2,
          date: moment(selectedDateFromURL).format('YYYY-MM-DD'), // Use the selected date here
          get_all: false
        })
      )
      history.push({
        pathname: ROUTES.COMPLETED_VACC,
        search: `?date=${selectedDateFromURL}`
      })
    }
  }

  const mapVacancyStatusToColor = (status: string | number): string => {
    switch (status) {
      case 0:
        return 'red' // Custom color for cusPending
      case 1:
        return 'green' // Custom color for Paid
      case 2:
        return 'orange' // Custom color for Pending
      default:
        return 'gray' // Default color for Unknown status
    }
  }
  const renderApprovedTime = () => {
    // Check if both approved_etime and approved_stime are null
    if (
      updateViewpublishvacancy.approved_stime === null &&
      updateViewpublishvacancy.approved_etime === null
    ) {
      // Display report time range when both approved times are null
      return `${formatTime(
        updateViewpublishvacancy.report_start_time
      )} - ${formatTime(updateViewpublishvacancy.report_end_time)}`
    } else {
      // Otherwise, display the approved time range
      return `${formatTime(
        updateViewpublishvacancy.approved_stime
      )} - ${formatTime(updateViewpublishvacancy.approved_etime)}`
    }
  }
  const renderApprovedHrs = () => {
    // Check if both approved_etime and approved_stime are null
    if (updateViewpublishvacancy.total_approved_hrs === null) {
      // Display report time range when both approved times are null
      return `${updateViewpublishvacancy.report_total_whours}`
    } else {
      // Otherwise, display the approved time range
      return `${updateViewpublishvacancy.total_approved_hrs} `
    }
  }

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')
  // const dateformatTime = (time: Date) => {
  //   return moment(time).format('YYYY-MM-DD-HH:mm')
  // }

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle
          title="Detail view"
          hasBorder
          hasBackButton
          onBackClick={handleBackClick}
        />
        <FlexWrapper justifyContent="center">
          <Row>
            <Col xs={12} md={6}>
              <Column
                keyName="Customer name"
                value={`${updateViewpublishvacancy.customer_firstname}${updateViewpublishvacancy.customer_lastname}`}
              />
              <Column
                keyName="Position"
                value={updateViewpublishvacancy?.position}
              />
              <Column
                keyName="Published Date-Time"
                value={moment(
                  updateViewpublishvacancy?.created_at.slice(0, 19)
                ).format('YYYY-MM-DD- HH:mm')}
              />
              <Column
                keyName="Company name"
                value={updateViewpublishvacancy?.customer_company_name}
              />
              <Column
                keyName="Organisation type"
                value={updateViewpublishvacancy?.customer_organization_type}
              />
              <Column
                keyName="Customer Email"
                value={updateViewpublishvacancy?.customer_emailID}
              />
              {updateViewpublishvacancy.vacancy_status === 0 ? (
                <Column keyName="Consultant" value="NA" />
              ) : (
                <Column
                  keyName="Consultant"
                  value={`${updateViewpublishvacancy.consultant_first_name} ${updateViewpublishvacancy.consultant_last_name}`}
                />
              )}
              <Column
                keyName="Vacancy Date"
                value={updateViewpublishvacancy.v_date}
              />
              <Column
                keyName="Job Description"
                value={updateViewpublishvacancy.discription}
              />
            </Col>
            <Col xs={12} md={6}>
              <Column
                keyName="Original time"
                value={`${formatTime(
                  updateViewpublishvacancy.from_time
                )} - ${formatTime(updateViewpublishvacancy.to_time)}`}
              />
              <Column
                keyName="Break time"
                value={updateViewpublishvacancy.break_time}
              />
              <Column
                keyName="Reported time"
                value={`${formatTime(
                  updateViewpublishvacancy.report_start_time
                )} - ${formatTime(updateViewpublishvacancy.report_end_time)}`}
              />
              <Column
                keyName="Reported Hours"
                value={updateViewpublishvacancy.report_total_whours}
              />
              <Column
                keyName="Reason from Consultant  "
                value={updateViewpublishvacancy.report_reason}
              />
              {(updateViewpublishvacancy.is_paid === 0 ||
                updateViewpublishvacancy.is_paid === 1,
              updateViewpublishvacancy.vacancy_is_approved !== 0) && (
                <>
                  <Column
                    keyName="Approved time"
                    value={renderApprovedTime()}
                  />

                  {/* Render the "Total Approved Hours" column */}
                  <Column
                    keyName="Total Approved Hours"
                    value={renderApprovedHrs()}
                  />
                  <Column
                    keyName="Reason by customer"
                    value={updateViewpublishvacancy.feedback}
                  />
                </>
              )}
              <Column
                keyName="Location"
                value={`${updateViewpublishvacancy.customer_address},${updateViewpublishvacancy.customer_area_name},${updateViewpublishvacancy.customer_postal_code}`}
              />{' '}
              <Column
                keyName="Customer contact"
                value={updateViewpublishvacancy.customer_telephone_number}
              />
              <Column
                keyName="Recipient type"
                value={updateViewpublishvacancy.publish_to}
              />
              <Column keyName="By accepting this job, I confirm:" value={''} />
              <Column
                keyName="I study minimum 50%"
                isCheckbox
                input={
                  updateViewpublishvacancy?.I_am_a_full_time_student === 1
                    ? true
                    : false
                }
              />
              <Column
                keyName="I have another primary occupation - minimum 50%"
                isCheckbox
                input={
                  updateViewpublishvacancy?.self_employed_F_Skatt_or_freelancer_third_party ===
                  1
                    ? true
                    : false
                }
              />
              <Column
                keyName="Initial accepted time"
                value={
                  updateViewpublishvacancy?.vacancy_Accepted_Primary_time ===
                  null
                    ? 'NA'
                    : moment(
                        String(
                          updateViewpublishvacancy?.vacancy_Accepted_Primary_time
                        )?.slice(0, 19)
                      ).format('YYYY-MM-DD- HH:mm')
                }
              />
              <Column
                keyName="Vacancy Status"
                value={
                  <>
                    {updateViewpublishvacancy.vacancy_is_approved === 0 ? (
                      <span
                        className="custom-status-pending"
                        style={{
                          color: mapVacancyStatusToColor(
                            updateViewpublishvacancy.is_paid
                          )
                        }}
                      >
                        Awaiting approval
                      </span>
                    ) : updateViewpublishvacancy.is_paid === 0 ? (
                      <span
                        className="custom-status-pending"
                        style={{
                          color: mapVacancyStatusToColor(
                            updateViewpublishvacancy.is_paid
                          )
                        }}
                      >
                        Pending
                      </span>
                    ) : (
                      <span
                        className="custom-status-paid"
                        style={{
                          color: mapVacancyStatusToColor(
                            updateViewpublishvacancy.is_paid
                          )
                        }}
                      >
                        Paid
                      </span>
                    )}
                  </>
                }
              />
            </Col>
          </Row>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewCompletedVacancy
