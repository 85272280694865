import styled from "styled-components";

export const FormContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 8px;
  background: #003824;
  color: white;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const RadioGroup = styled.div`
  margin-bottom: 10px;
`;

export const RadioInput = styled.input.attrs({ type: "radio" })`
  margin-right: 5px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;

export const ValidationError = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;
