const apiEndpoints = {
  add_institute: '/Add_Institute',
  get_institute: '/Get_Institutes',
  get_organization: '/Get_Organization ',
  createCustomer: '/Create_Customer',
  createSubstitute: '/Create_Substitute',
  addRole: '/Add_title',
  getRole: '/Get_Title',
  getCompletedVacancy: '/Get_MyCompletedVacancy',
  addTax: '/Add_Tax',
  getTax: '/Get_Tax',
  getAddress: '/Get_Address',
  getDraft: '/Get_My_Ins_Vacancy',
  getApproveConsultant: '/Get_ApproveList',
  getAllConsultant: '/Get_All_Consultant',
  getAllDeniedConsultants: '/Get_DenyList',
  getVacancyUserDetails: '/Get_Vacancy_Cancel_UserDetails',
  approveConsultant: '/Approve_Consultant',
  denyConsultant: '/Deny_Consultant ',
  getExpressPass: '/Get_Express_Pass',
  addExpressPass: '/Add_Express_Pass',
  getAllVacancy: '/Get_vacancy_All_type',
  getAllConsVacancy: '/Get_vacancy_All_by_paid_status',
  VacancyStatus: '/Make_Paid',
  getContactUsData: '/Get_contact_us_data',
  getDOHRFeedBAck: '/get_feedback_dohr',
  ManualpushAll: '/Send_Manual_Notification',
  GetalluserList: '/Get_ExpressPass_UserDetails',
  Expressupdatecount: '/Edit_ExpressPass',
  Getallcustomer: '/Get_All_Customer',
  customerstatus: '/Make_Customer_Active',
  consultantStatusChange: '/Make_Consultant_InActive',
  deleteConsultant: '/delete_consultant',
  deleteinactiveconsultant: '/delete_inactive_consultant',
  deleteCustomer: '/delete_customer',
  deleteVacancy: '/delete_vacancy',
  deleteDomain: '/delete_institute',
  deletecontactus: '/delete_contact_us',
  deletedohrfeedback: '/delete_feedback_for_dohr',
  getopenapplications: '/Get_Open_Applications',
  getfilterapplication: '/Get_Open_Applications',
  Approvedenyopenapplication: '/Open_Application_Approve',
  websitecreatead: '/Add_Advertisement',
  getwebsitead: '/Get_Advertisement',
  editwebsitead: '/Edit_Advertisment',
  deletead: '/delete_ad',
  publishedadapplication: '/Get_Ad_Applications',
  handleststatusapplication: '/Ad_Application_Approve',
  deleteadapplication: '/Delete_ad_application_form ',
  deleteexpresspass: '/delete_express_pass',
  deleteopenapplication: '/delete_open_application',
  getnewsemail: '/get_all_sub_email',
  deltenewsemail: '/delete_sub_email'
}

export default apiEndpoints
