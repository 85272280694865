export interface GetvacanciesApplicationresponse {
  id: any
  first_name?: string
  last_name?: string
  address?: string
  email_id: string
  postalcode?: string
  area_name?: string
  phone_number?: string
  preferred_location?: string
  description?: string
  city?: string
  date_of_birth: Date
  right_to_work_in_Sweden_attachment?: string
  Iam_EU_citizen?: number
  Iam_Non_EU_citizen?: number
  linkedIn?: string
  occup_sector?: string
  studyingteacher?: number
  swedish_bank_account?: number
  working_evening_or_weekends?: number
  qualifiedteacherdate: Date
  work_exp_in_school?: number
  work_exp_out_school?: number
  specificField?: string
  language_skills?: string
  subjectlist?: string
  specify_date: Date
  agegroup?: string
  subjectlistno: string
  agegroupno: string
  subjectlistyes: string
  agegroupyes: string
  info_work_exp_in_school?: string
  info_work_exp_out_school?: string
  employment_history?: string
  relevant_education?: string
  qualifiedTeacher?: number
  swedishLara?: number
  instutionstudyteacher?: string
  preferWorkIn?: string
  occupation?: string
  position?: string
  work_start?: number
  fileswedishlara?: string
  howDoYouKnowUs?: string
  Driving_license?: number
  right_to_work_in_Sweden?: number
  marketing_purpose?: number
  accurate_information?: number
  terms_and_conditions?: number
  is_denied?: number
  is_approved?: number
  created_at: Date
  updated_at?: Date
  is_active?: number
}

export interface websitecreatead {
  id?: any
  ad_title: string
  location?: string | null
  sector_id?: string | null
  sector?: string | null
  descrption?: string
  published_date: Date | null
  end_date: Date | null | string
  is_active?: number
  created_at?: Date | null | string
  updated_at?: Date | null | string
}

export interface InitialState {
  isLoading: boolean
  Websitecreateadapp: websitecreatead
  Websitecreateadapplist: Array<websitecreatead>
  updatecreatedapplist: websitecreatead | null
  getapplicationlist: Array<GetvacanciesApplicationresponse>
  setApplicationData: GetvacanciesApplicationresponse | null
  handleupdatead: boolean
}

export const initialState: InitialState = {
  isLoading: false,
  Websitecreateadapp: {
    id: '',
    ad_title: '',
    location: '',
    sector_id: '',
    sector: '',
    descrption: '',
    published_date: new Date(),
    end_date: null
  },
  Websitecreateadapplist: [],
  updatecreatedapplist: null,
  getapplicationlist: [],
  setApplicationData: null,
  handleupdatead: false
}

export interface applicationstatus {
  is_denied: boolean
  is_approved: boolean
  id: any
}
