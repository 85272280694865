import { createAsyncThunk } from '@reduxjs/toolkit'
import apiEndpoints from '../../../const/apiEndPoints'
import history from '../../../const/history'
import ROUTES from '../../../const/routes'
import api from '../../../services'
import {
  PostexpressPass,
  getallexpresspasslist,
  expresspassupdate
} from './types'
import { getAllcustomerdata } from '../customer/api'
import { getAllPublishVacancy } from '../vacancy/api'

import moment from 'moment'

export const getConsultant = createAsyncThunk(
  'consultant/getConsultant',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getApproveConsultant)
    return response.data.message
  }
)

export const getAllConsultantList = createAsyncThunk(
  'consultant/getConsultantList',
  async (requestPayload: { type: any; is_verfied: any }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getAllConsultant}`,
      requestPayload
    )
    return response.data.message
  }
)

export const getAllDeniedConsultantList = createAsyncThunk(
  'consultant/getConsultantList',
  async (): Promise<any> => {
    const response = await api.get(`${apiEndpoints.getAllDeniedConsultants}`)
    return response.data.message
  }
)

export const handleApproveConsultant = createAsyncThunk(
  'consultant/approveConsultantList',
  async (requestPayload: any, { dispatch }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.approveConsultant}`,
      requestPayload
    )
    if (response?.data) {
      dispatch(getConsultant())
    }
    return response.data
  }
)

export const handleDenyConsultant = createAsyncThunk(
  'consultant/approveConsultantList',
  async (requestPayload: any, { dispatch }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.denyConsultant}`,
      requestPayload
    )
    if (response?.data) {
      dispatch(getConsultant())
    }
    return response.data
  }
)

export const getApproveConsultant = createAsyncThunk(
  'consultant/getApproveConsultantList',
  async (): Promise<any> => {
    const response = await api.get(`${apiEndpoints.approveConsultant}`)
    if (response?.data) {
      history.push(ROUTES.VIEW_CONSULTANTS)
    }
    return response.data
  }
)

export const getExpressPass = createAsyncThunk(
  'consultant/getExpressPass',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getExpressPass)
    return response.data.message
  }
)
export const deleteExpresspass = createAsyncThunk(
  'consultant/deleteExpressPass',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.deleteexpresspass,
      requestPayload
    )
    if (response.data) {
      dispatch(getExpressPass())
    }
    return response.data.message
  }
)

export const addExpressPass = createAsyncThunk(
  'consultant/addExpressPass',
  async (requestPayload: PostexpressPass, { dispatch }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.addExpressPass}`,
      requestPayload
    )
    if (response.status === 200) {
      dispatch(getExpressPass())
    }
    return response.data.message
  }
)

export const getAllUserList = createAsyncThunk(
  'consultant/getAllUserList',
  async (requestPayload: getallexpresspasslist): Promise<any> => {
    const response = await api.post(apiEndpoints.GetalluserList, requestPayload)
    return response.data.message
  }
)

export const ExpressUpdateCount = createAsyncThunk(
  'consultant/ExpressUpdateCount',
  async (requestPayload: expresspassupdate): Promise<any> => {
    const response = await api.post(
      apiEndpoints.Expressupdatecount,
      requestPayload
    )
    return response.data.message
  }
)

export const updataConsutantStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (
    requestPayload: { data: boolean; id: any; type: any; email_id: any },
    { dispatch }
  ): Promise<any> => {
    const response = await api.post(
      apiEndpoints.consultantStatusChange,
      requestPayload
    )
    if (response.data) {
      dispatch(
        getAllConsultantList({
          type: requestPayload?.type,
          is_verfied: true
        })
      )
    }
    return response.data.message
  }
)

export const deleteConsutantStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.deleteinactiveconsultant,
      requestPayload
    )
    if (response.data) {
      dispatch(
        getAllConsultantList({
          type: 3,
          is_verfied: true
        })
      )
    }
    return response.data.message
  }
)

export const deleteCustomerStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(apiEndpoints.deleteCustomer, requestPayload)
    if (response.data) {
      dispatch(
        getAllcustomerdata({
          type: 1,
          is_verfied: true
        })
      )
    }
    return response.data.message
  }
)

export const deleteVacancyStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (
    requestPayload: { id: any; date: any },
    { dispatch }
  ): Promise<any> => {
    const response = await api.post(apiEndpoints.deleteVacancy, requestPayload)
    if (response.data) {
      dispatch(
        getAllPublishVacancy({
          type: 0,
          date: moment(requestPayload?.date).format('YYYY-MM-DD'),
          get_all: false
        })
      )
    }
    return response.data.message
  }
)

export const getVacancyDataList = createAsyncThunk(
  'vac/getVacancy',
  async (rp: { vacancy_id: any }): Promise<any> => {
    const response = await api.post(`${apiEndpoints.getVacancyUserDetails}`, rp)
    return response.data.message
  }
)

export const deleteReviewedStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.deleteConsultant,
      requestPayload
    )
    if (response.data) {
      dispatch(
        getAllConsultantList({
          type: 1,
          is_verfied: true
        })
      )
    }
    return response.data.message
  }
)
export const deletedeniedStatus = createAsyncThunk(
  'consultant/statusUpdate',
  async (requestPayload: { id: any }, { dispatch }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.deleteConsultant,
      requestPayload
    )
    if (response.data) {
      dispatch(getAllDeniedConsultantList())
    }
    return response.data.message
  }
)

export const getHoursList = createAsyncThunk(
  'topup/getTopUp',
  async (): Promise<any> => {
    const response = await api.get('get_top_request')
    return response.data.message
  }
)

export const approveHours = createAsyncThunk(
  'topup/approveTopUp',
  async (requestPayload: any, { dispatch }): Promise<any> => {
    const response = await api.post('/top_up_approve', requestPayload)
    if (response.data) {
      dispatch(getHoursList())
    }
    return response.data.message
  }
)
