import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState,AllVacancyResposne } from "./types";
import { getAllConsVacancy, getAllPublishVacancy, VacancyStatus } from "./api";

export const initialState: InitialState = {
    isLoading: false,
    vacancyLists: [],
    updateViewpublishvacancy:null
};

export const vacancySlice = createSlice({
    name: "vacancy",
    initialState,
    reducers: {
        updateViewVacancy: (
            state,
            action: PayloadAction<AllVacancyResposne | null>
          ) => {
            state.updateViewpublishvacancy = action.payload;
          },

    },
    extraReducers: {
        [getAllPublishVacancy.pending.toString()]: (state) => {
            state.isLoading = true;
        },
        [getAllPublishVacancy.fulfilled.toString()]: (
            state,
            action: PayloadAction<Array<AllVacancyResposne>>
        ) => {
            state.isLoading = false;
            state.vacancyLists = action.payload
        },
        [getAllPublishVacancy.rejected.toString()]: (state) => {
            state.isLoading = false;
        },
    


        [getAllConsVacancy.pending.toString()]: (state) => {
            state.isLoading = true;
        },
        [getAllConsVacancy.fulfilled.toString()]: (
            state,
            action: PayloadAction<Array<AllVacancyResposne>>
        ) => {
            state.isLoading = false;
            state.vacancyLists = action.payload
        },
        [getAllConsVacancy.rejected.toString()]: (state) => {
            state.isLoading = false;
        },
        

        
        [VacancyStatus.pending.toString()]: (state) => {
            state.isLoading = true;
        },
        [VacancyStatus.fulfilled.toString()]: (
            state,
            action: PayloadAction<Array<AllVacancyResposne>>
        ) => {
            state.isLoading = false;
            state.vacancyLists = action.payload
        },
        [VacancyStatus.rejected.toString()]: (state) => {
            state.isLoading = false;
        },
    },
  
});

export default vacancySlice.reducer;
