/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment' // Import Moment type
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { AllVacancyResposne } from '../../../../../redux/admin/vacancy/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faCheckCircle,
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import ROUTES from '../../../../../const/routes'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { getAllPublishVacancy } from '../../../../../redux/admin/vacancy/api'

const ViewUnFilledVacancy = (): ReactElement => {
  const { updateViewpublishvacancy, vacancyUserDetails } = useSelector(
    (state: RootState) => ({
      updateViewpublishvacancy: state.vacancy
        .updateViewpublishvacancy as AllVacancyResposne,
      vacancyUserDetails: state.consultant.vacancyUserDetails
    })
  )

  console.log(vacancyUserDetails)

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  // Parse the selected date from the URL parameters
  const searchParams = new URLSearchParams(location.search)
  const selectedDateFromURL = searchParams.get('date')

  const handleBackClick = () => {
    if (selectedDateFromURL) {
      dispatch(
        getAllPublishVacancy({
          type: 0,
          date: moment(selectedDateFromURL).format('YYYY-MM-DD'), // Use the selected date here
          get_all: false
        })
      )
      history.push({
        pathname: ROUTES.UNFILLEDVACANCY,
        search: `?date=${selectedDateFromURL}`
      })
    }
  }

  const mapVacancyStatusToStringAndColor = (status: string | number) => {
    switch (status) {
      case 0:
        return { label: 'Unfilled', color: 'red', icon: faCircleXmark }
      case 1:
        return { label: 'Filled', color: 'green', icon: faCircle }
      case 2:
        return { label: 'Completed', color: 'green', icon: faCheckCircle }
      default:
        return { label: 'Unknown', color: 'gray', icon: faCircleXmark }
    }
  }

  const renderApprovedTime = () => {
    // Check if both approved_etime and approved_stime are null
    if (
      updateViewpublishvacancy.approved_etime === null &&
      updateViewpublishvacancy.approved_stime === null
    ) {
      // Display report time range when both approved times are null
      return `${formatTime(
        updateViewpublishvacancy.report_start_time
      )} - ${formatTime(updateViewpublishvacancy.report_end_time)}`
    } else {
      // Otherwise, display the approved time range
      return `${updateViewpublishvacancy.approved_etime} - ${updateViewpublishvacancy.approved_stime}`
    }
  }
  const renderApprovedHrs = () => {
    // Check if both approved_etime and approved_stime are null
    if (updateViewpublishvacancy.total_approved_hrs === null) {
      // Display report time range when both approved times are null
      return `${updateViewpublishvacancy.report_total_whours}`
    } else {
      // Otherwise, display the approved time range
      return `${updateViewpublishvacancy.total_approved_hrs} `
    }
  }

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle
          title="Detail view"
          hasBorder
          hasBackButton
          onBackClick={handleBackClick}
        />
        <FlexWrapper justifyContent="center">
          <Row>
            <Col xs={12} md={6}>
              <Column
                keyName="Customer name"
                value={`${updateViewpublishvacancy.customer_firstname}${updateViewpublishvacancy.customer_lastname}`}
              />
              <Column
                keyName="Position"
                value={updateViewpublishvacancy?.position}
              />
              <Column
                keyName="Published Date-Time"
                value={moment(
                  updateViewpublishvacancy?.created_at.slice(0, 19)
                ).format('YYYY-MM-DD- HH:mm')}
              />
              <Column
                keyName="Company name"
                value={updateViewpublishvacancy?.customer_company_name}
              />
              <Column
                keyName="Customer email"
                value={updateViewpublishvacancy?.customer_emailID}
              />
              <Column
                keyName="Organisation type"
                value={updateViewpublishvacancy?.customer_organization_type}
              />
              <Column
                keyName="Job Description"
                value={updateViewpublishvacancy.discription}
              />
              <Column
                keyName="Vacancy date"
                value={updateViewpublishvacancy.v_date}
              />
              <Column
                keyName="Vacancy time"
                value={`${formatTime(
                  updateViewpublishvacancy.from_time
                )}-${formatTime(updateViewpublishvacancy.to_time)}`}
              />
              <Column
                keyName="Break time"
                value={updateViewpublishvacancy.break_time}
              />
              <Column
                keyName="Customer contact"
                value={updateViewpublishvacancy.customer_telephone_number}
              />
            </Col>

            <Col xs={12} md={6}>
              {updateViewpublishvacancy.is_paid === 1 && (
                <>
                  <Column
                    keyName="Approved time"
                    value={renderApprovedTime()}
                  />
                  <Column
                    keyName="Total Approved Hours"
                    value={renderApprovedHrs()}
                  />
                  <Column
                    keyName="Reason by customer"
                    value={updateViewpublishvacancy.feedback}
                  />
                </>
              )}
              <Column
                keyName="Location"
                value={`${updateViewpublishvacancy.customer_address},${updateViewpublishvacancy.customer_area_name},${updateViewpublishvacancy.customer_postal_code}`}
              />
              <Column
                keyName="Recipient type"
                value={updateViewpublishvacancy.publish_to}
              />
              <Column
                keyName="Vacancy Status"
                value={
                  <>
                    {' '}
                    <FontAwesomeIcon
                      icon={
                        mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).icon
                      }
                      style={{
                        color: mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).color
                      }}
                    />
                    <span style={{ marginLeft: '5px' }}>
                      {
                        mapVacancyStatusToStringAndColor(
                          updateViewpublishvacancy.vacancy_status
                        ).label
                      }
                    </span>
                    {updateViewpublishvacancy?.cancelled_by_consultant ===
                      1 && (
                      <FontAwesomeIcon
                        title="This indicates that the previous consultant cancelled this vacancy 90 minutes or less prior to the start time. "
                        icon={faInfoCircle}
                        style={{ marginLeft: '5px', color: 'blue' }}
                      />
                    )}
                  </>
                }
              />
              {updateViewpublishvacancy?.cancelled_by_consultant === 1 && (
                <>
                  <Column
                    keyName="Previously cancelled by (Consultant)"
                    value={`${vacancyUserDetails[0]?.first_name} ${vacancyUserDetails[0]?.last_name}`}
                  />
                  <Column
                    keyName="Previously cancelled on (Consultant)"
                    value={moment(
                      vacancyUserDetails[0]?.vacancy_created_at?.slice(0, 19)
                    ).format('YYYY-MM-DD- HH:mm')}
                  />
                </>
              )}
            </Col>
          </Row>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewUnFilledVacancy
