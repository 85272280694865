import { ReactElement, useEffect, useState } from 'react'
import { Table, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  Input,
  PrimaryButton
} from '../../../../component'
import {
  getAllConsultantList,
  getAllDeniedConsultantList,
  deleteReviewedStatus,
  deletedeniedStatus
} from '../../../../redux/admin/consultant/api'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import { useHistory } from 'react-router'
import ROUTES from '../../../../const/routes'
import { updateSelectedConsultant } from '../../../../redux/admin/consultant/action'

const ApproveConsultant = (): ReactElement => {
  const {
    consultant: { getApprovedlist, isLoading }
  } = useSelector((state: RootState) => ({
    consultant: state.consultant
  }))

  const dispatch = useDispatch()

  const [filterType, setFilterType] = useState<any>(1)
  useEffect(() => {
    if (filterType === 1) {
      dispatch(
        getAllConsultantList({
          type: 1,
          is_verfied: true
        })
      )
    }
    if (filterType === 2) {
      dispatch(getAllDeniedConsultantList())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25) // Default: All (type 1)
  const [showModal, setshowModal] = useState('')
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }
  const history = useHistory()

  const totalPages = Math.ceil(getApprovedlist?.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const filteredSearchData = getApprovedlist?.filter((item) => {
    if (filterType === 1) {
      // If filterType is 1 (Approved), only show data where is_approved === 1
      return item.is_approved === 1
    }
    if (searchQuery === '') {
      return true
    } else if (
      `${item.first_name} ${item.last_name}`
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      // If filterType is not 1 and searchQuery matches, show the data
      return true
    }
    return false
  })

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin backgroundColor=" #f2fff2">
          <SectionTitle title="Consultant list" />
        </FlexWrapper>
        <>
          <FlexWrapper justifyContent="space-between">
            <TablePaginationEntries
              showEntries
              handleSelectedDataPerPage={(value) => {
                setdataPerPage(value)
              }}
            />

            <Input
              width="30%"
              value={searchQuery}
              placeholder="Search by consultant name"
              onChange={handleSearch}
            />
            <select
              value={filterType}
              onChange={(e) => setFilterType(Number(e.target.value))}
              style={{ width: '20%', height: '' }}
            >
              <option value={1}>Approved</option>
              <option value={2}>Denied</option>
            </select>
          </FlexWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Table striped borderless hover>
                <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    {TableHeaderData.map((data, index) => (
                      <th key={`Header Unique id - ${index}`}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredSearchData
                    ?.slice(
                      (total = currentPage * dataPerPage - dataPerPage),
                      currentPage * dataPerPage
                    )
                    .map((item, index) => (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{1 + total++}</td>
                        <td>{`${item.first_name} ${item.last_name}`}</td>
                        <td>{item.email_id}</td>
                        <td>
                          {moment(item?.created_at.slice(0, 19)).format(
                            'YYYY-MM-DD- HH:mm'
                          )}
                        </td>{' '}
                        <td>{item.phone_number}</td>
                        <td>
                          <Icon
                            onClick={() => {
                              history.push({
                                pathname: ROUTES.VIEW_CONSULTANT_DATA
                              })

                              dispatch(updateSelectedConsultant(item))
                            }}
                            icon={['fas', 'eye']}
                          />
                        </td>
                        <td>
                          <Icon
                            title="Delete Application"
                            onClick={() => {
                              setshowModal(item?.id)
                            }}
                            icon={['far', 'trash-alt']}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <TableFooter
                currentPage={currentPage}
                showEntries
                startValue={startindex}
                showPageDisplay={false}
                pageValue={endindex}
                TotalIems={getApprovedlist?.length}
                totalPages={totalPages}
                showButtonDisplay
                pageButtonsToShow={pageButtonsToShow}
                handlePrevious={() =>
                  setCurrentPages(Math.max(currentPage - 1, 1))
                }
                handleNext={() =>
                  setCurrentPages(Math.min(currentPage + 1, totalPages))
                }
                handlePageClick={handlePageClick}
              />
            </>
          )}
        </>
      </CardWraper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setshowModal('')
        }}
        backdrop="static"
      >
        <Modal.Header>
          Are you sure you want to delete this application permanently?
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper noMargin justifyContent="end">
            <PrimaryButton
              left
              onClick={async () => {
                await dispatch(
                  deleteReviewedStatus({
                    id: showModal
                  })
                )
                setshowModal('')
              }}
            >
              Yes
            </PrimaryButton>{' '}
            <PrimaryButton
              left
              onClick={() => {
                setshowModal('')
              }}
            >
              No
            </PrimaryButton>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
      {filterType === 2 && (
        <Modal
          show={!!showModal}
          onHide={() => {
            setshowModal('')
          }}
          backdrop="static"
        >
          <Modal.Header>
            Are you sure you want to delete this application permanently?
          </Modal.Header>
          <Modal.Footer>
            <FlexWrapper noMargin justifyContent="end">
              <PrimaryButton
                left
                onClick={async () => {
                  await dispatch(
                    deletedeniedStatus({
                      id: showModal
                    })
                  )
                  setshowModal('')
                }}
              >
                Yes
              </PrimaryButton>{' '}
              <PrimaryButton
                left
                onClick={() => {
                  setshowModal('')
                }}
              >
                No
              </PrimaryButton>
            </FlexWrapper>
          </Modal.Footer>
        </Modal>
      )}
    </PageWrapper>
  )
}

export default ApproveConsultant
