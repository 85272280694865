export const tableHeader = [
  'S.No',
  'Company name',
  'Position',
  'Published Date-Time',
  'Vacancy time',
  'Consultant',
  'Status',
  'Customer cancellation info',
  'Detail view'
]
