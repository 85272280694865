const ROUTES = {
  LOGIN: '/',
  HOME: '/dohrhome',
  DASHBOARD: '/dashboard',
  ADD_INSTITUTE: '/addInstitute',
  VIEW_INSTITUTE: '/viewInstitute',
  ADD_ROLE: '/addRole',
  ADD_TAX: '/addTax',
  APPROVE_CONSULTANTS: '/approveConsultants',
  VIEW_CONSULTANTS: '/viewConsultants',
  VIEW_SELECTED_INS: '/instituteView',
  CONSULTANTS_DETAILS: '/consultantsDetails',
  EXPRESS_PASS: '/expressPass',
  COMPLETED_VACC: '/completedVacancies',
  PUVLISHED_VACC: '/publishedVacancies',
  FILLEDVACANCY: '/filledVacancy',
  UNFILLEDVACANCY: '/unfilledVacancy',
  MANUALPUSH_SUBSTITUTE: '/manualpushsubstitute',
  PAIDVACANCY: '/paidvacancy',
  UNPAIDVACANCY: '/unpaidvacancy',
  VIEWFILLEDVACANCY: '/viewfilledvacancy',
  VIEWUNFILLEDVACANCY: '/viewunfilledvacancy,',
  VIEWPUBLISHEDDVACANCY: '/viewpublishedvacancy,',
  RESERVEPOOL: '/reservepool',
  MANUALPUSHCONSULTANT: '/manualpushconsultant',
  CONSTUMERFEEDBACK: '/customerfeedback',
  VIEWFEEDBACK_CUSTOMER: '/viewcustomerfeedback',
  CONSULTANTFEEDBACK: '/consultantfeedback',
  VIEWFEEDBACK_CONSULTANT: '/viewconsultantfeedback',
  DOHR_FEEDBACK: '/dohrfeedback',
  VIEWDOHR_FEEDBACK: '/viewdohrfeedback',
  VIEWCOMPLETED_VACANCY: '/viewcompletedvacancy',
  VIEWPAID_VACANCY: '/viewpaidvacancy',
  VIEWUNPAID_VACANCY: '/viewunpaidvacancy',
  MANUALPUSHALL: '/manualpushnotificationall',
  GETEXPRESSPASSUSERLIST: '/expresspassuserlist',
  VIEWEXPRESSPASSUSER: '/viewexpresspassuser',
  VIEWCUSTOMER: '/viewcustomer',
  VIEWCUSTOMERDETAIL: '/viewcustomerdetail',
  VIEWTOTALCUSTOMER: '/viewtotalcustomer',
  SUBSTITUTE: '/substitiue',
  VIEW_INACTIVE_CONSULTANT: '/viewInActiveConsultant',
  VIEW_SUBSTITUTE_CONSULTANAT: '/viewSubstituteConsultant',
  VIEW_CONSULTANT_DATA: '/viewconsultantdetails',
  WEBFEEDBACK: '/webfeedback',
  VIEWFEEDBACKWEB: '/ViewFeedbackWeb',
  CREATEAD: '/createad',
  PUBLISHEDAD: '/publishedad',
  PUBLISHEDADVIEW: '/publishedadview',
  ADUSERDETAILS: '/aduserdetails',
  PublishedAdUser: '/publishedaduser',
  OPENAPPLICATIONS: '/openapplication',
  OPENAPPLICATIONVIEW: '/openapplicationview',
  SUBSCRIPTIONS: '/subscriptions'
}

export default ROUTES
