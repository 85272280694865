import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faGear,
  faChevronLeft,
  faEye,
  faInfo,
  faRightFromBracket
} from '@fortawesome/free-solid-svg-icons'

import { faEdit, faBell, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

library.add(
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faBell,
  faGear,
  faEye,
  faTrashAlt,
  faChevronLeft,
  faEdit,
  faInfo,
  faRightFromBracket
)
