import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  addExpressPass,
  getAllConsultantList,
  getConsultant,
  getExpressPass,
  getAllUserList,
  ExpressUpdateCount,
  getVacancyDataList,
  getHoursList
} from './api'
import {
  getConsultantResponse,
  GetexpressPass,
  GetHoursListProsp,
  initialState
} from './types'

export const consultantsSlice = createSlice({
  name: 'Consultants',
  initialState,
  reducers: {
    updateSelectedConsultant: (
      state,
      action: PayloadAction<getConsultantResponse | null>
    ) => {
      state.setSelectedConsutant = action.payload
    },
    updateSubstituteSelectedConsultant: (state, action: PayloadAction<any>) => {
      state.setSubstituteSelectedConsutant = action.payload
    }
  },
  extraReducers: {
    [getConsultant.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getConsultantResponse>>
    ) => {
      state.isLoading = false
      state.getApprovelist = action?.payload
    },
    [getConsultant.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getAllConsultantList.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getAllConsultantList.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getConsultantResponse>>
    ) => {
      state.isLoading = false
      state.getApprovedlist = action?.payload
    },
    [getAllConsultantList.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [getExpressPass.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetexpressPass>>
    ) => {
      state.isLoading = false
      state.GetExpressPass = action?.payload
    },
    [addExpressPass.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.addExpressResponse = action.payload
    },
    [getAllUserList.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getConsultantResponse>>
    ) => {
      state.isLoading = false
      state.getApprovedlist = action.payload
    },
    [ExpressUpdateCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getConsultantResponse>>
    ) => {
      state.isLoading = false
      state.getApprovedlist = action.payload
    },
    [getVacancyDataList.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.vacancyUserDetails = action.payload
    },
    [getHoursList.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetHoursListProsp>>
    ) => {
      state.isLoading = false
      state.getHoursDetials = action.payload
    }
  }
})

export default consultantsSlice.reducer
