import React, { ReactElement, useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Table, Button } from 'react-bootstrap'
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  Icon,
  PrimaryButton,
  Input,
  Loader
} from '../../../../../component'
import {
  CardWraper,
  IsApprovedAction
} from '../../../Consultants/subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../../component/TablePaginationEntires'
import TableFooter from '../../../../../component/TableFooter'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory, useLocation } from 'react-router-dom'
import ROUTES from '../../../../../const/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import {
  Publishedadapplication,
  deleteadaplication,
  handlestatusApplication
} from '../../../../../redux/admin/WebsiteAd/api'
import { updateViewApplication } from '../../../../../redux/admin/WebsiteAd/action'

const PublishedAdUser = (): ReactElement => {
  const { Applicationadlist, isLoading } = useSelector((state: RootState) => ({
    Applicationadlist: state.Websitead.getapplicationlist,
    isLoading: state.Websitead.isLoading
  }))

  const dispatch = useDispatch()

  const dateformatTime = (time: Date | null | string) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  let sorteddata = [...Applicationadlist]

  let total: number

  const [filterType, setFilterType] = useState<any>(1) // Default: All (type 1)
  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4
  const [showModal, setShowModal] = useState<any>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [selectedApplicationId, setSelectedApplicationId] = useState(null)
  const [selectedEmailid, setselectedEmailid] = useState('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const history = useHistory()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const advertisementid = searchParams.get('id')

  useEffect(() => {
    dispatch(
      Publishedadapplication({
        type: filterType,
        advertisement_id: advertisementid
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  const filteredSearchData = sorteddata?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      `${items.first_name} ${items.last_name}`
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })
  console.log(selectedEmailid)
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Job applicants list" hasBorder hasBackButton />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                height="60%"
                value={searchQuery}
                placeholder="Search by name"
                onChange={handleSearch}
              />
              <select
                value={filterType}
                onChange={(e) => setFilterType(Number(e.target.value))}
                style={{ width: '20%', height: '70%' }}
              >
                <option value={1}>Applied</option>
                <option value={2}>Approved</option>
                <option value={3}>Denied</option>
              </select>
            </FlexWrapper>
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {filteredSearchData
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{`${item.first_name} ${item.last_name}`}</td>
                      <td>{dateformatTime(item.created_at)}</td>

                      <td>
                        {filterType === 1 ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <IsApprovedAction
                              handleApprove={() => {
                                // Show the approval confirmation modal
                                setShowApproveModal(true)
                                setselectedEmailid(item.email_id)
                                setSelectedApplicationId(item.id)
                              }}
                              handleDeny={() => {
                                // Show the deny confirmation modal
                                setShowDenyModal(true)
                                setselectedEmailid(item.email_id)
                                setSelectedApplicationId(item.id)
                              }}
                            />
                          </div>
                        ) : (
                          <Icon
                            icon={['far', 'trash-alt']}
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              setShowModal(item?.id)
                            }}
                          />
                        )}
                      </td>

                      <td>
                        {' '}
                        {''}
                        <Icon
                          icon={['fas', 'eye']}
                          onClick={() => {
                            dispatch(updateViewApplication(item))
                            history.push({
                              pathname: ROUTES.ADUSERDETAILS
                            })
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setShowModal('')
        }}
      >
        <Modal.Header>
          Are you sure you want to delete this applicant?
        </Modal.Header>
        <Modal.Footer>
          <PrimaryButton
            onClick={() => {
              setShowModal('')
            }}
          >
            No
          </PrimaryButton>
          {''} {''}
          <PrimaryButton
            onClick={() => {
              dispatch(
                deleteadaplication({
                  id: showModal,
                  filtertype: filterType,
                  advertisement_id: advertisementid
                })
              )
              setShowModal('')
            }}
          >
            Yes
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
      <Modal show={showApproveModal} onHide={() => setShowApproveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve this application?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              console.log(selectedEmailid, advertisementid)
              // Handle the approval logic here using the selectedConsultantId
              dispatch(
                handlestatusApplication({
                  id: selectedApplicationId,
                  is_approved: true,
                  is_denied: false,
                  email_id: selectedEmailid,
                  advertisement_id: advertisementid
                })
              )
              setShowApproveModal(false)
            }}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Deny Confirmation Modal */}
      <Modal show={showDenyModal} onHide={() => setShowDenyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to deny this application?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              // Handle the deny logic here using the selectedConsultantId
              dispatch(
                handlestatusApplication({
                  id: selectedApplicationId,
                  is_approved: false,
                  is_denied: true,
                  email_id: selectedEmailid,
                  advertisement_id: advertisementid
                })
              )
              setShowDenyModal(false)
            }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowDenyModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  )
}

export default PublishedAdUser
