import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SideMenu from '../../component/Sidemenu'
import { RootState } from '../../redux/store'
import { MenuWrapperProps, SideNavigationProps } from '../Sidemenu/types'
import menuItems from '../../const/menu'

const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  @media (max-width: 769px) {
    width: ${({ isMenuOpen }) => (isMenuOpen ? '25%' : '0')};
  }
  @media (max-width: 415px) {
    width: ${({ isMenuOpen }) => (isMenuOpen ? '12rem' : '0')};
    position: absolute;
    height: 100%;
  }
  width: ${({ isMenuOpen }) => (isMenuOpen ? '20rem' : 0)};
  transition: left 0.3s ease, width 0.45s ease;
`

const SideNavigation = ({ isDucare }: SideNavigationProps): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => ({
    isMenuOpen: state.menu.isMenuOpen
  }))

  // Determine which set of menus to pass based on the isDucare flag
  const menusToPass = isDucare ? menuItems.user1 : menuItems.user

  return (
    <MenuWrapper isMenuOpen={isMenuOpen}>
      {/* Pass the appropriate menus to the SideMenu component */}
      <SideMenu menus={menusToPass} />
    </MenuWrapper>
  )
}

export default SideNavigation
