import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndPoints";
import { ManualPush } from "./types";

export const getAllManualPushMsg = createAsyncThunk(
    'Manualpushall/Notifications',
    async (requestPayload:  ManualPush): Promise<any> => {
        const response = await api.post(apiEndpoints.ManualpushAll, requestPayload)
        return response?.data?.message
    }
)