import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  initialState,
  GetAllCustomerresponse,
  GetopenApplicationresponse
} from './types'
import { getAllcustomerdata, customerstatus, getAllApplication } from './api'

export const customerslice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    updateViewCustomer: (
      state,
      action: PayloadAction<GetAllCustomerresponse | null>
    ) => {
      state.updateViewCustomer = action.payload
    },
    updateApplicationData: (
      state,
      action: PayloadAction<GetopenApplicationresponse | null>
    ) => {
      state.setApplicationData = action.payload
    }
  },
  extraReducers: {
    [getAllcustomerdata.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getAllcustomerdata.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAllCustomerresponse>>
    ) => {
      state.isLoading = false
      state.listofcustomer = action?.payload
    },
    [getAllcustomerdata.rejected.toString()]: (state) => {
      state.isLoading = false
    },

    [customerstatus.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [customerstatus.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAllCustomerresponse>>
    ) => {
      state.isLoading = false
      state.listofcustomer = action.payload
    },
    [customerstatus.rejected.toString()]: (state) => {
      state.isLoading = false
    },

    [getAllApplication.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getAllApplication.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetopenApplicationresponse>>
    ) => {
      state.isLoading = false
      state.getApprovedlist = action.payload
    },
    [getAllApplication.rejected.toString()]: (state) => {
      state.isLoading = false
    }
  }
})

export default customerslice.reducer
