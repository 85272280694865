import { vacancySlice } from './reducer'
import { getAllPublishVacancy, getAllConsVacancy, VacancyStatus } from './api'

const { updateViewVacancy } = vacancySlice.actions

export {
  updateViewVacancy,
  getAllPublishVacancy,
  getAllConsVacancy,
  VacancyStatus
}
