import React, { ReactElement, useEffect, useState } from 'react'
import { Modal, ModalFooter, Table } from 'react-bootstrap'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  PrimaryButton,
  Input
} from '../../../../component'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Consultants/subcomponents'
import { TableHeaderData } from '../const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import { Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router'
import ROUTES from '../../../../const/routes'
import {
  getAllcustomerdata,
  customerstatus
} from '../../../../redux/admin/customer/api'
import { customerai } from '../../../../redux/admin/customer/types'
import { updateViewCustomer } from '../../../../redux/admin/customer/action'

import { deleteCustomerStatus } from '../../../../redux/admin/consultant/api'
import DataExportButton from './DataExport'

const ViewCustomer = (): ReactElement => {
  const { listcustomer, isLoading } = useSelector(
    (state: RootState) => ({
      listcustomer: state.customer.listofcustomer,
      isLoading: state.customer.isLoading
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const history = useHistory()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const sorteddata = [...listcustomer]
  const [filterType, setFilterType] = useState<any>(1) // Default: All (type 1)

  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number
  const [showModal, setShowModal] = useState<any>('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [actionType, setActionType] = useState('')
  const [selectedCustomerId, setSelectedCustomerId] = useState(0)
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState('')

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  useEffect(() => {
    dispatch(
      getAllcustomerdata({
        type: filterType,
        is_verfied: true
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType])

  const handleMakeInactive = (customerId: number, email_id: any) => {
    setActionType('inactive')
    setSelectedCustomerId(customerId)
    setSelectedCustomerEmail(email_id)
    setShowConfirmationModal(true)
  }

  const handleMakeActive = (customerId: number) => {
    setActionType('active')
    setSelectedCustomerId(customerId)
    setShowConfirmationModal(true)
  }

  const confirmAction = async () => {
    setShowConfirmationModal(false)

    if (actionType === 'inactive') {
      await handleInactivate(selectedCustomerId, selectedCustomerEmail)
    } else if (actionType === 'active') {
      await handleActivate(selectedCustomerId, selectedCustomerEmail)
    }

    // Fetch customer data again after making changes
    await dispatch(
      getAllcustomerdata({
        type: filterType,
        is_verfied: true
      })
    )
  }

  const handleInactivate = async (customerId: number, email_id: any) => {
    try {
      const payload: customerai = {
        data: true,
        id: customerId,
        email_id: email_id
      }
      await dispatch(customerstatus(payload)) // Dispatch the action to make the customer inactive
    } catch (error) {
      console.error('Error inactivating customer:', error)
    }
  }

  const handleActivate = async (customerId: number, email_id: any) => {
    try {
      const payload: customerai = {
        data: false,
        id: customerId,
        email_id: email_id
      }
      await dispatch(customerstatus(payload)) // Dispatch the action to make the customer active
    } catch (error) {
      console.error('Error activating customer:', error)
    }
  }

  let sortingData =
    filterType === 4
      ? listcustomer?.filter((data) => data?.is_verfied === 0)
      : sorteddata

  const filteredSearchData = sortingData?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      items.company_name?.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const savedFilterType = params.get('filterType')

    if (savedFilterType) {
      setFilterType(Number(savedFilterType))
    }

    // ... your existing code
  }, [history.location.search])

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Customer list" hasBorder />
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                height="60%"
                value={searchQuery}
                placeholder="Search by company name"
                onChange={handleSearch}
              />
              <select
                value={filterType}
                onChange={(e) => {
                  setFilterType(Number(e.target.value))
                  history.push(`?filterType=${e.target.value}`)
                }}
                style={{ width: '20%', height: '70%' }}
              >
                <option value={1}>All</option>
                <option value={4}>Awaiting</option>
                <option value={2}>Active</option>
                <option value={3}>Inactive</option>
              </select>
              <DataExportButton
                exportData={filteredSearchData}
                filterType={filterType}
              />
            </FlexWrapper>
            <Table striped borderless hover responsive>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {filteredSearchData
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map(
                    (
                      item,
                      index // Inactive
                    ) => (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{1 + total++}</td>
                        <td>{`${item.first_name} ${item.last_name} `}</td>
                        <td>{item.company_name}</td>
                        <td>{item.email_id}</td>
                        <td>
                          {moment(item?.created_at.slice(0, 19)).format(
                            'YYYY-MM-DD- HH:mm'
                          )}
                        </td>{' '}
                        <td>{item.telephone_number}</td>
                        <td>
                          {item?.is_verfied === 0 ? (
                            'Awaiting'
                          ) : item.is_inactive == 0 ? (
                            <Button
                              variant="warning"
                              onClick={() =>
                                handleMakeInactive(item.id, item?.email_id)
                              }
                            >
                              Inactivate
                            </Button>
                          ) : (
                            <>
                              <Button
                                variant="success"
                                onClick={() => handleMakeActive(item.id)}
                              >
                                Activate
                              </Button>
                            </>
                          )}
                        </td>
                        <td>
                          <Icon
                            onClick={() => {
                              history.push({
                                pathname: ROUTES.VIEWCUSTOMERDETAIL
                              })
                              history.push(`?filterType=${filterType}`)
                              dispatch(updateViewCustomer(item))
                            }}
                            icon={['fas', 'eye']}
                          />
                          {item?.is_verfied === 0 || item.is_inactive === 1 ? (
                            <Icon
                              icon={['far', 'trash-alt']}
                              onClick={() => {
                                setShowModal(item?.id)
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
      <Modal
        show={!!showModal}
        onHide={() => {
          setShowModal('')
        }}
      >
        <Modal.Header>
          Are you sure you want to delete this customer account?
        </Modal.Header>
        <ModalFooter>
          <FlexWrapper noPadding justifyContent="end">
            <PrimaryButton
              onClick={() => {
                setShowModal('')
              }}
            >
              No
            </PrimaryButton>

            <PrimaryButton
              onClick={() => {
                dispatch(
                  deleteCustomerStatus({
                    id: showModal
                  })
                )
              }}
            >
              Yes
            </PrimaryButton>
          </FlexWrapper>
        </ModalFooter>
      </Modal>

      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header>
          {actionType === 'inactive'
            ? 'Are you sure you want to inactivate this customer?'
            : 'Are you sure you want to reactivate this customer?'}
        </Modal.Header>
        <ModalFooter>
          <FlexWrapper noPadding justifyContent="end">
            <PrimaryButton onClick={() => setShowConfirmationModal(false)}>
              No
            </PrimaryButton>
            <PrimaryButton onClick={confirmAction}>Yes</PrimaryButton>
          </FlexWrapper>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  )
}

export default ViewCustomer
