import { ReactElement, useState } from 'react'
import { Menu } from './types'
import menuItems from '../../const/menu'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
  ListItems,
  MenuContainer,
  MenuListWrapper,
  FontIcon,
  MenuLogo,
  Anchor,
  ChevronIcon,
  ListWrapper
} from './subcomponents'
import { updateIsMenuOpen } from '../../redux/Menu/action'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface SideMenuProps {
  menus?: Array<Menu>
}

const SideMenu = ({
  menus = menuItems.user
}: SideMenuProps): ReactElement => {
  const { isMenuOpen } = useSelector(
    (state: RootState) => ({ isMenuOpen: state.menu.isMenuOpen }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [selectedChild, setSelectedChild] = useState(-1)

  const handleMenuClick = (index: number, hasChilds: boolean) => {
    setSelectedMenu(selectedMenu === index ? -1 : index)
    setSelectedChild(hasChilds ? 0 : -1)
    if (!isMenuOpen) {
      dispatch(updateIsMenuOpen(true))
    }
  }

  const handleChildClick = (index: number) => {
    setSelectedChild(index)
  }

  return (
    <MenuContainer>
      <ListWrapper>
        {menus?.map((item, index) => {
          const { childs = [] } = item
          const isSelected = selectedMenu === index
          const hasChilds = childs.length > 0

          return (
            <>
              <ListItems
                key={`list${index}`}
                $isSelected={isSelected}
                onClick={() => handleMenuClick(index, hasChilds)}
              >
                <MenuListWrapper $isSelected={isSelected}>
                  {typeof item?.icon === 'string' ? (
                    <MenuLogo src={item?.icon} alt="icon" />
                  ) : (
                    <FontIcon
                      icon={item?.icon}
                      size="sm"
                      $isSelected={isSelected}
                      isMenuOpen={isMenuOpen}
                    />
                  )}
                  {hasChilds ? (
                    <>
                      <Anchor $isSelected={isSelected} to={item?.to}>
                        {item?.label}
                      </Anchor>
                      <ChevronIcon
                        icon={[
                          'fas',
                          isSelected ? 'chevron-up' : 'chevron-down'
                        ]}
                        size="sm"
                      />
                    </>
                  ) : (
                    <Anchor $isSelected={isSelected} to={item?.to}>
                      {item?.label}
                    </Anchor>
                  )}
                </MenuListWrapper>
              </ListItems>
              {isSelected && isMenuOpen && hasChilds && (
                <MenuContainer key={`subList${index}`} isChild>
                  {childs.map((child, childIndex) => (
                    <ListItems
                      key={`${index}-${childIndex}`}
                      $isSelected={selectedChild === childIndex}
                      onClick={() => handleChildClick(childIndex)}
                    >
                      <FontIcon
                        $isSelected={selectedChild === childIndex}
                        style={{ fontSize: '12px' }}
                        icon={child?.icon as IconProp}
                        size="sm"
                      ></FontIcon>
                      <Anchor
                        $isSelected={selectedChild === childIndex}
                        font
                        to={child?.to}
                      >
                        {child?.label}
                      </Anchor>
                    </ListItems>
                  ))}
                </MenuContainer>
              )}
            </>
          )
        })}
      </ListWrapper>
    </MenuContainer>
  )
}

export default SideMenu
