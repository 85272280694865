import moment from 'moment'
import { ReactElement, useEffect, useState, useMemo } from 'react'
import { FormCheck, Table } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  Input,
  DropdownWrapper
} from '../../../../component'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Consultants/subcomponents'
import { tableHeader } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory, useLocation } from 'react-router'
import ROUTES from '../../../../const/routes'
import { getAllPublishVacancy } from '../../../../redux/admin/vacancy/api'
import { updateViewVacancy } from '../../../../redux/admin/vacancy/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
  faCheckCircle,
  faCircle,
  faInfoCircle,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons'
import DataExportButton from './DataExport'
import { getVacancyDataList } from '../../../../redux/admin/consultant/api'

const Published = (): ReactElement => {
  const { vacancyLists, isLoading } = useSelector(
    (state: RootState) => ({
      vacancyLists: state.vacancy.vacancyLists,
      isLoading: state.vacancy.isLoading
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sorteddata = [...vacancyLists]
  const [selectedDate, setSelectedDate] = useState<moment.Moment | any>(null)

  useEffect(() => {
    // Check if the selectedDate is not set (meaning it's the initial visit)
    if (!selectedDate) {
      dispatch(
        getAllPublishVacancy({
          type: 3,
          date: moment(new Date()).format('YYYY-MM-DD'),
          get_all: false
        })
      )
      setcheckedToggle(false) // Set the toggle to false on initial visit
    } else {
      dispatch(
        getAllPublishVacancy({
          type: 3,
          date: selectedDate.format('YYYY-MM-DD'), // Use the selected date here
          get_all: false
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Parse the selected date from the URL parameters
    const searchParams = new URLSearchParams(location.search)
    const selectedDateFromURL = searchParams.get('date')
    const showAllToggleFromURL = searchParams.get('showAll')
    // Set the selected date state
    setSelectedDate(
      selectedDateFromURL ? moment(selectedDateFromURL) : moment()
    )
    setcheckedToggle(showAllToggleFromURL === 'true')
  }, [location.search])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<
    string | null
  >(null)

  const handleStatusFilterChange = (status: string | null) => {
    if (status === '') {
      // If "All Status" is selected, set selectedStatusFilter to null
      setSelectedStatusFilter(null)
    } else {
      setSelectedStatusFilter(status)
    }
  }

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  const sortedFilteredData = useMemo(() => {
    let filteredList = sorteddata
    if (selectedDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.v_date).isSame(selectedDate, 'day')
      )
    }

    return filteredList
  }, [selectedDate, sorteddata])

  const [checkedToggle, setcheckedToggle] = useState(false)

  useEffect(() => {
    // Dispatch the initial data loading based on the toggle state
    dispatch(
      getAllPublishVacancy({
        type: 3,
        date: selectedDate ? selectedDate.format('YYYY-MM-DD') : null,
        get_all: checkedToggle
      })
    )
  }, [selectedDate, checkedToggle, dispatch])

  let filteredData
  if (!checkedToggle) {
    filteredData =
      sortOrder === 'asc'
        ? sortedFilteredData
        : [...sortedFilteredData].reverse()
  } else {
    filteredData =
      sortOrder === 'asc' ? vacancyLists : [...vacancyLists].reverse()
  }

  const tableData = checkedToggle ? filteredData : filteredData
  const totalPages = Math.ceil(tableData.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number

  const filteredDatas = tableData?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      items.customer_company_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })

  const mapVacancyStatusToStringAndColor = (status: string | number) => {
    switch (status) {
      case 0:
        return { label: 'Unfilled', color: 'red', icon: faCircleXmark }
      case 1:
        return { label: 'Filled', color: 'green', icon: faCircle }
      case 2:
        return { label: 'Completed', color: 'green', icon: faCheckCircle }
      default:
        return { label: 'Unknown', color: 'gray', icon: faCircleXmark }
    }
  }

  const filteredDatasstatus =
    selectedStatusFilter === null
      ? filteredDatas
      : filteredDatas.filter((item) => {
          const statusInfo = mapVacancyStatusToStringAndColor(
            item.vacancy_status
          )
          return statusInfo.label === selectedStatusFilter
        })

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date ? moment(date) : null)
    setcheckedToggle(false)
    dispatch(
      getAllPublishVacancy({
        type: 3,
        date: date ? moment(date).format('YYYY-MM-DD') : null,
        get_all: false
      })
    )
  }

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')
  const [showCancelInfo, setShowCancelInfo] = useState<boolean[]>(
    new Array(vacancyLists.length).fill(false)
  )

  const dateformat = (time: Date) => {
    return moment(time).format('YYYY-MM-DD')
  }
  // Function to toggle the visibility of cancel info for a specific row
  const toggleCancelInfo = (vacancyId: number) => {
    setShowCancelInfo((prevShowCancelInfo) => ({
      ...prevShowCancelInfo,
      [vacancyId]: !prevShowCancelInfo[vacancyId]
    }))
  }

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Published Vacancies" hasBorder />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <DatePicker
              selected={selectedDate ? selectedDate.toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select a date"
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              showMonthDropdown
              showYearDropdown
              customInput={
                <Input
                  value={selectedDate}
                  placeholder="Select date"
                  onClick={(value: string) => {
                    setcheckedToggle(false)
                    dispatch(
                      getAllPublishVacancy({
                        type: 3,
                        date: moment(value).format('YYYY-MM-DD'),
                        get_all: false
                      })
                    )
                  }}
                />
              }
            />
          </DropdownWrapper>
          <DropdownWrapper width="30%" style={{ margin: '33px 0 10px 0' }}>
            <FormCheck
              type="switch"
              label="Show All"
              checked={checkedToggle}
              onClick={() => {
                setcheckedToggle(!checkedToggle)
                setSelectedStatusFilter(null)
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper style={{ margin: '33px 0px 10px 290px' }}>
            <Input
              width="100%"
              value={searchQuery}
              placeholder="Search by company name"
              onChange={handleSearch}
            />
          </DropdownWrapper>
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              {checkedToggle && (
                <DropdownWrapper width="20%">
                  <select
                    className="form-select"
                    onChange={(e) => handleStatusFilterChange(e.target.value)}
                    value={selectedStatusFilter || ''}
                  >
                    <option value="">All</option>
                    <option value="Unfilled">Unfilled</option>
                    <option value="Filled">Filled</option>
                    <option value="Completed">Completed</option>
                  </select>
                </DropdownWrapper>
              )}

              <DataExportButton exportData={filteredDatas} />
            </FlexWrapper>
            <Table striped borderless hover responsive>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th
                    onClick={() => {
                      toggleSortOrder()
                      // You can perform sorting based on your data here.
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon
                      icon={sortOrder === 'asc' ? faArrowUp : faArrowDown}
                      color="white"
                    />
                  </th>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {filteredDatasstatus
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  ?.map((item, index) => {
                    return (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{}</td>
                        <td>{1 + total++}</td>
                        <td>{`${item.customer_company_name} `}</td>
                        <td>{item.position}</td>
                        <td>
                          {moment(item?.created_at.slice(0, 19)).format(
                            'YYYY-MM-DD- HH:mm'
                          )}
                        </td>{' '}
                        <td>{dateformat(item.v_date)}</td>
                        <td>{`${formatTime(item.from_time)}-${formatTime(
                          item.to_time
                        )}`}</td>
                        {item.vacancy_status === 0 ? (
                          <td>NA</td>
                        ) : (
                          <td>{`${item.consultant_first_name} ${item.consultant_last_name}`}</td>
                        )}
                        <td>
                          <FontAwesomeIcon
                            icon={
                              mapVacancyStatusToStringAndColor(
                                item.vacancy_status
                              ).icon
                            }
                            style={{
                              color: mapVacancyStatusToStringAndColor(
                                item.vacancy_status
                              ).color
                            }}
                          />
                          <span
                            style={{
                              marginLeft: '5px',
                              color: mapVacancyStatusToStringAndColor(
                                item.vacancy_status
                              ).color
                            }}
                          >
                            {
                              mapVacancyStatusToStringAndColor(
                                item.vacancy_status
                              ).label
                            }
                          </span>
                          {item.vacancy_status === 0 &&
                            item?.cancelled_by_consultant === 1 && (
                              <FontAwesomeIcon
                                title="This indicates that the previous consultant cancelled this vacancy 90 minutes or less prior to the start time. "
                                icon={faInfoCircle}
                                style={{
                                  marginLeft: '5px',
                                  color: 'blue',
                                  cursor: 'pointer'
                                }}
                              />
                            )}
                        </td>
                        <td>
                          {item.is_canceled === 0 ? (
                            'NA'
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <button
                                onClick={() => toggleCancelInfo(item.id)}
                                style={{
                                  cursor: 'pointer',
                                  color:
                                    item.cancellation_charges === '0'
                                      ? 'blue'
                                      : 'red'
                                }}
                              >
                                Cancelled
                              </button>
                              <button
                                style={{
                                  cursor: 'pointer',
                                  position: 'relative',
                                  color:
                                    item.cancellation_charges === '0'
                                      ? 'blue'
                                      : 'red'
                                }}
                                onClick={() => toggleCancelInfo(item.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  style={{ marginLeft: '5px' }}
                                />
                                {showCancelInfo[item.id] && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      bottom: '50%',
                                      left: '50%',
                                      transform: 'translateX(-50%)',
                                      background: '#f1f1f1',
                                      padding: '10px',
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                      fontSize: '12px',
                                      maxWidth: '100px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: '5px',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      Cancel Information:
                                    </div>
                                    <div>
                                      {item.cancellation_charges === '0'
                                        ? 'A customer cancelled the vacancy without charges.'
                                        : 'The cancellation was made after the cancellation deadline. An invoice to be issued to the customer!'}
                                    </div>
                                  </div>
                                )}
                              </button>
                            </div>
                          )}
                        </td>
                        <td>
                          <Icon
                            onClick={() => {
                              history.push({
                                pathname: ROUTES.VIEWPUBLISHEDDVACANCY,
                                search: `?date=${selectedDate.format(
                                  'YYYY-MM-DD'
                                )}&showAll=${checkedToggle ? 'true' : 'false'}`
                              })
                              if (item.vacancy_status === 0) {
                                dispatch(
                                  getVacancyDataList({
                                    vacancy_id: item?.id
                                  })
                                )
                              }
                              dispatch(updateViewVacancy(item))
                            }}
                            icon={['fas', 'eye']}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={tableData.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
    </PageWrapper>
  )
}

export default Published
