import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState,ManualPushresponse } from "./types";
import { getAllManualPushMsg } from "./api";

export const initialState: InitialState = {
    isLoading: false,
  ManualPushMsg:{
    message:""
  },
};

export const ManualPushSlice = createSlice({
    name: "manualpushall",
    initialState,
    reducers: {


    },
    extraReducers: {
        [getAllManualPushMsg.pending.toString()]: (state) => {
            state.isLoading = true;
        },
        [getAllManualPushMsg.fulfilled.toString()]: (
            state,
            action: PayloadAction<ManualPushresponse>
        ) => {
            state.isLoading = false;
            state. ManualPushMsg  = action.payload
        },
        [getAllManualPushMsg.rejected.toString()]: (state) => {
            state.isLoading = false;
        },
    


    },
  
});

export default ManualPushSlice.reducer;
