import moment from 'moment' // Import Moment type
import React, { ReactElement, useEffect, useState, useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  Input
} from '../../../../component'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Consultants/subcomponents'
import { tableHeader } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router'
import ROUTES from '../../../../const/routes'
import {
  getAllConsVacancy,
  VacancyStatus
} from '../../../../redux/admin/vacancy/api'
import { updateViewVacancy } from '../../../../redux/admin/vacancy/action'

const UnPaidVacancy = (): ReactElement => {
  const { vacancyLists, isLoading } = useSelector(
    (state: RootState) => ({
      vacancyLists: state.vacancy.vacancyLists,
      isLoading: state.vacancy.isLoading
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    dispatch(
      getAllConsVacancy({
        is_paid: 0
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sorteddata = [...vacancyLists]

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const filteredData = useMemo(() => {
    let filteredList = sorteddata
    if (searchQuery.trim() !== '') {
      filteredList = filteredList.filter(
        (item) =>
          item.consultant_first_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase().trim()) ||
          item.consultant_last_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase().trim())
      )
    }
    return filteredList
  }, [sorteddata, searchQuery])

  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4
  let total: number

  const formatTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm')

  const [checkboxStatus] = useState<{
    [id: number]: boolean
  }>({})

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
    notificationId: any
  ) => {
    try {
      const isChecked = event.target.checked

      if (isChecked) {
        const confirmed = window.confirm(
          'Are you sure you want to change the status to Paid?'
        )

        if (!confirmed) {
          // If the user cancels, remove the tick mark by reverting the checkbox state
          event.target.checked = false
          return
        }

        await dispatch(
          VacancyStatus({
            is_paid: 1,
            id: Number(id),
            notificationId: notificationId
          })
        )
        // Once the VacancyStatus API call is successful, call getAllConsVacancy
        dispatch(getAllConsVacancy({ is_paid: 0 }))
      } else {
        // If the user unchecks the checkbox, no confirmation is needed.
        await dispatch(VacancyStatus({ is_paid: 0, id: Number(id) }))
        // Once the VacancyStatus API call is successful, call getAllConsVacancy
        dispatch(getAllConsVacancy({ is_paid: 0 }))
      }
    } catch (error) {
      // Handle any error that occurred during API calls
      console.error('Error updating vacancy status:', error)
    }
  }

  useEffect(() => {
    // For each checked row, call the API with is_paid set to true (1) or false (0)
    Object.entries(checkboxStatus).forEach(([id, isChecked]) => {
      dispatch(VacancyStatus({ is_paid: isChecked, id: Number(id) }))
    })
  }, [checkboxStatus, dispatch])

  const mapVacancyStatusToColor = (status: string | number): string => {
    switch (status) {
      case 0:
        return 'red' // Custom color for cusPending
      case 1:
        return 'green' // Custom color for Paid
      case 2:
        return 'orange' // Custom color for Pending
      default:
        return 'gray' // Default color for Unknown status
    }
  }

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Unpaid vacancies" hasBorder />
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                value={searchQuery}
                placeholder="Search by Consultant"
                onChange={handleSearch}
              />
            </FlexWrapper>
            <Table striped borderless hover responsive>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData
                  ?.slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>
                        {item.customer_firstname} {item.customer_lastname}
                      </td>
                      <td>{item.position}</td>
                      <td>{item.v_date}</td>
                      <td>{item.customer_company_name}</td>
                      <td>
                        {item.consultant_first_name} {item.consultant_last_name}
                      </td>
                      <td>
                        {formatTime(item.from_time)}-{formatTime(item.to_time)}
                      </td>
                      <td>
                        {formatTime(item.report_start_time)}-
                        {formatTime(item.report_end_time)}
                      </td>
                      <td>
                        {item.vacancy_is_approved === 0 ? (
                          <span>NA</span>
                        ) : item.approved_etime && item.approved_stime ? (
                          <span>
                            {formatTime(item.approved_stime)} -{' '}
                            {formatTime(item.approved_etime)}
                          </span>
                        ) : (
                          <span>
                            {formatTime(item.report_start_time)} -{' '}
                            {formatTime(item.report_end_time)}
                          </span>
                        )}
                      </td>

                      {item.total_approved_hrs ? (
                        <td>{item.total_approved_hrs}</td>
                      ) : (
                        <td>{item.report_total_whours}</td>
                      )}
                      <td>
                        <input
                          type="checkbox"
                          checked={checkboxStatus[item.v_id]}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event,
                              item.v_id,
                              item?.consultant_notification_id
                            )
                          }
                        />
                      </td>

                      <td
                        style={{ color: mapVacancyStatusToColor(item.is_paid) }}
                      >
                        {item.vacancy_is_approved === 0 ? (
                          <span className="custom-status-pending">
                            Cuspending
                          </span>
                        ) : item.is_paid === 0 ? (
                          <span className="custom-status-pending">Pending</span>
                        ) : (
                          <span className="custom-status-paid">paid</span>
                        )}
                      </td>

                      <td>
                        <Icon
                          onClick={() => {
                            history.push(ROUTES.VIEWUNPAID_VACANCY)

                            dispatch(updateViewVacancy(item))
                          }}
                          icon={['fas', 'eye']}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />
          </>
        )}
      </CardWraper>
    </PageWrapper>
  )
}

export default UnPaidVacancy
