import { ReactElement, useState, useEffect } from 'react'
import {
  FlexWrapper,
  PageWrapper,
  Icon,
  SectionTitle,
  Loader,
  PrimaryButton
} from '../../../../component'
import { TableHeaderData } from './const'
import { Table, Modal } from 'react-bootstrap'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  deletenewsemail,
  getNewsEmail
} from '../../../../redux/admin/contactus/api'
import { RootState } from '../../../../redux/store'

const Subscriptions = (): ReactElement => {
  const { newsemail, isLoading } = useSelector(
    (state: RootState) => ({
      newsemail: state.contactus.newsemail,
      isLoading: state.contactus.isLoading
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(getNewsEmail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()
  const sorteddata = [...newsemail].reverse()
  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(10)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(sorteddata.length / dataPerPage)
  const pageButtonsToShow = 4

  let total: number
  const [showModal, setshowModal] = useState('')
  return (
    <PageWrapper>
      <FlexWrapper>
        <SectionTitle title="Subscribers list" hasBorder />
      </FlexWrapper>
      <FlexWrapper justifyContent="center">
        <TablePaginationEntries
          showEntries
          handleSelectedDataPerPage={(value) => {
            setdataPerPage(value)
          }}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table striped borderless hover>
              <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }}>
                {sorteddata
                  .slice(
                    (total = currentPage * dataPerPage - dataPerPage),
                    currentPage * dataPerPage
                  )
                  .map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{item.email_id}</td>
                      <td>
                        {' '}
                        <Icon
                          title="Delete feedback"
                          onClick={() => {
                            setshowModal(item?.id)
                          }}
                          icon={['far', 'trash-alt']}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TableFooter
              currentPage={currentPage}
              showEntries
              startValue={startindex}
              showPageDisplay={false}
              pageValue={endindex}
              TotalIems={sorteddata?.length}
              totalPages={totalPages}
              showButtonDisplay
              pageButtonsToShow={pageButtonsToShow}
              handlePrevious={() =>
                setCurrentPages(Math.max(currentPage - 1, 1))
              }
              handleNext={() =>
                setCurrentPages(Math.min(currentPage + 1, totalPages))
              }
              handlePageClick={handlePageClick}
            />{' '}
          </>
        )}
        <Modal
          show={!!showModal}
          onHide={() => {
            setshowModal('')
          }}
          backdrop="static"
        >
          <Modal.Header>
            Are you sure you want to delete this email?
          </Modal.Header>
          <Modal.Footer>
            <FlexWrapper noMargin justifyContent="end">
              <PrimaryButton
                left
                onClick={() => {
                  dispatch(
                    deletenewsemail({
                      id: showModal
                    })
                  )
                  setshowModal('')
                }}
              >
                Yes
              </PrimaryButton>{' '}
              <PrimaryButton
                left
                onClick={() => {
                  setshowModal('')
                }}
              >
                No
              </PrimaryButton>
            </FlexWrapper>
          </Modal.Footer>
        </Modal>
      </FlexWrapper>
    </PageWrapper>
  )
}

export default Subscriptions
