import ROUTESFORDUCARE from './routesducare'
import ROUTES from './routes'

const users = [
  {
    icon: ['fas', 'lock'],
    label: 'Vacancies',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'All',
        to: ROUTES.PUVLISHED_VACC
      },
      {
        icon: ['fas', 'lock'],
        label: 'Unfilled ',
        to: ROUTES.UNFILLEDVACANCY
      },
      {
        icon: ['fas', 'lock'],
        label: 'Filled',
        to: ROUTES.FILLEDVACANCY
      },
      {
        icon: ['fas', 'lock'],
        label: 'Completed ',
        to: ROUTES.COMPLETED_VACC
      }
      // {
      //   icon: ['fas', 'lock'],
      //   label: 'Deleted',
      //   to: ROUTES.COMPLETED_VACC
      // }
    ]
  },

  {
    icon: ['fas', 'lock'],
    label: 'Substitutes',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: ' Reviewed',
        to: ROUTES.APPROVE_CONSULTANTS
      },
      {
        icon: ['fas', 'lock'],
        label: 'Active ',
        to: ROUTES.VIEW_CONSULTANTS
      },
      {
        icon: ['fas', 'lock'],
        label: 'Inactive ',
        to: ROUTES.VIEW_INACTIVE_CONSULTANT
      },
      {
        icon: ['fas', 'lock'],
        label: 'Paid ',
        to: ROUTES.PAIDVACANCY
      },
      {
        icon: ['fas', 'lock'],
        label: 'Unpaid',
        to: ROUTES.UNPAIDVACANCY
      },
      {
        icon: ['fas', 'lock'],
        label: 'Reserved ',
        to: ROUTES.EXPRESS_PASS
      }
    ]
  },
  {
    icon: ['fas', 'lock'],
    label: 'Inbox',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Customer ',
        to: ROUTES.CONSTUMERFEEDBACK
      },
      {
        icon: ['fas', 'lock'],
        label: 'Consultant',
        to: ROUTES.CONSULTANTFEEDBACK
      },
      {
        icon: ['fas', 'lock'],
        label: 'Website',
        to: ROUTES.WEBFEEDBACK
      },
      {
        icon: ['fas', 'lock'],
        label: 'Feedback',
        to: ROUTES.DOHR_FEEDBACK
      },
      {
        icon: ['fas', 'lock'],
        label: 'Subscriptions',
        to: ROUTES.SUBSCRIPTIONS
      }
    ]
  },
  {
    icon: ['fas', 'lock'],
    label: ' Push notifications',
    to: ROUTES.MANUALPUSHALL
  },
  {
    icon: ['fas', 'lock'],
    label: 'Customers',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'All',
        to: ROUTES.VIEWCUSTOMER
      }
      // {
      //   icon: ['fas', 'lock'],
      //   label: 'View total customer',
      //   to: ROUTES.VIEWTOTALCUSTOMER
      // }
    ]
  },
  {
    icon: ['fas', 'lock'],
    label: 'Domain',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Add domain',
        to: ROUTES.ADD_INSTITUTE
      },
      {
        icon: ['fas', 'lock'],
        label: 'Active domain',
        to: ROUTES.VIEW_INSTITUTE
      }
    ]
  },
  {
    icon: ['fas', 'lock'],
    label: 'Website/Ad',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Create',
        to: ROUTES.CREATEAD
      },
      {
        icon: ['fas', 'lock'],
        label: 'Published/applicants',
        to: ROUTES.PUBLISHEDAD
      }
    ]
  },

  {
    icon: ['fas', 'lock'],
    label: 'Substitute applications',
    to: ROUTES.SUBSTITUTE
  },
  {
    icon: ['fas', 'lock'],
    label: 'Open applications',
    to: ROUTES.OPENAPPLICATIONS
  },

  {
    icon: ['fas', 'lock'],
    label: 'Webmodule',
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Role',
        to: ROUTES.ADD_ROLE
      }
    ]
  },
  {
    icon: ['fas', 'lock'],
    label: 'Topup Hours',
    to: '/hoursLists'
  }
]

const users1 = [
  {
    icon: ['fas', 'lock'],
    label: 'DUCARE HOME',
    to: ROUTESFORDUCARE.HOMEPAGE
  }
]

const menus = {
  user: users,
  user1: users1
}

export default menus
