import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  CardWrapper,
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { DOHRFeedBack } from '../../../../../redux/admin/contactus/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'

const ViewDOHRFeedback = (): ReactElement => {
  const { updateViewDOHRFeedBack } = useSelector((state: RootState) => ({
    updateViewDOHRFeedBack: state.contactus
      .updateViewDOHRFeedBack as DOHRFeedBack
  }))
  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle title="FeedBack For DOHR" hasBorder hasBackButton />
        <FlexWrapper justifyContent="space-between">
          <CardWrapper>
            <Column
              keyName="Why did you choose to use DoHR services?"
              value={updateViewDOHRFeedBack?.dohr_service}
            />
            <Column
              keyName="How did DoHR services help you?"
              value={updateViewDOHRFeedBack.dohr_help_service}
            />
            <Column
              keyName="Other feedback"
              value={updateViewDOHRFeedBack.other_feedback}
            />
          </CardWrapper>
          {/* <CardWrapper>
                        <Column keyName='Total Hours' value={updateViewDOHRFeedBack.totalhrs} />
                        <Column keyName='Break Time' value={updateViewDOHRFeedBack.break_time} />
                    </CardWrapper> */}
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewDOHRFeedback
