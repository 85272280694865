import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services'
import apiEndpoints from '../../../const/apiEndPoints'
import { websitecreatead } from './types'

export const websitead = createAsyncThunk(
  'Websitead/createad',
  async (requestPayload: websitecreatead): Promise<any> => {
    const response = await api.post(
      apiEndpoints.websitecreatead,
      requestPayload
    )
    return response?.data?.message
  }
)

export const Getwebsitead = createAsyncThunk(
  'Websitead/getad',
  async (requestPayload: { type: number }): Promise<any> => {
    const response = await api.post(apiEndpoints.getwebsitead, requestPayload)
    return response?.data?.message
  }
)

export const Editwebsitead = createAsyncThunk(
  'Websitead/editad',
  async (requestPayload: websitecreatead): Promise<any> => {
    const response = await api.post(apiEndpoints.editwebsitead, requestPayload)
    return response?.data?.message
  }
)

export const deletead = createAsyncThunk(
  'Websitead/deletead',
  async (
    requestPayload: { id: any; filtertype: any },
    { dispatch }
  ): Promise<any> => {
    const { id, filtertype } = requestPayload
    const response = await api.post(apiEndpoints.deletead, { id })

    if (response.data) {
      dispatch(
        Getwebsitead({
          type: filtertype
        })
      )
    }

    return response.data.message
  }
)

export const Publishedadapplication = createAsyncThunk(
  'Websitead/Publishedeadapplication',
  async (requestPayload: {
    type: number
    advertisement_id?: any
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.publishedadapplication,
      requestPayload
    )

    return response.data.message
  }
)

export const handlestatusApplication = createAsyncThunk(
  'ad/approveList',
  async (
    requestPayload: {
      id: any
      is_approved: boolean
      is_denied: boolean
      email_id: any
      advertisement_id?: any
    },
    { dispatch }
  ): Promise<any> => {
    const { advertisement_id } = requestPayload
    const response = await api.post(
      apiEndpoints.handleststatusapplication,
      requestPayload
    )
    if (response?.data) {
      dispatch(
        Publishedadapplication({
          type: 1,
          advertisement_id: advertisement_id
        })
      )
    }
    return response.data
  }
)

export const deleteadaplication = createAsyncThunk(
  'Websitead/deleteadapplication',
  async (
    requestPayload: { id: any; filtertype: any; advertisement_id?: any },
    { dispatch }
  ): Promise<any> => {
    const { id, filtertype, advertisement_id } = requestPayload
    const response = await api.post(apiEndpoints.deleteadapplication, { id })

    if (response.data) {
      dispatch(
        Publishedadapplication({
          type: filtertype,
          advertisement_id: advertisement_id
        })
      )
    }

    return response.data.message
  }
)
