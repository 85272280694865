import React, { ReactElement, useState } from 'react'

import { FlexWrapper, PageWrapper, SectionTitle } from '../../../component'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { getAllManualPushMsg } from '../../../redux/admin/ManualPushAll/api'
import {
  Button,
  FormContainer,
  Label,
  RadioGroup,
  RadioInput,
  ValidationError,
  TextArea
} from './subcomponents'

export const ManualPushAll = (): ReactElement => {
  // eslint-disable-next-line no-empty-pattern
  const {} = useSelector(
    (state: RootState) => ({
      ManualPushMsg: state.manualpushall.ManualPushMsg,
      isLoading: state.manualpushall.isLoading
    }),
    shallowEqual
  )

  const [recipient, setRecipient] = useState<string>('All')
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [validationError, setValidationError] = useState<string>('')

  const dispatch = useDispatch()

  const handleRecipientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedRecipient = event.target.value
    setRecipient(selectedRecipient)
  }

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value)
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleSubmit = async () => {
    try {
      if (!message.trim()) {
        setValidationError('Please enter a message before sending.')
        return
      }

      // Clear any previous validation errors
      setValidationError('')

      // Define the recipients based on the selected value
      let recipientsToSend: string[]
      if (recipient === 'All') {
        recipientsToSend = ['customer', 'consultant']
      } else {
        // eslint-disable-next-line no-unused-vars
        recipientsToSend = [recipient]
      }

      // Dispatch the getAllManualPushMsg action with the payload
      await dispatch(
        getAllManualPushMsg({
          all: recipient === 'All',
          only_consultant: recipient === 'consultant',
          only_customer: recipient === 'customer',
          message,
          title: title == '' ? 'A new message' : title
        })
      )
      setTitle('');
      setMessage('');
      
      alert('Push notification sent successfully!')
    } catch (error) {
      console.error('Error sending push notification:', error)
      alert('Failed to send push notification.')
    }
  }

  return (
    <PageWrapper>
      <FlexWrapper>
        <SectionTitle
          title="Create a push notification"
          hasBorder
        ></SectionTitle>
        <FormContainer>
          <Label>Select Recipient:</Label>
          <RadioGroup>
            <Label>
              <RadioInput
                value="customer"
                checked={recipient === 'customer'}
                onChange={handleRecipientChange}
              />
              Customer
            </Label>
          </RadioGroup>
          <RadioGroup>
            <Label>
              <RadioInput
                value="consultant"
                checked={recipient === 'consultant'}
                onChange={handleRecipientChange}
              />
              Consultant
            </Label>
          </RadioGroup>

          <RadioGroup>
            <Label>
              <RadioInput
                value="All"
                checked={recipient === 'All'}
                onChange={handleRecipientChange}
              />
              All
            </Label>
          </RadioGroup>
          <Label>Title:</Label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter the title"
            style={{ width: '100%' }}
          />
          <Label>Message:</Label>
          <TextArea value={message} onChange={handleMessageChange} />
          {validationError && (
            <ValidationError>{validationError}</ValidationError>
          )}
          <Button onClick={handleSubmit}>Send Notification</Button>
        </FormContainer>
      </FlexWrapper>
    </PageWrapper>
  )
}

export default ManualPushAll
