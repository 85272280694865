import { ReactElement, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  PrimaryButton,
  SectionTitle
} from '../../../../component'
import { addTax, getTax } from '../../../../redux/admin/settings/api'
import { RootState } from '../../../../redux/store'
import { Tax } from '../../../../redux/admin/settings/types'
import { TaxFilter } from './subcomponents'
import { CardWraper } from '../../Institute/AddInstitute/subcomponent'

const AddTax = (): ReactElement => {
  const dispatch = useDispatch()
  const { TaxData } = useSelector(
    (state: RootState) => ({
      TaxData: state.settings.tax
    }),
    shallowEqual
  )

  const [AddTax, setAddTax] = useState<Tax>({ tax: '' })

  useEffect(() => {
    dispatch(getTax())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Add Tax" hasBorder />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <Input
              value={AddTax.tax}
              placeholder="Add Tax"
              onChange={(value: string) => {
                setAddTax({ tax: value })
              }}
            />
          </DropdownWrapper>
          <PrimaryButton
            height="36px"
            onClick={() => {
              dispatch(addTax(AddTax))
              alert('Tax Added Successfully')
            }}
          >
            Submit
          </PrimaryButton>
        </FlexWrapper>
        <TaxFilter Tableitems={TaxData} />
      </CardWraper>
    </PageWrapper>
  )
}

export default AddTax
