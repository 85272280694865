import { getConsultant } from './api'
import { consultantsSlice } from './reducer'

const { updateSelectedConsultant, updateSubstituteSelectedConsultant } =
  consultantsSlice.actions

export {
  getConsultant,
  updateSelectedConsultant,
  updateSubstituteSelectedConsultant
}
