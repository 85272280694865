import React, { useEffect, useState } from 'react'
import {
  FlexWrapper,
  Icon,
  Loader,
  PageWrapper,
  SectionTitle,
  Input
} from '../../../component'
import { CardWraper, IsApprovedAction } from '../Consultants/subcomponents'
import { Table, Modal, Button } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  getConsultant,
  handleApproveConsultant,
  handleDenyConsultant
} from '../../../redux/admin/consultant/api'
import { RootState } from '../../../redux/store'
import TablePaginationEntries from '../../../component/TablePaginationEntires'
import TableFooter from '../../../component/TableFooter'
import { TableHeaderData } from './const'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../const/routes'
import { updateSelectedConsultant } from '../../../redux/admin/consultant/action'
import moment from 'moment'

const Substitiue = () => {
  const { getApprovelist, isLoading } = useSelector(
    (state: RootState) => ({
      getApprovelist: state.consultant.getApprovelist,
      isLoading: state.consultant.isLoading
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(getConsultant())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(25)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [selectedConsultantId, setSelectedConsultantId] = useState(null)
  const [selectedEmailid, setselectedEmailid] = useState('')

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(getApprovelist?.length / dataPerPage)
  const pageButtonsToShow = 4

  let total: number

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const filteredSearchData = getApprovelist?.filter((items) => {
    if (searchQuery === '') {
      return true // Include all items if searchQuery is empty
    } else if (
      `${items.first_name} ${items.last_name}`
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    ) {
      return true // Include items that match the searchQuery
    }
    return false // Exclude items that don't match the searchQuery
  })

  const dateformatTime = (time: Date) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper>
          <SectionTitle title="Substitute applications" />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FlexWrapper noMargin justifyContent="space-between">
              <TablePaginationEntries
                showEntries
                handleSelectedDataPerPage={(value) => {
                  setdataPerPage(value)
                }}
              />
              <Input
                width="30%"
                value={searchQuery}
                placeholder="Search by Substitute name"
                onChange={handleSearch}
              />

              <Table striped borderless hover>
                <thead style={{ backgroundColor: '#003824', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    {TableHeaderData.map((data, index) => (
                      <th key={`Header Unique id - ${index}`}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredSearchData
                    ?.slice(
                      (total = currentPage * dataPerPage - dataPerPage),
                      currentPage * dataPerPage
                    )
                    .map((item, index) => (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{1 + total++}</td>
                        <td>{`${item.first_name} ${item.last_name}`}</td>
                        <td>{item.email_id}</td>
                        <td>{dateformatTime(item.created_at)}</td>
                        <td>
                          {item.right_to_work_in_Sweden === 1 ? 'Yes' : 'No'}
                        </td>
                        <td>{item.phone_number}</td>
                        <td>
                          {dateformatTime(item.date_of_brith)
                            ? dateformatTime(item.date_of_brith)
                            : 'NA'}
                        </td>
                        <td style={{ width: '150px' }}>
                          <IsApprovedAction
                            handleApprove={() => {
                              // Show the approval confirmation modal
                              setShowApproveModal(true)
                              // Set the selected consultant ID for later use
                              setSelectedConsultantId(item.id)
                              setselectedEmailid(item.email_id)
                            }}
                            handleDeny={() => {
                              // Show the deny confirmation modal
                              setShowDenyModal(true)
                              // Set the selected consultant ID for later use
                              setSelectedConsultantId(item.id)
                              setselectedEmailid(item.email_id)
                            }}
                          />
                        </td>
                        <td>
                          <Icon
                            title="View application"
                            onClick={() => {
                              dispatch(updateSelectedConsultant(item))
                              history.push(ROUTES.VIEW_SUBSTITUTE_CONSULTANAT)
                            }}
                            icon={['fas', 'eye']}
                          />{' '}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <TableFooter
                currentPage={currentPage}
                showEntries
                startValue={startindex}
                showPageDisplay={false}
                pageValue={endindex}
                TotalIems={getApprovelist?.length}
                totalPages={totalPages}
                showButtonDisplay
                pageButtonsToShow={pageButtonsToShow}
                handlePrevious={() =>
                  setCurrentPages(Math.max(currentPage - 1, 1))
                }
                handleNext={() =>
                  setCurrentPages(Math.min(currentPage + 1, totalPages))
                }
                handlePageClick={handlePageClick}
              />
            </FlexWrapper>
          </>
        )}
      </CardWraper>
      <Modal show={showApproveModal} onHide={() => setShowApproveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve this application?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // Handle the approval logic here using the selectedConsultantId
              dispatch(
                handleApproveConsultant({
                  id: selectedConsultantId,
                  email_id: selectedEmailid
                })
              )
              setShowApproveModal(false)
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Deny Confirmation Modal */}
      <Modal show={showDenyModal} onHide={() => setShowDenyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to deny this application?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDenyModal(false)}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // Handle the deny logic here using the selectedConsultantId
              dispatch(
                handleDenyConsultant({
                  id: selectedConsultantId,
                  email_id: selectedEmailid,
                  data: true
                })
              )
              setShowDenyModal(false)
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  )
}

export default Substitiue
