export interface GetAllCustomerresponse {
  id: number
  first_name: string
  last_name: string
  title: string
  title_id: string
  company_name: string
  ins_id: number
  organization_no: string
  organization_type: string
  organization_type_id: string
  email_id: string
  telephone_number: string
  address: string
  postal_code: string
  area_name: string
  invoice_address: string
  invoice_postal_code: string
  invoice_area_name: string
  invoice_email_id: string
  password: string
  is_active: any
  created_at: any
  updated_at: Date
  invoice_reference: string
  job_description: null
  short_description: null
  unique_id: string
  Routine_instructions_for_the_substitutedh_customer: string
  notification_id: string
  is_verfied: number
  profile_img: string
  register_by_mobile: number
  is_inactive: any
}

export interface customeres {
  type: number
  is_verfied: any
}

export interface Applicationres {
  type: number
  is_approved?: number
  is_denied?: number
}

export interface InitialState {
  isLoading: boolean
  listofcustomer: Array<GetAllCustomerresponse>
  updateViewCustomer: GetAllCustomerresponse | null
  getApprovedlist: Array<GetopenApplicationresponse>
  setApplicationData: GetopenApplicationresponse | null
}

export const initialState: InitialState = {
  isLoading: false,
  listofcustomer: [],
  updateViewCustomer: null,
  getApprovedlist: [],
  setApplicationData: null
}

export interface customerai {
  data: boolean
  id: number
  email_id: any
}

export interface applicationstatus {
  is_denied: boolean
  is_approved: boolean
  id: any
  email_id: any
}

export interface GetopenApplicationresponse {
  id?: any
  first_name?: string
  last_name?: string
  address?: string
  email_id: string
  postalcode?: string
  area_name?: string
  phone_number?: string
  preferred_location?: string
  city?: string
  date_of_birth: Date
  right_to_work_in_Sweden_attachment?: string
  Iam_EU_citizen?: number
  Iam_Non_EU_citizen?: number
  linkedIn?: string
  occup_sector?: string
  occup_sector_id?: string
  studyingteacher?: number
  swedish_bank_account?: number
  working_evening_or_weekends?: number
  qualifiedteacherdate: Date
  work_exp_in_school?: number
  work_exp_out_school?: number
  specificField?: string
  language_skills?: string
  subjectlist?: string
  specify_date: Date
  agegroup?: string
  subjectlistno: string
  agegroupno: string
  subjectlistyes: string
  agegroupyes: string
  info_work_exp_in_school?: string
  info_work_exp_out_school?: string
  employment_history?: string
  relevant_education?: string
  qualifiedTeacher?: number
  swedishLara?: number
  instutionstudyteacher?: string
  preferWorkIn?: string
  occupation?: string
  position?: string
  position_id?: string
  work_start?: number
  fileswedishlara?: string
  howDoYouKnowUs?: string
  Driving_license?: number
  right_to_work_in_Sweden?: number
  marketing_purpose?: number
  accurate_information?: number
  terms_and_conditions?: number
  is_denied?: number
  is_approved?: number
  created_at: Date
  updated_at?: Date
  is_active?: number
  otheroccupationfield?: string
}
