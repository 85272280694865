import { ReactElement } from 'react'
import FlexWrapper from '../FlexWrapper'
import { H4, Small } from '../../typography'
import ColumnProps from './typings'

const Column = ({
  keyName,
  value,
  input,
  isCheckbox
}: ColumnProps): ReactElement => {
  return (
    <FlexWrapper justifyContent="space-between">
      <H4>{`${keyName}: `}</H4>
      <Small hasPadding color={'blue'}>
        {value && value}
        {isCheckbox && <input type="checkbox" checked={input} />}
      </Small>
    </FlexWrapper>
  )
}

export default Column
