import React, { ReactElement } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../../Institute/AddInstitute/subcomponent'
import { Row, Col, Accordion } from 'react-bootstrap'
import styled from 'styled-components'
import { H3 } from '../../../../../typography'
import fonts, { weight } from '../../../../../const/fonts'
import { GetvacanciesApplicationresponse } from '../../../../../redux/admin/WebsiteAd/types'

const Title = styled(H3)`
  margin-bottom: 5px;
  font-size: ${fonts.small}px;
  font-weight: ${weight.normal};
  font-family: 'Montserrat', sans-serif;
  color: darkblue;
`

const ADUSERDETAILS = (): ReactElement => {
  const { UpdateApplicationData } = useSelector((state: RootState) => ({
    UpdateApplicationData: state.Websitead
      .setApplicationData as GetvacanciesApplicationresponse
  }))

  const relevant_education = JSON.parse(
    UpdateApplicationData?.relevant_education || '[]'
  )
  const employment_history = JSON.parse(
    UpdateApplicationData?.employment_history || '[]'
  )
  const subjectlist = JSON.parse(UpdateApplicationData?.subjectlist || '[]')

  const agegroup = JSON.parse(UpdateApplicationData?.agegroup || '[]')
  const subjectlistno = JSON.parse(UpdateApplicationData?.subjectlistno || '[]')

  const agegroupno = JSON.parse(UpdateApplicationData?.agegroupno || '[]')
  const subjectlistyes = JSON.parse(
    UpdateApplicationData?.subjectlistyes || '[]'
  )

  const agegroupyes = JSON.parse(UpdateApplicationData?.agegroupyes || '[]')

  const info_work_exp_in_school = JSON.parse(
    UpdateApplicationData?.info_work_exp_in_school || '[]'
  )
  const info_work_exp_out_school = JSON.parse(
    UpdateApplicationData?.info_work_exp_out_school || '[]'
  )

  const languageSkills = JSON.parse(
    UpdateApplicationData.language_skills || '[]'
  )
  const dateformatTime = (time: Date) => {
    return moment(time).format('YYYY-MM-DD ')
  }

  const dateformatTime1 = (time: Date) => {
    return moment(time).format('YYYY-MM ')
  }
  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle title="Detail view" hasBorder hasBackButton />
        <FlexWrapper justifyContent="center">
          <Accordion defaultActiveKey="" style={{ width: '100%' }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Title>Personal information</Title>
              </Accordion.Header>
              <Accordion.Body>
                <FlexWrapper>
                  <Row>
                    <Col xs={12} md={6}>
                      <Column
                        keyName="First name"
                        value={`${UpdateApplicationData.first_name} `}
                      />
                      <Column
                        keyName="last name"
                        value={`${UpdateApplicationData.last_name} `}
                      />
                      <Column
                        keyName="Address"
                        value={UpdateApplicationData?.address}
                      />
                      <Column
                        keyName="Postalcode"
                        value={UpdateApplicationData?.postalcode}
                      />
                      <Column
                        keyName="Area name"
                        value={UpdateApplicationData?.area_name}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      {' '}
                      <Column
                        keyName="Contact Number"
                        value={UpdateApplicationData?.phone_number}
                      />
                      <Column
                        keyName="Email"
                        value={UpdateApplicationData?.email_id}
                      />
                      <Column
                        keyName="City"
                        value={UpdateApplicationData?.city}
                      />
                      <Column
                        keyName="Date of Birth"
                        value={dateformatTime(
                          UpdateApplicationData.date_of_birth
                        )}
                      />
                      <Column
                        keyName="Tell us briefly why you are interested in this position"
                        value={UpdateApplicationData?.description}
                      />
                    </Col>
                  </Row>
                </FlexWrapper>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <Title>Relevant work experience</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <Column
                      keyName="Occupational field"
                      value={UpdateApplicationData?.occup_sector}
                    />

                    {(UpdateApplicationData?.occup_sector === 'Other' ||
                      UpdateApplicationData?.occup_sector === 'Övrigt') && (
                      <Column
                        keyName="Specify Occupational field"
                        value={UpdateApplicationData?.specificField}
                      />
                    )}
                    {(UpdateApplicationData?.occup_sector === 'Education' ||
                      UpdateApplicationData?.occup_sector === 'Utbildning') && (
                      <>
                        <Column
                          keyName="Position"
                          value={UpdateApplicationData?.position}
                        />
                        {(UpdateApplicationData?.position ===
                          'Teaching positions' ||
                          UpdateApplicationData?.position === 'Lärare') && (
                          <>
                            <Column
                              keyName="Job title"
                              value={UpdateApplicationData?.occupation}
                            />
                            <Column
                              keyName="Are you a qualified teacher?"
                              value={
                                UpdateApplicationData?.qualifiedTeacher === 1
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                            {UpdateApplicationData?.qualifiedTeacher === 1 && (
                              <>
                                <Column
                                  keyName="Qualified to teach following subject/subjects"
                                  value={subjectlist.join(', ')}
                                />
                                <Column
                                  keyName="Qualified to teach following age groups"
                                  value={agegroup.join(', ')}
                                />
                                <Column
                                  keyName="Do you have a Swedish lärarlegitimation"
                                  value={
                                    UpdateApplicationData?.swedishLara === 1
                                      ? 'Yes'
                                      : 'No'
                                  }
                                />
                                {UpdateApplicationData?.swedishLara === 1 && (
                                  <>
                                    <Column
                                      keyName="lärarlegitimation"
                                      value={
                                        <a
                                          href={`https://api.dohr.io/${UpdateApplicationData.fileswedishlara}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          lärarlegitimation.pdf
                                        </a>
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                            {UpdateApplicationData?.qualifiedTeacher === 0 && (
                              <>
                                <Column
                                  keyName="What subjects do you have teaching experience in?"
                                  value={subjectlistno.join(', ')}
                                />
                                <Column
                                  keyName="What age groups do you have experience with"
                                  value={agegroupno.join(', ')}
                                />
                                <Column
                                  keyName="Are you currently studying to become a teacher?"
                                  value={
                                    UpdateApplicationData?.studyingteacher === 1
                                      ? 'Yes'
                                      : 'No'
                                  }
                                />
                                {UpdateApplicationData?.studyingteacher ===
                                  1 && (
                                  <>
                                    <Column
                                      keyName="I will be a qualified teacher by"
                                      value={dateformatTime1(
                                        UpdateApplicationData?.qualifiedteacherdate
                                      )}
                                    />
                                    <Column
                                      keyName="I will be qualified to teach following subjects"
                                      value={subjectlistyes.join(', ')}
                                    />
                                    <Column
                                      keyName="I will be qualified to teach following age groups"
                                      value={agegroupyes.join(', ')}
                                    />
                                    <Column
                                      keyName="Institution name"
                                      value={
                                        UpdateApplicationData?.instutionstudyteacher
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {/* Additional columns based on position */}
                        {(UpdateApplicationData?.position ===
                          'Non-Teaching positions' ||
                          UpdateApplicationData?.position ===
                            'Icke lärare') && (
                          <>
                            <Column
                              keyName="Job title"
                              value={UpdateApplicationData?.occupation}
                            />
                            <Column
                              keyName="I have experience working with children/young adults in a school setting"
                              value={
                                UpdateApplicationData?.work_exp_in_school === 1
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                            {UpdateApplicationData?.work_exp_in_school === 1 &&
                              info_work_exp_in_school.map(
                                (item: any, index: number) => (
                                  <div key={index}>
                                    <Column
                                      keyName={`Employer `}
                                      value={item.name_of_emp}
                                    />
                                    <Column
                                      keyName={`Duration `}
                                      value={item.duration}
                                    />
                                    <Column
                                      keyName={`Role `}
                                      value={item.title_of_role}
                                    />
                                    <Column
                                      keyName={`Responsibilities `}
                                      value={item.key_res}
                                    />
                                  </div>
                                )
                              )}
                            {UpdateApplicationData?.work_exp_in_school ===
                              0 && (
                              <>
                                <Column
                                  keyName="I have experience working with children/young adults in a school setting"
                                  value={
                                    UpdateApplicationData?.work_exp_out_school ===
                                    1
                                      ? 'Yes'
                                      : 'No'
                                  }
                                />
                                {UpdateApplicationData?.work_exp_out_school ===
                                  1 &&
                                  info_work_exp_out_school.map(
                                    (item: any, index: number) => (
                                      <div key={index}>
                                        <Column
                                          keyName={`Employer`}
                                          value={item.name_of_emp}
                                        />
                                        <Column
                                          keyName={`Duration`}
                                          value={item.duration}
                                        />
                                        <Column
                                          keyName={`Role `}
                                          value={item.title_of_role}
                                        />
                                        <Column
                                          keyName={`Responsibilities`}
                                          value={item.key_res}
                                        />
                                      </div>
                                    )
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <Column
                      keyName="Employment history"
                      value={employment_history?.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <Column
                              keyName={`Employer `}
                              value={item.employer}
                            />
                            <Column
                              keyName={`From `}
                              value={dateformatTime1(item.from)}
                            />
                            <Column
                              keyName={`to `}
                              value={dateformatTime1(item.to)}
                            />

                            <Column keyName={`role`} value={item.role} />
                            <Column
                              keyName={`responsiblities`}
                              value={item.responsibility}
                            />
                          </div>
                        )
                      )}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <Title>Relevant education</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column
                  keyName="Relevant education"
                  value={relevant_education?.map((item: any, index: number) => (
                    <div key={index}>
                      <Column
                        keyName={`Institution name `}
                        value={item.ins_name}
                      />
                      <Column keyName={`Education `} value={item.education} />
                      <Column
                        keyName={`From `}
                        value={dateformatTime1(item.from)}
                      />
                      <Column
                        keyName={`to `}
                        value={dateformatTime1(item.to)}
                      />
                    </div>
                  ))}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <Title>My language skills</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column keyName="Swedish" value={languageSkills.swedish} />
                <Column keyName="English" value={languageSkills.english} />
                <Column
                  keyName="Other"
                  value={languageSkills.other.map(
                    (entry: any, index: number) => (
                      <div key={index}>
                        <p> {entry.lang}</p>
                        <p>Proficiency: {entry.proficiency}</p>
                      </div>
                    )
                  )}
                />
              </Accordion.Body>
            </Accordion.Item>{' '}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <Title>Other information</Title>
              </Accordion.Header>
              <Accordion.Body>
                <Column
                  keyName="Where can you start"
                  value={
                    UpdateApplicationData.work_start
                      ? 'Anytime'
                      : 'Specify date'
                  }
                />
                {UpdateApplicationData?.work_start === 0 && (
                  <Column
                    keyName="Specify Date"
                    value={dateformatTime(UpdateApplicationData?.specify_date)}
                  />
                )}

                <Column
                  keyName="I have a right to work in Sweden?"
                  value={
                    UpdateApplicationData?.right_to_work_in_Sweden === 1
                      ? 'Yes'
                      : 'No'
                  }
                />
                {/* 
                {UpdateApplicationData?.right_to_work_in_Sweden === 1 && (
                  <>
                    {UpdateApplicationData?.Iam_EU_citizen === 1 && (
                      <>
                        <Column keyName="I am an EU citizen" value="Yes" />
                        <Column
                          keyName="main page of your passport"
                          value={
                            <a
                              href={`https://api.dohr.io/${UpdateApplicationData.right_to_work_in_Sweden_attachment}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Passport.pdf
                            </a>
                          }
                        />
                      </>
                    )} */}

                {/* {UpdateApplicationData?.Iam_Non_EU_citizen === 1 && (
                      <>
                        <Column keyName="I am a non-EU citizen" value="Yes" />
                        <Column
                          keyName="work permit"
                          value={
                            <a
                              href={`https://api.dohr.io/${UpdateApplicationData.right_to_work_in_Sweden_attachment}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Work Permit.pdf
                            </a>
                          }
                        />
                      </>
                    )}
                  </>
                )} */}

                <Column
                  keyName="Provide a link to your LinkedIn
                                        "
                  value={UpdateApplicationData?.linkedIn}
                />
                <Column
                  keyName="I have a valid driver's licence
                                        "
                  value={
                    UpdateApplicationData?.Driving_license === 1 ? 'Yes' : 'No'
                  }
                />
                <Column
                  keyName="How did you hear about us?
                                        "
                  value={UpdateApplicationData?.howDoYouKnowUs}
                />
                <Column
                  keyName="GDPR Consent was given on"
                  value={dateformatTime(UpdateApplicationData?.created_at)}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ADUSERDETAILS
