import { Switch, Route } from 'react-router-dom'
import ROUTES from './const/routes'
import {
  AddInstitute,
  ViewInstitute,
  AddRole,
  AddTax,
  ViewActiveConsultants,
  ApproveConsultant,
  InstituteView,
  ConsultantsDetails,
  AddExpressPass,
  PublishedVacancies,
  CompletedVacancies,
  Substitiue,
  ViewInActiveConsultants,
  ViewSubstituteConsultant,
  HoursList
} from './pages'
import PaidVacancy from './pages/admin/Consultants/PaidVacancy'
import UnPaidVacancy from './pages/admin/Consultants/UnpaidVacancy'
import ManualPushConsultant from './pages/admin/Consultants/ManualPushNotification'
import ConsultantFeedBack from './pages/admin/ContactUs/ConsultantFeedback'
import ConstumerFeedBack from './pages/admin/ContactUs/CustomerFeedback'
import ViewFeedbackConsultant from './pages/admin/ContactUs/ConsultantFeedback/ViewFeedback'
import ViewFeedbackConstumer from './pages/admin/ContactUs/CustomerFeedback/ViewFeedback'
import Filled from './pages/admin/Vaccancy/Filled'
import UnFilled from './pages/admin/Vaccancy/UnFilled'
import ViewFilledVacancy from './pages/admin/Vaccancy/Filled/ViewVacancy'
import ViewUnFilledVacancy from './pages/admin/Vaccancy/UnFilled/ViewVacancy'
import ViewPublishedVacancy from './pages/admin/Vaccancy/Published/ViewVacancy'
import DOHRFeedBack from './pages/admin/ContactUs/DOHRFeedback'
import ViewDOHRFeedBack from './pages/admin/ContactUs/DOHRFeedback/ViewFeedback'
import ViewCompletedVacancy from './pages/admin/Vaccancy/Completed/Viewvacancy'
import ViewPaidVacancy from './pages/admin/Consultants/PaidVacancy/ViewVacancy'
import ViewUnPaidVacancy from './pages/admin/Consultants/UnpaidVacancy/Viewvacancy'
import ManualPushAll from './pages/admin/ManualPushNotification'
import ViewExpressUserList from './pages/admin/ExpressPass/ViewUserdetails'
import ExpressPassUser from './pages/admin/ExpressPass/Detailview'
import ViewCustomer from './pages/admin/Customer/ViewCustomer'
import ViewCustomerDetail from './pages/admin/Customer/ViewCustomer/Detailview'
import ViewTotalCustomer from './pages/admin/Customer/TotalCustomer'
import ViewConsultantdata from './pages/admin/Consultants/Approve/viewdetails'
import WebFeedBack from './pages/admin/ContactUs/WebFeedback'
import ViewFeedbackWeb from './pages/admin/ContactUs/WebFeedback/Viewfeedback'
import CreateAd from './pages/admin/WebsiteAd/CreateAd'
import PublishedAd from './pages/admin/WebsiteAd/PublishedAd'
import PublishedAdView from './pages/admin/WebsiteAd/PublishedAd/View'
import ADUSERDETAILS from './pages/admin/WebsiteAd/PublishedAd/ApplicationView/view'
import Openapplication from './pages/admin/Openapplication'
import ViewOpenApplication from './pages/admin/Openapplication/view'
import PublishedAdUser from './pages/admin/WebsiteAd/PublishedAd/ApplicationView'
import ROUTESFORDUCARE from './const/routesducare'
import Dashboard from './DUcare/pages/Dashboard'
import Dohrwebsite from './Dohr'
import DUcarehome from './DUcare/pages/Home'
import Subscriptions from './pages/admin/ContactUs/Subscriptions'

export const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path={ROUTES.DASHBOARD} component={DashBoard} /> */}
      <Route path={ROUTES.ADD_INSTITUTE} component={AddInstitute} />
      <Route path={ROUTES.VIEW_INSTITUTE} component={ViewInstitute} />
      <Route path={ROUTES.ADD_ROLE} component={AddRole} />
      <Route path={ROUTES.ADD_TAX} component={AddTax} />
      <Route path={ROUTES.VIEW_CONSULTANTS} component={ViewActiveConsultants} />
      <Route path={ROUTES.APPROVE_CONSULTANTS} component={ApproveConsultant} />
      <Route path={ROUTES.VIEW_SELECTED_INS} component={InstituteView} />
      <Route path={ROUTES.CONSULTANTS_DETAILS} component={ConsultantsDetails} />
      <Route path={ROUTES.EXPRESS_PASS} component={AddExpressPass} />
      <Route path={ROUTES.PUVLISHED_VACC} component={PublishedVacancies} />
      <Route path={ROUTES.COMPLETED_VACC} component={CompletedVacancies} />

      <Route path={ROUTES.FILLEDVACANCY} component={Filled} />

      <Route path={ROUTES.UNFILLEDVACANCY} component={UnFilled} />

      <Route path={ROUTES.PAIDVACANCY} component={PaidVacancy} />
      <Route path={ROUTES.UNPAIDVACANCY} component={UnPaidVacancy} />
      <Route
        path={ROUTES.MANUALPUSHCONSULTANT}
        component={ManualPushConsultant}
      />
      <Route path={ROUTES.CONSULTANTFEEDBACK} component={ConsultantFeedBack} />
      <Route
        path={ROUTES.VIEWFEEDBACK_CUSTOMER}
        component={ViewFeedbackConstumer}
      />
      <Route path={ROUTES.CONSTUMERFEEDBACK} component={ConstumerFeedBack} />
      <Route
        path={ROUTES.VIEWFEEDBACK_CONSULTANT}
        component={ViewFeedbackConsultant}
      />
      <Route path={ROUTES.VIEWFILLEDVACANCY} component={ViewFilledVacancy} />
      <Route
        path={ROUTES.VIEWUNFILLEDVACANCY}
        component={ViewUnFilledVacancy}
      />
      <Route
        path={ROUTES.VIEWPUBLISHEDDVACANCY}
        component={ViewPublishedVacancy}
      />
      <Route path={ROUTES.DOHR_FEEDBACK} component={DOHRFeedBack} />
      <Route path={ROUTES.VIEWDOHR_FEEDBACK} component={ViewDOHRFeedBack} />
      <Route
        path={ROUTES.VIEWCOMPLETED_VACANCY}
        component={ViewCompletedVacancy}
      />
      <Route path={ROUTES.VIEWPAID_VACANCY} component={ViewPaidVacancy} />
      <Route path={ROUTES.VIEWUNPAID_VACANCY} component={ViewUnPaidVacancy} />
      <Route path={ROUTES.MANUALPUSHALL} component={ManualPushAll} />
      <Route
        path={ROUTES.GETEXPRESSPASSUSERLIST}
        component={ViewExpressUserList}
      />
      <Route path={ROUTES.VIEWEXPRESSPASSUSER} component={ExpressPassUser} />
      <Route path={ROUTES.VIEWCUSTOMER} component={ViewCustomer} />
      <Route path={ROUTES.VIEWCUSTOMERDETAIL} component={ViewCustomerDetail} />
      <Route path={ROUTES.VIEWTOTALCUSTOMER} component={ViewTotalCustomer} />
      <Route path={ROUTES.SUBSTITUTE} component={Substitiue} />
      <Route
        path={ROUTES.VIEW_INACTIVE_CONSULTANT}
        component={ViewInActiveConsultants}
      />
      <Route
        path={ROUTES.VIEW_SUBSTITUTE_CONSULTANAT}
        component={ViewSubstituteConsultant}
      />
      <Route
        path={ROUTES.VIEW_CONSULTANT_DATA}
        component={ViewConsultantdata}
      />
      <Route path={ROUTES.WEBFEEDBACK} component={WebFeedBack} />
      <Route path={ROUTES.VIEWFEEDBACKWEB} component={ViewFeedbackWeb} />
      <Route path={ROUTES.CREATEAD} component={CreateAd} />
      <Route path={ROUTES.PUBLISHEDAD} component={PublishedAd} />
      <Route path={ROUTES.PUBLISHEDADVIEW} component={PublishedAdView} />
      <Route path={ROUTES.ADUSERDETAILS} component={ADUSERDETAILS} />
      <Route path={ROUTES.PublishedAdUser} component={PublishedAdUser} />
      <Route path={ROUTES.OPENAPPLICATIONS} component={Openapplication} />
      <Route
        path={ROUTES.OPENAPPLICATIONVIEW}
        component={ViewOpenApplication}
      />
      <Route path={ROUTES.HOME} component={Dohrwebsite} />
      <Route path={ROUTES.SUBSCRIPTIONS} component={Subscriptions} />
      <Route path={'/hoursLists'} component={HoursList} />
    </Switch>
  )
}

export const RoutesForDUcare = () => {
  return (
    <Switch>
      {/* Add your DUcare-specific routes here */}
      {/* For example: */}
      <Route path={ROUTESFORDUCARE.DASHBOARD} component={Dashboard} />
      <Route path={ROUTESFORDUCARE.HOMEPAGE} component={DUcarehome} />

      {/* Add more routes as needed */}
    </Switch>
  )
}
