import React, { ReactElement, useEffect, useState } from 'react'
import { Table, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  Icon,
  PrimaryButton,
  Input
} from '../../../../component'

import {
  GetInstitute,
  deleteDomainStatus
} from '../../../../redux/admin/institution/api'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../AddInstitute/subcomponent'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'

const ViewInstitute = (): ReactElement => {
  const dispatch = useDispatch()

  const { getInstitute } = useSelector((state: RootState) => ({
    getInstitute: state.institute.getInstitute
  }))

  useEffect(() => {
    dispatch(GetInstitute())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(10)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchQuery(value)
  }

  const totalPages = Math.ceil(getInstitute.length / dataPerPage)
  const pageButtonsToShow = 4
  const [showModal, setshowModal] = useState('')

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  let total: number

  const sortedData = [...getInstitute]

  const filteredData = sortedData.filter(
    (item) =>
      searchQuery === '' ||
      item.institute_domin?.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin backgroundColor=" #f2fff2">
          <SectionTitle title="List of active domains" />
        </FlexWrapper>
        <FlexWrapper justifyContent="space-between">
          <TablePaginationEntries
            showEntries
            handleSelectedDataPerPage={(value) => {
              setdataPerPage(value)
            }}
          />

          <Input
            width="30%"
            value={searchQuery}
            placeholder="Search by domain name"
            onChange={handleSearch}
          />
        </FlexWrapper>

        <FlexWrapper>
          <Table striped borderless hover>
            <thead style={{ backgroundColor: '#003824', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                {TableHeaderData.map((data, index) => (
                  <th key={`Header Unique id - ${index}`}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(
                  (total = currentPage * dataPerPage - dataPerPage),
                  currentPage * dataPerPage
                )
                .map((item, index) => (
                  <tr key={index} style={{ textAlign: 'center' }}>
                    <td>{1 + total++}</td>
                    <td>{item.institute_name}</td>
                    <td>{item.institute_domin}</td>
                    <td>active</td>
                    <td>
                      <Icon
                        title="Delete Domain"
                        onClick={() => {
                          setshowModal(item?.id)
                        }}
                        icon={['far', 'trash-alt']}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <TableFooter
            currentPage={currentPage}
            showEntries
            startValue={startindex}
            showPageDisplay={false}
            pageValue={endindex}
            TotalIems={getInstitute?.length}
            totalPages={totalPages}
            showButtonDisplay
            pageButtonsToShow={pageButtonsToShow}
            handlePrevious={() => setCurrentPages(Math.max(currentPage - 1, 1))}
            handleNext={() =>
              setCurrentPages(Math.min(currentPage + 1, totalPages))
            }
            handlePageClick={handlePageClick}
          />
          <Modal
            show={!!showModal}
            onHide={() => {
              setshowModal('')
            }}
            backdrop="static"
          >
            <Modal.Header>
              Are you sure you want to delete this domain?
            </Modal.Header>
            <Modal.Footer>
              <FlexWrapper noMargin justifyContent="end">
                <PrimaryButton
                  left
                  onClick={() => {
                    dispatch(
                      deleteDomainStatus({
                        id: showModal
                      })
                    )
                    setshowModal('')
                  }}
                >
                  Yes
                </PrimaryButton>{' '}
                <PrimaryButton
                  left
                  onClick={() => {
                    setshowModal('')
                  }}
                >
                  No
                </PrimaryButton>
              </FlexWrapper>
            </Modal.Footer>
          </Modal>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewInstitute
