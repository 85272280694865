import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services'
import apiEndpoints from '../../../const/apiEndPoints'
import { PostRole, Role, Tax } from './types'
import axios from 'axios'

export const addRole = createAsyncThunk(
  'settings/addRole',
  async (requestPayload: PostRole, { dispatch }): Promise<any> => {
    const response = await api.post(`${apiEndpoints.addRole}`, requestPayload)
    if (response.status === 200) {
      dispatch(getRole())
    }
    return response.data
  }
)

export const getRole = createAsyncThunk(
  'settings/getRole',
  async (): Promise<Array<Role>> => {
    const response = await api.get(apiEndpoints.getRole)
    return response.data.message
  }
)

export const addTax = createAsyncThunk(
  'settings/addTax',
  async (requestPayload: Tax): Promise<any> => {
    const response = await api.post(`${apiEndpoints.addTax}`, requestPayload)
    return response.data
  }
)

export const getTax = createAsyncThunk(
  'settings/getTax',
  async (): Promise<Array<Tax>> => {
    const response = await api.get(apiEndpoints.getTax)
    return response.data.message
  }
)

export const adminLogin = createAsyncThunk(
  'login/adminLogin',
  async (userDetials: { email_id: string; password: string }): Promise<any> => {
    const resposne = axios.post('https://api.dohr.io/AdminLogin', {
      email_id: userDetials?.email_id,
      password: userDetials?.password
    })
    return resposne.then((res) => res?.data?.message?.data)
  }
)
