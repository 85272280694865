/* eslint-disable jsx-a11y/no-onchange */
import { ReactElement } from 'react'
import { EntriesWrapper } from '../TableFooter/subcomponents'
import { HeadEnd } from './styles'
import { TableHeaderProps } from './typings'

const TablePaginationEntries = ({
  showEntries,
  handleSelectedDataPerPage
}: TableHeaderProps): ReactElement => {
  return (
    <HeadEnd>
      <EntriesWrapper>
        {showEntries && (
          <>
            Show{' '}
            <select
              id="lang"
              onChange={(e) => {
                handleSelectedDataPerPage(e.target.value)
              }}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{' '}
            entries
          </>
        )}
      </EntriesWrapper>
    </HeadEnd>
  )
}

export default TablePaginationEntries