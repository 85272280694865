import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services'
import apiEndpoints from '../../../const/apiEndPoints'
import {
  customeres,
  customerai,
  Applicationres,
  applicationstatus,
  GetopenApplicationresponse
} from './types'

export const getAllcustomerdata = createAsyncThunk(
  'customer/allcustomer',
  async (requestPayload: customeres): Promise<any> => {
    const response = await api.post(apiEndpoints.Getallcustomer, requestPayload)
    return response?.data?.message
  }
)

export const customerstatus = createAsyncThunk(
  'customer/allcustomerstatus',
  async (requestPayload: customerai): Promise<any> => {
    const response = await api.post(apiEndpoints.customerstatus, requestPayload)
    return response?.data?.message
  }
)

export const handleApproveApplication = createAsyncThunk(
  'consultant/approveConsultantList',
  async (requestPayload: applicationstatus, { dispatch }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.Approvedenyopenapplication}`,
      requestPayload
    )
    if (response?.data) {
      dispatch(
        getAllApplication({
          type: 1
        })
      )
    }
    return response.data
  }
)

export const handleDenyApplication = createAsyncThunk(
  'consultant/approveConsultantList',
  async (requestPayload: applicationstatus, { dispatch }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.Approvedenyopenapplication}`,
      requestPayload
    )
    if (response?.data) {
      dispatch(
        getAllApplication({
          type: 1
        })
      )
    }
    return response.data
  }
)

export const getAllApplication = createAsyncThunk(
  'customer/Open application',
  async (requestPayload: Applicationres): Promise<any> => {
    const { type } = requestPayload

    // Make the API request and fetch all data
    const response = await api.post(
      apiEndpoints.getfilterapplication,
      requestPayload
    )
    const allData: GetopenApplicationresponse[] = response?.data?.message
    // Filter the data based on filterType
    if (type === 1) {
      return allData.filter(
        (item) => item.is_approved === 0 && item.is_denied === 0
      )
    } else {
      // Return all data without filtering for other filter types
      return allData
    }
  }
)

export const deleteopenapplication = createAsyncThunk(
  'openapplication/deleteapplication',
  async (
    requestPayload: { id: any; filtertype: any },
    { dispatch }
  ): Promise<any> => {
    const { id, filtertype } = requestPayload
    const response = await api.post(apiEndpoints.deleteopenapplication, { id })
    if (response?.data) {
      dispatch(
        getAllApplication({
          type: filtertype
        })
      )
    }
    return response.data.message
  }
)
