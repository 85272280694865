import { ReactElement, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  FlexWrapper,
  Icon,
  PageWrapper,
  SectionTitle,Loader
} from '../../../../component'
import ROUTES from '../../../../const/routes'
import { updateSelectedConsultant } from '../../../../redux/admin/consultant/action'
import { getAllUserList } from '../../../../redux/admin/consultant/api'
import { RootState } from '../../../../redux/store'
import { CardWraper } from './subcomponents'
import { TableHeaderData } from './const'
import TablePaginationEntries from '../../../../component/TablePaginationEntires'
import TableFooter from '../../../../component/TableFooter'

const ViewExpressUserList = (): ReactElement => {
  const {
    consultant: { getApprovedlist,isLoading }
  } = useSelector((state: RootState) => ({
    consultant: state.consultant
  }))

  const dispatch = useDispatch()
  const history = useHistory()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const passId = queryParams.get('passId')

  useEffect(() => {
    if (passId) {
      dispatch(
        getAllUserList({
          pass_id: passId
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passId])

  const [currentPage, setCurrentPages] = useState(1)
  const [dataPerPage, setdataPerPage] = useState(5)
  const startindex = currentPage * dataPerPage - (dataPerPage - 1)
  const endindex = currentPage * dataPerPage
  let total: number

  const handlePageClick = (pageNumber: number) => {
    setCurrentPages(pageNumber)
  }

  const totalPages = Math.ceil(getApprovedlist?.length / dataPerPage)
  const pageButtonsToShow = 4

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper>
          <SectionTitle title=" Reserved substitutes" hasBackButton />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
        <>
        <TablePaginationEntries
          showEntries
          handleSelectedDataPerPage={(value) => {
            setdataPerPage(value)
          }}
        />
       
          <Table striped borderless hover responsive>
            <thead style={{ backgroundColor: '#003824', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                {TableHeaderData.map((data, index) => (
                  <th key={`Header Unique id - ${index}`}>{data}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {getApprovedlist
                ?.slice(
                  (total = currentPage * dataPerPage - dataPerPage),
                  currentPage * dataPerPage
                )
                ?.map((data, index) => {
                  const {
                    first_name,
                    last_name,
                    email_id,
                    phone_number,
                  } = data
                  return (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{1 + total++}</td>
                      <td>{`${first_name} ${last_name}`}</td>
                      <td>{email_id}</td>
                      <td>{phone_number}</td>
                      <td>
                        <Icon
                          onClick={() => {
                            history.push(ROUTES.VIEWEXPRESSPASSUSER)
                            dispatch(updateSelectedConsultant(data))
                          }}
                          icon={['fas', 'eye']}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
          <TableFooter
            currentPage={currentPage}
            showEntries
            startValue={startindex}
            showPageDisplay={false}
            pageValue={endindex}
            TotalIems={getApprovedlist?.length}
            totalPages={totalPages}
            showButtonDisplay
            pageButtonsToShow={pageButtonsToShow}
            handlePrevious={() => setCurrentPages(Math.max(currentPage - 1, 1))}
            handleNext={() =>
              setCurrentPages(Math.min(currentPage + 1, totalPages))
            }
            handlePageClick={handlePageClick}
          />
       </>
       )}
      </CardWraper>
    </PageWrapper>
  )
}

export default ViewExpressUserList
