export const TableHeaderData = [
  'S.No',
  'Ad ID',
  'Ad title',
  'Published date ',
  'End date',
  'Ad details ',
  'Status',
  'Action',
  'View applicants'
]
