export interface getConsultantResponse {
  id?: any
  first_name?: string
  last_name?: string
  email_id?: any
  phone_number?: string
  address?: string
  postalcode?: string
  area_name?: string
  city?: null
  date_of_brith: Date
  swedish_personal_number?: string
  swedish_bank_account?: any
  bank_account_number?: string
  preferred_work_institution?: string
  emergency_contact_number?: string
  emergency_contact_lastname?: string
  emergency_contact_firstname?: string
  clearing_number?: string
  bank_name?: string
  preferred_location_5?: string
  preferred_location_4?: string
  preferred_location_3?: string
  preferred_location_2?: string
  preferred_location_1?: string
  description?: string
  self_employed?: number
  iam_student?: number
  type_of_study?: string
  state_hours_weeks_student?: number
  name_of_institutition?: string
  proof_of_studies?: string
  iam_retired_pensioner?: number
  other_occupations?: number
  info_other_occupations?: string
  work_exp_in_school?: number
  info_work_exp_in_school?: string
  work_exp_out_school?: number
  info_work_exp_out_school?: string
  other_work_experience?: number
  info_other_work_experience?: null
  as_soon_as_possible?: number
  specify_date: Date
  working_hours_or_weeks?: number
  working_evening_or_weekends?: number
  language_skills?: string
  police_clearance_report?: number
  police_report?: string
  Driving_license?: number
  How_did_you_hear_about_us?: string
  right_to_work_in_Sweden?: number
  Iam_non_EU_citizen?: number
  Iam_EU_citizen?: number
  right_to_work_in_Sweden_attachment?: any
  terms_and_condition?: number
  marketing_purpose?: null
  accurate_information?: null
  is_active?: number
  password?: string
  is_approved?: number
  created_at: any
  updated_at?: Date
  notification_id?: string
  profile_img?: string
  swedish_phone_number?: string
  is_inactive?: number
  is_denied?: number
  reg_i_have_swedish?: number
  reg_iam_teacher_student?: number
  reg_short_description?: string
}

// interface WorkExperience {
//   name_of_emp: string;
//   duration: string;
//   title_of_role: string;
//   key_res: string;
// }

export interface GetexpressPass {
  pass_id: number
  date: string
  count: any
  filled_count: string | number
  available_count: string | number
}

export interface PostexpressPass {
  date: string
  count: string
}

export interface GetHoursListProsp {
  id: string
  total_hours: Number
  used_hours: Number
  remaining_hours: Number
  requesting_hours: Number
  ins_id: Number
  is_processed: Number
  is_active: Number
  created_at: string
  updated_at: string
}
export interface InitialState {
  isLoading: boolean
  getApprovelist: Array<getConsultantResponse>
  getApprovedlist: Array<getConsultantResponse>
  setSelectedConsutant: getConsultantResponse | null
  setSubstituteSelectedConsutant: any
  GetExpressPass: Array<GetexpressPass>
  addExpressResponse: string
  vacancyUserDetails: any
  getHoursDetials: Array<GetHoursListProsp>
}

export interface getallexpresspasslist {
  pass_id: any
}

export interface expresspassupdate {
  count: number
  open: boolean
  id: number
}

export const initialState: InitialState = {
  getApprovelist: [],
  isLoading: false,
  getHoursDetials: [],
  getApprovedlist: [],
  setSelectedConsutant: null,
  setSubstituteSelectedConsutant: null,
  GetExpressPass: [],
  addExpressResponse: '',
  vacancyUserDetails: []
}
