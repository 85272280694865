import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addRole, addTax, adminLogin, getRole, getTax } from './api'
import { InitialState, Role, Tax } from './types'

export const initialState: InitialState = {
  role: [],
  isLoading: false,
  tax: [],
  isLoggedIn: false
}

export const SettingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateIsloggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action?.payload
    }
  },
  extraReducers: {
    [addRole.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [addRole.fulfilled.toString()]: (state) => {
      state.isLoading = false
    },
    [addRole.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [getRole.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getRole.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Role>>
    ) => {
      state.isLoading = false
      state.role = action?.payload
    },
    [getRole.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [addTax.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [addTax.fulfilled.toString()]: (state) => {
      state.isLoading = false
    },
    [addTax.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [getTax.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [getTax.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Tax>>
    ) => {
      state.isLoading = false
      state.tax = action?.payload
    },
    [getTax.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [adminLogin.pending.toString()]: (state) => {
      state.isLoading = true
      state.isLoggedIn = false
    },
    [adminLogin.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.isLoggedIn = !!action?.payload
      console.log(action?.payload, 'q')
    },
    [adminLogin.rejected.toString()]: (state) => {
      state.isLoading = false
      state.isLoggedIn = false
    }
  }
})

export default SettingSlice.reducer
