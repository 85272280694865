/* eslint-disable no-unused-vars */
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  PrimaryButton,
  SectionTitle,
  EditableDropdown,
  CardWrapper
} from '../../../../component'
import { DropdownListProps } from '../../../../component/EditableDropdown/typings'
import {
  OrganizationDropdown,
  TitleDropdown
} from '../../../../helpers/dropdown'
import {
  AddInstitutes,
  GetAddress,
  GetOrganization
} from '../../../../redux/admin/institution/api'
import { getRole } from '../../../../redux/admin/settings/api'
import { RootState } from '../../../../redux/store'
import { resetInitialValues } from './const'
import { CardWraper, CountInput, In, SelectedTitle } from './subcomponent'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import Modal from '../../../../component/Modal'
import ToastMessage from '../../../../component/ToastMessge'
import { updatehasInstituteCreated } from '../../../../redux/admin/institution/action'
import { validateRequired } from '../../../../helpers/formValidation'

const AddInstitute = (): ReactElement => {
  const dispatch = useDispatch()

  const { InstituteProps, OrgData, RoleTitle, handleInstituteCreated } =
    useSelector(
      (state: RootState) => ({
        InstituteProps: state.institute.AddInstitute,
        handleInstituteCreated: state.institute.handleInstituteCreated,
        OrgData: state.institute.GetOrganization,
        Address: state.institute.getAddress,
        RoleTitle: state.settings.role
      }),
      shallowEqual
    )

  const [AddInstitution, setAddInstitution] = useState(InstituteProps)
  const [resetValue, setresetValue] = useState(resetInitialValues)
  const [value, setValue] = useState({ formatted_address: '' })
  const [title, setTitle] = useState([{ id: '', name: '' }])
  const [showModal, setShowModal] = useState(false)

  const OrgDropDown = OrgData ? OrganizationDropdown(OrgData) : []
  const TitleDropDown = RoleTitle ? TitleDropdown(RoleTitle) : []

  const clearValues = () => {
    setresetValue({
      institute_type: true,
      requirement_info: true
    })
    setAddInstitution(InstituteProps)
  }

  const [error, setError] = useState('')
  const [error1, setError1] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  useEffect(() => {
    dispatch(GetOrganization())
    dispatch(getRole())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAddInstitution({ ...AddInstitution, address: value.formatted_address })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const searchOptions = {
    componentRestrictions: { country: ['se'] },
    types: ['address']
  }
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper noMargin backgroundColor=" #f2fff2">
          <SectionTitle title="Add company domain" />
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          <DropdownWrapper>
            <Input
              value={AddInstitution.institute_name}
              placeholder="Institute Name"
              onBlur={() => {
                const errors = validateRequired(AddInstitution?.institute_name)
                setError(errors)
              }}
              error={error}
              onChange={(value: string) => {
                setAddInstitution({ ...AddInstitution, institute_name: value })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.institute_domin}
              placeholder="for example @domainname.xx"
              onBlur={() => {
                const errors = validateRequired(AddInstitution?.institute_domin)
                setError1(errors)
              }}
              error={error1}
              onChange={(value: string) => {
                setAddInstitution({
                  ...AddInstitution,
                  institute_domin: value
                })
              }}
            />
          </DropdownWrapper>

          <DropdownWrapper>
            <Input
              value={AddInstitution.institute_id}
              placeholder="Institute id"
              onChange={(value: string) => {
                setAddInstitution({
                  ...AddInstitution,
                  institute_id: value
                })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.locality}
              placeholder="Institute Locality"
              onChange={(value: string) => {
                setAddInstitution({ ...AddInstitution, locality: value })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <EditableDropdown
              isRequired
              dropdownList={OrgDropDown}
              placeholder={'Organization type'}
              reset={resetValue?.institute_type}
              handleSelect={(value: DropdownListProps) => {
                setAddInstitution({
                  ...AddInstitution,
                  institute_type: value
                })
              }}
            />
          </DropdownWrapper>

          <DropdownWrapper>
            <EditableDropdown
              isRequired
              dropdownList={TitleDropDown}
              isMultiChoice
              placeholder={'Select Title'}
              handleMultiSelect={(value: Array<DropdownListProps>) => {
                setTitle(value)
                setresetValue({
                  ...resetValue,
                  requirement_info: false
                })
              }}
              multiReset={resetValue?.requirement_info}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.email_id}
              inputType="email"
              placeholder="E-Mail"
              onChange={(value: string) => {
                setAddInstitution({
                  ...AddInstitution,
                  email_id: value
                })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.password}
              inputType="password"
              placeholder="Password"
              onChange={(value: string) => {
                setAddInstitution({ ...AddInstitution, password: value })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.contact_number}
              placeholder="Contact Number"
              onChange={(value: string) => {
                setAddInstitution({
                  ...AddInstitution,
                  contact_number: value
                })
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={AddInstitution.postcode}
              placeholder="Postal Code"
              onChange={(value: string) => {
                setAddInstitution({
                  ...AddInstitution,
                  postcode: value
                })
              }}
              onBlur={() => {
                dispatch(GetAddress({ postcode: AddInstitution.postcode }))
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <In
              style={{ height: '40px', outline: 'none' }}
              apiKey={'AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc'}
              onPlaceSelected={(place) => {
                setValue({ formatted_address: place.formatted_address })
              }}
              options={searchOptions}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding>
          <PrimaryButton
            onClick={() => {
              setError1(
                AddInstitution?.institute_domin ? '' : 'Field is Required'
              )
              setError(
                AddInstitution?.institute_name ? '' : 'Field is Required'
              )
              if (
                !AddInstitution?.institute_domin &&
                !AddInstitution?.institute_name
              ) {
                {
                  setToastMessage('Please fill all required fields.')
                }
              }

              dispatch(
                AddInstitutes({
                  ...AddInstitution,
                  password: AddInstitution?.password,
                  contact_number: AddInstitution.contact_number
                    ? AddInstitution.contact_number
                    : 'NA',
                  institute_type: {
                    id: AddInstitution.institute_type.id
                      ? AddInstitution.institute_type.id
                      : '0',
                    name: AddInstitution.institute_type.name
                      ? AddInstitution.institute_type.name
                      : 'NA'
                  },
                  address: AddInstitution.address
                    ? AddInstitution.address
                    : 'NA',
                  email_id: AddInstitution.email_id
                    ? AddInstitution.email_id
                    : 'NA',
                  locality: AddInstitution.locality
                    ? AddInstitution.locality
                    : 'NA',
                  institute_id: AddInstitution.institute_id
                    ? AddInstitution.institute_id
                    : 'NA',
                  postcode: AddInstitution.postcode
                    ? AddInstitution.postcode
                    : 'NA'
                })
              )
            }}
          >
            Submit
          </PrimaryButton>
        </FlexWrapper>
        {showModal ? (
          <Modal
            isLargeModal
            title="Assign Count"
            handleCancel={() => {
              setShowModal(false)
              // setAddInstitution({
              //   ...AddInstitution,
              //   requirement_info: []
              // })
            }}
            handleSubmit={() => {
              dispatch(AddInstitutes(AddInstitution))
              clearValues()
              setShowModal(false)
            }}
          >
            <FlexWrapper justifyContent="center">
              {title.map((item, index) => (
                <CardWrapper width="30" key={index}>
                  <FlexWrapper noPadding justifyContent="center">
                    <SelectedTitle>{item.name}</SelectedTitle>
                  </FlexWrapper>
                  <CountInput
                    type="number"
                    placeholder="Count"
                    min={1}
                    max={5}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                      const isIncluded = AddInstitution.requirement_info.some(
                        (x) => x?.id === item.id
                      )
                      if (isIncluded) {
                        const updatedSelection =
                          AddInstitution.requirement_info.map((x) => {
                            if (x?.id === item.id) {
                              return {
                                id: item.id,
                                name: item.name,
                                count: e.target.value
                              }
                            }
                            return x
                          })
                        setAddInstitution({
                          ...AddInstitution,
                          requirement_info: updatedSelection
                        })
                      } else {
                        setAddInstitution({
                          ...AddInstitution,
                          requirement_info: [
                            ...AddInstitution.requirement_info,
                            {
                              id: item.id,
                              name: item.name,
                              count: e.target.value
                            }
                          ]
                        })
                      }
                    }}
                  />
                </CardWrapper>
              ))}
            </FlexWrapper>
          </Modal>
        ) : null}
      </CardWraper>
      {handleInstituteCreated && (
        <ToastMessage
          show={!!handleInstituteCreated}
          bg="success"
          onCloseHandler={() => {
            dispatch(updatehasInstituteCreated(false))
          }}
          message="Successfully Created,"
        ></ToastMessage>
      )}
      {/* {toastMessage && (
        <ToastMessage
          show={true}
          bg="danger" // You can use a different background color for error messages
          onCloseHandler={() => {
            setToastMessage('')
          }}
          message={toastMessage}
        ></ToastMessage>
      )} */}
    </PageWrapper>
  )
}

export default AddInstitute
