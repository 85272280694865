import { WebsiteadSlice } from './reducer'
import {
  websitead,
  Getwebsitead,
  Editwebsitead,
  Publishedadapplication
} from './api'
const { updateViewAd, updateViewApplication, updatehasAdCreated } =
  WebsiteadSlice.actions
export {
  websitead,
  WebsiteadSlice,
  Getwebsitead,
  updateViewAd,
  Editwebsitead,
  updateViewApplication,
  Publishedadapplication,
  updatehasAdCreated
}
