export const tableHeader = [
    "S.No",
    "Customer",
    "Position",
    "Vacancy date",
    "Company name",
    "Consultant",
    "Original ",
    "Reported ",
  "Approved",
    " Approved hours",
    "Paid",
    "Vacancy Status",
    "Detail view",
  ];