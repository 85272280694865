import { ReactElement, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../component'
import { getConsultantResponse } from '../../../../redux/admin/consultant/types'
import { RootState } from '../../../../redux/store'
import { CardWraper } from '../../Institute/AddInstitute/subcomponent'
import { Row, Col } from 'react-bootstrap'

const ExpressPassUser = (): ReactElement => {
  const { setSelectedConsutant } = useSelector((state: RootState) => ({
    setSelectedConsutant: state.consultant
      .setSelectedConsutant as getConsultantResponse
  }))

  const [isStudent, setIsStudent] = useState(false)

  useEffect(() => {
    // Set checkbox state based on the value of `iam_student`
    setIsStudent(setSelectedConsutant?.iam_student === 1)
  }, [setSelectedConsutant?.iam_student])

  const [isSwedishBankAccount, setIsSwedishBankAccount] = useState(false)

  useEffect(() => {
    // Set checkbox state based on the value of `swedish_bank_account`
    setIsSwedishBankAccount(setSelectedConsutant?.swedish_bank_account === 1)
  }, [setSelectedConsutant?.swedish_bank_account])

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle title="User details" hasBackButton hasBorder />
        <FlexWrapper justifyContent="space-between">
          <Row>
            <Col xs={12} md={6}>
              <Column
                keyName="First Name"
                value={`${setSelectedConsutant?.first_name}`}
              />
              <Column
                keyName="Last Name"
                value={`${setSelectedConsutant?.last_name}`}
              />
              <Column
                keyName="Personal number"
                value={setSelectedConsutant?.swedish_personal_number}
              />
              <Column keyName="Email" value={setSelectedConsutant?.email_id} />
              <Column
                keyName="Phone Number"
                value={setSelectedConsutant?.phone_number}
              />
              <Column
                keyName=" Postal address"
                value={setSelectedConsutant?.address}
              />
              <Column
                keyName="Area Name"
                value={`${setSelectedConsutant?.area_name}`}
              />
              <Column
                keyName="Postal Code"
                value={setSelectedConsutant?.postalcode}
              />

              <Column
                keyName="I am a teacherstudent"
                value={<input type="checkbox" checked={isStudent} />}
              />
              <Column
                keyName="About me"
                value={setSelectedConsutant?.description}
              />
            </Col>
            <Col xs={12} md={6}>
              <Column
                keyName="Bank Name"
                value={setSelectedConsutant?.bank_name}
              />
              <Column
                keyName="Clearing Number"
                value={setSelectedConsutant?.clearing_number}
              />
              <Column
                keyName="Bank Account Number"
                value={setSelectedConsutant?.bank_account_number}
              />{' '}
              <Column
                keyName="I have Swish"
                value={<input type="checkbox" checked={isSwedishBankAccount} />}
              />
              {/* ... */}
              {setSelectedConsutant?.swedish_bank_account === 1 && (
                <Column
                  keyName="Phone Number for Swish"
                  value={setSelectedConsutant?.swedish_phone_number}
                />
              )}
              <Column
                keyName="Emergency Contact Person Name"
                value={`${setSelectedConsutant?.emergency_contact_firstname} ${setSelectedConsutant?.emergency_contact_lastname}`}
              />
              <Column
                keyName="Emergency Contact Person Number"
                value={setSelectedConsutant?.emergency_contact_number}
              />
              <Column
                keyName="Approved "
                value={
                  setSelectedConsutant?.is_approved === 0 ? (
                    <span style={{ color: 'red' }}>No</span>
                  ) : (
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      Yes
                    </span>
                  )
                }
              />
              <Column
                keyName="Status"
                value={
                  setSelectedConsutant?.is_active === 0 ? (
                    <span style={{ color: 'red' }}>INACTIVE</span>
                  ) : (
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      ACTIVE
                    </span>
                  )
                }
              />
            </Col>
          </Row>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default ExpressPassUser
