import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  CardWrapper,
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle
} from '../../../../../component'
import { GetInstituteProps } from '../../../../../redux/admin/institution/types'
import { RootState } from '../../../../../redux/store'
import { CardWraper } from '../../AddInstitute/subcomponent'

const InstituteView = (): ReactElement => {
  const { updateViewInstitute } = useSelector((state: RootState) => ({
    updateViewInstitute: state.institute
      .updateViewInstitute as GetInstituteProps
  }))

  return (
    <PageWrapper>
      <CardWraper>
        <SectionTitle title="Institute Details" hasBorder hasBackButton />
        <FlexWrapper justifyContent="space-between">
          <CardWrapper>
            <Column
              keyName="Institute Name"
              value={updateViewInstitute?.institute_name}
            />
            <Column
              keyName="Institute Domain"
              value={updateViewInstitute?.institute_domin}
            />
            <Column
              keyName="Institute Type"
              value={updateViewInstitute?.institute_type}
            />
            <Column keyName="Locality" value={updateViewInstitute?.locality} />
          </CardWrapper>
          <CardWrapper>
            <Column keyName="Address" value={updateViewInstitute?.address} />
            <Column
              keyName="Phone Number"
              value={updateViewInstitute?.contact_number}
            />
            {/* <Column keyName='Role' value={tiltleName} />
                        <Column keyName='Role Count' value={tiltleCount} /> */}
          </CardWrapper>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  )
}

export default InstituteView
